import $ from 'jquery'
import _ from 'underscore'
import RecentReadings from './recent_readings'

export default class DeviceRow {
  // Public: Initialize a new DeviceRow.
  //
  // token      - A String token for the device.
  // channels   - An Array<Object> of Channel data.
  // controller - A locations.PreferenceToggleController.
  constructor(token, channels, controller) {
    this._populateAlert = this._populateAlert.bind(this)
    this.token = token
    this.channels = channels
    this.controller = controller
    this.$el = $(`[data-token=${this.token}]`)
    this.$readings = this.$el.find('.js--recent_readings')

    this._populateAlert()
    new RecentReadings(this.$readings, this.channels).render().then(() => (this.controller != null ? this.controller.refresh() : undefined))
  }

  // Public: Populate an alert if the device is in a not reporting state with a
  // corresponding AlarmEvent.
  //
  // Returns nothing.
  _populateAlert() {
    const deviceAlert = _.detect(gon.alerts, (alert) => (alert.token === this.token) && (alert.channel === 0))

    if (deviceAlert) {
      const styleClass = deviceAlert.excursion ? 'text-danger' : 'text-warning'
      const icon = deviceAlert.excursion ? 'error' : 'warning'

      this.$el
          .find('.js--alarmWarning__device')
          .html(`<i class='material-icons ${styleClass}' title='${deviceAlert.description}' data-bs-toggle='tooltip'>${icon}</i>`)
    }
  }
}
