const COLORS = [
  {background: '#0a837b', text: '#ffffff', border: '#0a837b'},
  {background: '#7d22be', text: '#ffffff', border: '#7d22be'},
  {background: '#d33400', text: '#ffffff', border: '#d33400'},
  {background: '#142029', text: '#ffffff', border: '#142029'},
  {background: '#3747a0', text: '#ffffff', border: '#3747a0'},
  {background: '#00610a', text: '#ffffff', border: '#00610a'},
  {background: '#d73b57', text: '#ffffff', border: '#d73b57'},
  {background: '#8b720e', text: '#ffffff', border: '#8b720e'},
  {background: '#9beae5', text: '#142029', border: '#0a837b'},
  {background: '#d69eff', text: '#142029', border: '#7d22be'},
  {background: '#ffac92', text: '#142029', border: '#d33400'},
  {background: '#d9e2e8', text: '#142029', border: '#142029'},
  {background: '#b9c4ff', text: '#142029', border: '#3747a0'},
  {background: '#a3e5a9', text: '#142029', border: '#00610a'},
  {background: '#ffc1cc', text: '#142029', border: '#d73b57'},
  {background: '#e9cf69', text: '#142029', border: '#8b720e'},
]

export default class AlarmColor {
  // Public: Initialize an AlarmColor object to track assigned colors for alarms
  // between different UI components.
  constructor() {
    this.colorFor = this.colorFor.bind(this)

    this._assignedColors = {}
    this._nextIndex = 0
  }

  // Public: Get the assigned color for the passed Alarm object. If the alarm has
  // not yet been assigned a color, grab the next color from the list.
  //
  // alarm - An Object.
  //
  // Returns a String.
  colorFor(alarm) {
    const id = alarm.alarmTemplateId || alarm.id

    if (!id) {
      return ''
    }

    let color
    if (this._assignedColors[id]) {
      color = this._assignedColors[id]
    } else {
      color = this._assignedColors[id] = COLORS[this._nextIndex]
    }

    // To support more alarms than we have predefined colors in COLORS,
    // we use the remainder operator to ensure that _nextIndex wraps around
    // when it reaches the end of COLORS. In this case, .length will always
    // be non-negative, so we don’t need to normalize the result of % to obtain
    // the modulo (as discussed on https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Remainder#description)
    this._nextIndex = Object.keys(this._assignedColors).length % COLORS.length

    return color
  }
}
