import $ from 'jquery'
import initElement from '../../helpers/init_element'

initElement('#js--terms_acceptances-show', () => {
  $('.acceptTerms__button').tooltip()

  $('#terms').on('scroll', function(e) {
    const el = $(e.currentTarget)[0]

    // NOTE: We're checking if the user scrolled to within 5px of the bottom of
    // the scroll pane. Trying to get an exact match causes issues with
    // subpixels when the browser's zoom is adjusted.
    if ((el.scrollTop + el.clientHeight) > (el.scrollHeight - 5)) {
      $('#terms').off('scroll')
      $('.acceptTerms__button').tooltip('dispose')
      $('button[type=submit]').prop('disabled', false)
    }
  })
})
