export default class DateRenderer {
  init(params) {
    this.eGui = document.createElement('span')
    this.eGui.innerHTML = params.value ? moment(params.value).format(momentDateFormat) : '-'
  }

  getGui() {
    return this.eGui
  }

  refresh(params) {
    return false
  }
}
