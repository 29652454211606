import _ from 'underscore'

import Clipboard from '../../helpers/clipboard'
import ModalDialog from '../shared/modal_dialog'

export default {
  name: 'contact-row',
  template: '#contact-row-template',
  components: {ModalDialog},
  props: {
    contact: {
      type: Object,
      required: true,
    },
    userRoles: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      expandPanel: false,
    }
  },
  computed: {
    userRole() {
      return this.userRoles.find(function(role) {
        return role.id === this.contact.userRoleId
      }, this)
    },
    displayLocations() {
      if (this.contact.locations.length) {
        return _.pluck(this.contact.locations, 'name').join(', ')
      } else {
        return 'None'
      }
    },
    editPath() {
      return '/contacts/' + this.contact.id + '/edit'
    },
    inviteModalAnchor() {
      return '#' + this.inviteModalId
    },
    inviteModalId() {
      return 'invitation-' + this.contact.id
    },
    invitePath() {
      return '/users/' + this.contact.user.id + '/resend_invite'
    },
    invitable() {
      return this.contact.user && !this.contact.user.loggedIn
    },
  },
  methods: {
    copyInvitationUrl(e) {
      Clipboard.copy(this.contact.invitationUrl)

      const $button = $(e.currentTarget)
      $button.prop('disabled', true).html('<i class=\'material-icons\'>pending</i>')

      setTimeout(function() {
        $button.html('Copied!')
        setTimeout(function() {
          $button.prop('disabled', false).html('Copy URL')
        }, 3000)
      }, 500)
    },
    followUserLink(e) {
      if (!this.contact.editable) {
        e.preventDefault()
      }
    },
    toggleForm(e) {
      if (!this.contact.editable) {
        return
      }
      this.expandPanel = true
      this.$emit('toggle-form', this.contact.id)
    },
    togglePanel(e) {
      if ($(e.target).parent('.btn').length) {
        return
      }
      this.expandPanel = !this.expandPanel
    },
    submit() {
      this.$emit('submit', this.contact)
    },
    destroy() {
      if (this.contact.user) {
        return
      }
      this.$emit('destroy', this.contact)
    },
  },
}
