import ModalDialog from '../shared/modal_dialog'

const STATUS = {
  INITIAL: 0,
  UPLOADING: 1,
  PROCESSING: 2,
  SUCCESS: 3,
  FAILED: 4,
}

export default {
  template: '#adjustment-uploader-template',
  props: ['url', 'returnTo'],
  components: {ModalDialog},
  data() {
    return {
      csvFile: '',
      status: STATUS.INITIAL,
    }
  },
  computed: {
    isStatusInitial() {
      return this.status === STATUS.INITIAL
    },
    isStatusUploading() {
      return this.status === STATUS.UPLOADING
    },
    isStatusProcessing() {
      return this.status === STATUS.PROCESSING
    },
    isStatusSuccess() {
      return this.status === STATUS.SUCCESS
    },
    isStatusFailed() {
      return this.status === STATUS.FAILED
    },
    processProgressClass() {
      return {
        'active': this.isStatusProcessing,
        'success': this.isStatusSuccess,
        'progress-bar-danger': this.isStatusFailed,
        'progress-bar-striped': this.isStatusProcessing,
      }
    },
    returnPath() {
      return this.isStatusSuccess ? this.returnTo : null
    },
    uploadProgressClass() {
      return {
        'active': this.isStatusUploading,
        'success': !this.isStatusUploading,
        'progress-bar-striped': this.isStatusUploading,
      }
    },
  },
  methods: {
    dropFile({dataTransfer: {files: [file]}}) {
      this.csvFile = file
    },
    error(response) {
      this.status = STATUS.FAILED
    },
    removeFile() {
      this.csvFile = ''
      this.status = STATUS.INITIAL
    },
    selectFile({target: {files: [file]}}) {
      this.csvFile = file
    },
    success(response) {
      this.status = STATUS.SUCCESS
    },
    upload(file) {
      this.status = STATUS.UPLOADING

      const data = new FormData()
      data.append('file', file)

      $.ajax({
        data,
        url: this.url,
        type: 'PUT',
        contentType: false,
        processData: false,
        error: this.error,
        success: this.success,
      })

      if (this.status < STATUS.PROCESSING) {
        this.status = STATUS.PROCESSING
      }
    },
  },
  watch: {
    csvFile(file) {
      if (!file) {
        return
      }

      this.upload(file)
    },
  },
}
