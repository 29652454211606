import _ from 'underscore'

/**
 * The `paginator` component is used to capture numeric strings using
 * the user preference for decimal separator. The value is then stored as a
 * number that can be passed to the backend.

@name paginator

@prop {Object} data - An Object containing pagination metadata.

@example
  {
    "data": {
      "record_count": 123,
      "page_size": 25,
      "page_count": 5,
      "current_page": 3,
      "prev_page": 2,
      "next_page": 4,
    }
  }
*/

const Paginator = {
  name: 'paginator',
  template: '#paginator-template',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ['update'],
  data() {
    return {
      pageSize: this.data.page_size,
    }
  },
  computed: {
    currentPage() {
      return this.data.current_page
    },
    prevPage() {
      return this.data.prev_page
    },
    nextPage() {
      return this.data.next_page
    },
    totalCount() {
      return this.data.record_count
    },
    totalPages() {
      return this.data.page_count
    },
    from() {
      return (this.currentPage - 1) * this.pageSize + 1
    },
    to() {
      return _.min([this.currentPage * this.pageSize, this.totalCount])
    },
  },
  methods: {
    setPage(page) {
      this.$emit('update', page)
    },
  },
}

export default Paginator
