import $ from '~/jquery'
import moment from '~/moment'
import _ from 'underscore'

import FlashMessage from '~/services/flash_message'

/**
 * The `snooze-form` component describes a form that can be used to select and
 * submit a request to snooze one or more alerts.
 *
 * @name snooze-form
 * @prop {String} url The URL to which the snooze request should be posted.
 * @prop {Boolean} [acknowledge=false] Whether or not an informational block regarding passive acknowledgement should be displayed.
 * @prop {Boolean} [actions=true] Whether or not to render form actions inline.
 * @prop {Object} [metadata={}] Additional metadata to include in the POST request body.
 *
 * @template components/alerts/snooze_form
 * @design https://zpl.io/6N8qPoj
 *
 * @example
 *   {
 *     "url": "/alerts/1/snooze",
 *     "acknowledge": false
 *   }
 *
 * @example
 *   {
 *     "url": "/snooze",
 *     "acknowledge": true
 *   }
 */

const SnoozeForm = {
  name: 'snooze-form',
  template: '#snooze-form-template',
  emits: ['update', 'change'],
  props: {
    url: {
      type: String,
      required: true,
    },
    acknowledge: {
      type: Boolean,
      required: false,
      default: true,
    },
    actions: {
      type: Boolean,
      required: false,
      default: true,
    },
    metadata: {
      type: Object,
      require: false,
      default: {},
    },
  },
  data() {
    return {
      snoozeSelection: '',
      snoozeComment: '',
      snoozeMinutes: 15,
      snoozeHours: 0,
      snoozeDays: 0,
    }
  },
  computed: {
    snoozeDuration() {
      if (this.snoozeSelection === -1) {
        return this.snoozeMinutes * 60 + this.snoozeHours * 3600 + this.snoozeDays * 86400
      } else if (this.snoozeSelection === '') {
        return 0
      } else {
        return this.snoozeSelection
      }
    },
    submittable() {
      return this.snoozeDuration > 0
    },
  },
  methods: {
    snooze() {
      const endTime = moment().add(this.snoozeDuration, 'seconds').toISOString()

      $.ajax({
        method: 'POST',
        url: this.url,
        data: _.extend({alert_snooze: {ends_at: endTime}, comment: {text: this.snoozeComment}}, this.metadata),
      }).done((data) => {
        this.snoozeSelection = ''
        this.snoozeComment = ''
        this.snoozeMinutes = 15
        this.snoozeHours = 0
        this.snoozeDays = 0

        data.forEach((alert) => this.$emit('update', alert))
      }).fail((response) => {
        const errorMessage = response.responseJSON.errors.base
        const options = this.actions ? {modal: true, icon: 'warning'} : {icon: 'warning'}

        new FlashMessage(errorMessage, 'warning', options)
      })
    },
  },
  watch: {
    snoozeDuration(value) {
      this.$emit('change', value > 0)
    },
  },
}

export default SnoozeForm
