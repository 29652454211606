import _ from 'underscore'
import {underscored} from 'underscore.string'

export default function(object) {
  const iterator = function(acc, value, key) {
    acc[underscored(key)] = value
    return acc
  }
  return _.inject(object, iterator, {})
}
