const ICONS = {
  'ambient': 'air',
  'chamber': 'door_front',
  'cold_room': 'ac_unit',
  'freezer': 'ac_unit',
  'incubator': 'door_front',
  'laboratory': 'biotech',
  'refrigerator': 'kitchen',
  'shipment': 'inventory_2',
  'warehouse': 'apartment',
  'custom': 'hexagon',
}

export default function equipmentIcon(equipment) {
  const equipmentType = typeof equipment === 'object' ? equipment.type : equipment
  return ICONS[equipmentType] || 'hexagon'
}
