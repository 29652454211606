import $ from 'jquery'
import initElement from '../../helpers/init_element'

initElement('#js--audit_logs-index', () => {
  $('#timezone-select').on('change', function(e) {
    e.preventDefault()
    const choice = this.options[this.selectedIndex].value

    $.ajax({
      dataType: 'json',
      url: '/preferences',
      type: 'POST',
      data: {preference: 'time_zone', value: choice},
      success(data) {
        location.reload()
      },
    })
  })
})
