import $ from 'jquery'
import _ from 'underscore'
import {initVue} from '~/vue'

import AlarmConditionForm from '../../components/alarms/alarm_condition_form'
import AlertSchedule from '../../components/alert_schedules/alert_schedule'
import ErrorMessage from '../../components/shared/error_message'
import EscalationPolicySelect from '../../components/escalation_policies/escalation_policy_select'
import NotificationPolicyForm from '../../components/escalation_policies/notification_policy_form'
import TemplateApplicationForm from '../../components/alarm_templates/template_application_form'

import AlarmTemplate from '../../services/wrappers/alarm_template'
import TemplateGroup from '../../services/wrappers/template_group'
import EscalationPolicy from '../../services/wrappers/escalation_policy'

initVue('#vue--alarm-templates-build', {
  components: {
    AlarmConditionForm,
    AlertSchedule,
    ErrorMessage,
    EscalationPolicySelect,
    NotificationPolicyForm,
    TemplateApplicationForm,
  },
  data() {
    const templateGroup = new TemplateGroup(gon.templateGroup)

    return {
      templateGroup,
      escalationPolicies: EscalationPolicy.wrapWithDefault(gon.escalationPolicies),
      templateApplicationOptions: gon.templateApplicationOptions,
      customPolicy: _.any(templateGroup.notificationGroups),
      warnings: [],
    }
  },
  computed: {
    // Trigger
    allowConditionRemoval() {
      return this.templateGroup.visibleAlarmTemplates.length > 1
    },
    // Notify
    allowContinue() {
      return this.templateGroup.allowSaveNotificationGroup()
    },
  },
  methods: {
    submit() {
      const options = {
        url: window.location,
        method: 'patch',
        data: JSON.stringify({template_group: this.templateGroup.serialize()}),
        contentType: 'application/json',
      }

      $.ajax(options)
          .done(this.processData.bind(this))
          .fail(function(xhr) {
            this.processData(xhr.responseJSON)
          }.bind(this))
    },
    processData(data) {
      if (data.redirect) {
        window.location = data.redirect
      } else {
        this.templateGroup = new TemplateGroup(data.templateGroup)
        this.warnings = data.warnings
        if (this.warnings.length) {
          $('html, body').animate({scrollTop: 0}, 500)
        }
      }
    },
    toggleCustomPolicy(value) {
      this.customPolicy = value
    },
    // Trigger
    addAlarmTemplate() {
      this.templateGroup.alarmTemplates.push(new AlarmTemplate())
    },
    destroyAlarmTemplate(alarmTemplate) {
      if (alarmTemplate.id) {
        alarmTemplate._destroy = true
      } else {
        const index = this.templateGroup.alarmTemplates.indexOf(alarmTemplate)
        this.templateGroup.alarmTemplates.splice(index, 1)
      }
    },
  },
})
