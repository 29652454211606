import $ from 'jquery'
import _ from 'underscore'
import initElement from '../../../helpers/init_element'

const SUPPORTS_DAILY_SUMMARY = ['device']
const SUPPORTS_SIGNATURE_LINE = ['device', 'channel', 'reading', 'two_a_day']

class ReportChooseType {
  constructor($form) {
    this._verifySelectedOption = this._verifySelectedOption.bind(this)
    this._reportTypeDetail = this._reportTypeDetail.bind(this)

    this.$form = $form
    this.$select = this.$form.find('select[data-figure-target]')
    this.$dropdown = this.$form.find('.js--reportTypeOptions')

    this.$targetFigure = $(this.$select.data('figure-target'))
    this.$exampleFigure = $('.reportType--example')
    this.$descriptions = $('.js--report-description')

    this.$select.on('change', this._reportTypeDetail).trigger('change')
    this.$dropdown.on('change', this._verifySelectedOption).trigger('change')
  }

  _verifySelectedOption() {
    if (_.contains(SUPPORTS_DAILY_SUMMARY, this.$dropdown.val())) {
      $('.js--includeDailySummary').show()
    } else {
      $('.js--includeDailySummary').hide()
      $('.js--includeDailySummary input:checkbox').prop('checked', false)
    }

    if (_.contains(SUPPORTS_SIGNATURE_LINE, this.$dropdown.val())) {
      $('.js--includeSignature').show()
    } else {
      $('.js--includeSignature').hide()
      $('.js--includeSignature input:checkbox').prop('checked', false)
    }
  }

  _reportTypeDetail() {
    const selectValue = this.$select.val()
    const dataName = selectValue.replace(/_/g, '-')
    const figureImage = this.$select.data(`figure-${dataName}-image`)
    const figureCaption = this.$select.data(`figure-${dataName}-caption`)

    if (selectValue) {
      this.$descriptions.addClass('hide')
      this.$descriptions.filter(`[data-type=${selectValue}]`).removeClass('hide')
    } else {
      this.$descriptions.removeClass('hide')
    }

    if (figureImage != null) {
      this.$targetFigure.find('img').attr('src', figureImage)
      this.$targetFigure.find('figcaption em').text(figureCaption)
      this.$targetFigure.removeClass('hide')
      this.$exampleFigure.addClass('hide')
    } else {
      this.$targetFigure.addClass('hide')
      this.$exampleFigure.removeClass('hide')
    }
  }
}

initElement('#js--dashboard_reports-build-choose_type', ($el) => new ReportChooseType($($el)))
