import ModalDialog from '~/components/shared/modal_dialog'
import SearchDropdown from '~/components/shared/search_dropdown'

const BulkAcknowledgeModal = {
  name: 'bulk-acknowledge-modal',
  template: '#template-bulk-acknowledge-modal',
  components: {ModalDialog, SearchDropdown},
  props: {
    withComments: {
      type: Boolean,
      default: false,
    },
    metadata: {
      type: Object,
      require: false,
      default: {},
    },
  },
  data() {
    return {
      reasonCodes: gon.reason_codes,
      selectedReasonCode: null,
      commentText: "",
      correctiveActionText: "",
      preventiveActionText: ""
    }
  },
  computed: {
    reasonCode() {
      return this.reasonCodes.find((rc) => rc.id == this.selectedReasonCode)
    },
    alarmEventIds() {
      return this.metadata?.alarm_event_ids ?? []
    },
    formId() {
      if (this.withComments) {
        return "bulk-alarm-comment-form"
      } else {
        return "bulk-alarm-acknowledge-form"
      }
    }
  },
  methods: {
    clearReasonCode() {
      this.selectedReasonCode = null
    },
    updateReasonCode(reasonCode) {
      this.selectedReasonCode = reasonCode
    },

  }
}

export default BulkAcknowledgeModal
