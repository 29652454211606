/**
The `select-card` component defines a special formatting for radio button-style
inputs, with a large icon and label text.

@name select-card
@prop {string} value
  The value of the input.
@prop {string} radioGroup
  The input group to which this card belongs.
@prop {string} name
  The label text to display.
@prop {string} selected
  The currently selected input's value.
@prop {string} [icon=`null`]
  The optional icon to display.
@prop {string} [description=`null`]
  The optional description text to display.

@template components/shared/select_card
@design https://zpl.io/DlRGjmw
@a11y -
  The select card is a group of radio inputs. When using this component, be sure
  to include a `legend` (the group's label), `div` (to group the inputs),
  and wrap it all in a `fieldset`. Individuals should be able to interact with
  the radio group using a keyboard, mouse, screenreader or other assistive
  technology (AT) with styles applied for hover, and selected states.

  When navigating radio buttons with a keyboard or AT, an individual should be
  able to focus into the group of radio buttons and navigate through them using
  the **arrow** keys.

  When using a screenreader, it should announce the following (order may vary
  base on AT): value or label, the number of options, the current position in
  the number of options, if the radio is selected, and the label for the group.

@wrapper
  <fieldset><legend class="form-label">Radio Group Label</legend><div class="card-grid">{example}</div></fieldset>
@example
  {
    "value": "ambient",
    "radioGroup": "equipment[type]",
    "name": "Ambient",
    "selected": "cold_room",
    "icon": "air",
    "class": "flex-centered flex-column"
  }
@example
  {
    "value": "cold_room",
    "radioGroup": "equipment[type]",
    "name": "Cold Room",
    "selected": "cold_room",
    "icon": "ac_unit",
    "class": "flex-centered flex-column"
  }
@example
  {
    "value": "small",
    "radioGroup": "dashboard[size]",
    "name": "Small",
    "selected": "large",
    "description": "(Computers)",
    "class": "flex-centered flex-column"
  }
@example
  {
    "value": "large",
    "radioGroup": "dashboard[size]",
    "name": "Large",
    "selected": "large",
    "description": "(Large TV)",
    "class": "flex-centered flex-column"
  }
*/

const SelectCard = {
  name: 'select-card',
  template: '#select-card-inputs-template',
  props: ['value', 'radioGroup', 'name', 'selected', 'icon', 'description'],
  computed: {
    isSelected() {
      return this.value == this.selected
    },
  },
}

export default SelectCard
