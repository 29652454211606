import _ from 'underscore'
import moment from '~/moment'

import TimeSlot from '../../services/wrappers/time_slot'

export default {
  name: 'alert-schedule-summary',
  template: '#alert-schedule-summary-template',
  props: {
    schedule: {
      type: Object,
      required: true,
    },
  },
  computed: {
    days() {
      const lang = document.body.lang
      const daySlots = _.groupBy(this.schedule.originalSlots, function(slot) {
        return slot.day
      })

      return _.map(daySlots, function(slots, day) {
        const date = moment().isoWeekday(parseInt(day, 10))
        const name = new Intl.DateTimeFormat(lang, {weekday: 'long'}).format(date)

        return {
          name,
          slots: _.sortBy(_.map(slots, function(slot) {
            return new TimeSlot(slot)
          }), 'startHour'),
        }
      })
    },
  },
  methods: {
    isEmpty: _.isEmpty,
  },
}
