/**
The `modal-dialog` component leverages slots for the "body" content and "footer"
(user actions). Will replace the deprecated `modal` component.

@name modal-dialog
@prop {string} title
  Heading or title of the modal
@prop {string} [size='medium']
  The size of the modal. Valid options include 'small', 'medium', and 'large'.
  Bootstrap default is no specified size/medium.

@template components/shared/modal_dialog
@design https://zpl.io/GEgK9z4\
@a11y -
  When using a keyboard or assitive technology (AT) and upon opening modal, the
  focus should be trapped (i.e., tabbing through elements should remain in
  modal) and individual cannot interact with content behind modal.

  When using a keyboard, pressing **esc** should close the modal.

  When using a screenreader and upon opening modal, the title of the modal
  should be announced.

  When using a screenreader and upon opening modal, it is announced that element
  is a modal (support for this by screenreaders, AT, and browsers is limited).

@example
  {
    "title": "Modal Title"
  }
*/

const ModalDialog = {
  name: 'modal-dialog',
  template: '#modal-dialog-template',
  props: {
    title: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
  },
  computed: {
    classBindings() {
      switch (this.size) {
        case 'small': return ['modal-sm']
        case 'medium': return []
        case 'large': return ['modal-lg']
        default: return []
      }
    },
  },
}

export default ModalDialog
