import _ from 'underscore'
import {initVue} from '~/vue'

import AlarmForm from '../../components/alarms/alarm_form'
import CoverageCalendar from '../../components/alarms/coverage_calendar'
import CustomAlarm from '../../components/alarms/custom_alarm'
import EquipmentHeader from '../../components/equipment/equipment_header'
import TemplateGroupRow from '../../components/alarm_templates/template_group_row'
import AlarmPauseModal from '../../components/alarms/alarm_pause_modal'

import AlarmColor from '../../services/alarm_color'
import Alarm from '../../services/wrappers/alarm'
import EscalationPolicy from '../../services/wrappers/escalation_policy'
import TemplateGroup from '../../services/wrappers/template_group'

initVue('#vue--alarms-index', {
  components: {AlarmForm, CoverageCalendar, CustomAlarm, EquipmentHeader, TemplateGroupRow, AlarmPauseModal},
  data() {
    return {
      colors: new AlarmColor(),
      channelOptions: gon.channels,
      customAlarms: Alarm.wrap(gon.customAlarms),
      soundNotificationWarning: null,
      equipment: gon.equipment,
      device: gon.device,
      escalationPolicies: EscalationPolicy.wrap(gon.escalationPolicies),
      newAlarm: new Alarm,
      showNewAlarmPanel: false,
      templateGroups: TemplateGroup.wrap(gon.templateGroups),
      bulkAlarmPauses: [],
      isSubmitting: false
    }
  },
  computed: {
    alarms() {
      return _.union(this.customAlarms, _.flatten(_.map(this.templateGroups, 'alarms')))
    },
    alarmCount() {
      return _.filter(this.alarms, (alarm) => !alarm.paused).length
    },
    channelCount() {
      return this.channelOptions.length
    },
    hasAlarms() {
      return this.alarms.length > 0
    },
    persistedAlarms() {
      return _.reject(this.customAlarms, function(alarm) {
        return !alarm.id
      })
    },
    showCustomAlarms() {
      return !_.isEmpty(this.persistedAlarms)
    },
    canBulkPause() {
      const pausedAlarm = _.find(this.bulkAlarmPauses, (alarm) => alarm.paused)
      return pausedAlarm === undefined
    }
  },
  methods: {
    updateAlarmPauses(alarm) {
      const alarmPauses = alarm.alarmPauses

      if (alarm.custom) {
        const index = _.findIndex(this.customAlarms, (a) => a.id === alarm.id)

        this.customAlarms[index].alarmPauses = alarmPauses
        this.customAlarms[index].paused = alarm.paused
      } else {
        const index = _.findIndex(this.templateGroups, (g) => g.id === alarm.templateGroupId)
        const alarmIndex = _.findIndex(this.templateGroups[index].alarms, (a) => a.id == alarm.id)

        this.templateGroups[index].alarms[alarmIndex].alarmPauses = alarmPauses
        this.templateGroups[index].alarms[alarmIndex].paused = alarm.paused
      }

      this.unselectAllBulkAlarmPauses()
    },
    saveAlarm(alarm, alarmForm) {
      this.delayWarning = null
      const settings = {
        url: alarm.url,
        method: alarm.id ? 'put' : 'post',
        data: JSON.stringify({alarm: alarm.serialize()}),
        contentType: 'application/json',
      }

      $.ajax(settings)
          .done(function(data, _, xhr) {
            if (xhr.status === 201 || xhr.status === 200) {
              if (xhr.status === 201) {
                this.newAlarm = new Alarm()
              }
              alarmForm.$emit('close')
            }
            this.customAlarms = Alarm.wrapWithDefault(data.customAlarms)
            this.templateGroups = TemplateGroup.wrap(data.templateGroups)
            this.soundNotificationWarning = data.currentAlarm.soundNotificationWarning
          }.bind(this))
          .fail(function(xhr) {
            const alarmData = xhr.responseJSON.currentAlarm
            Object.assign(alarm, new Alarm(alarmData))
          })
    },
    deleteAlarm(alarm, index) {
      const settings = {
        url: alarm.url,
        method: 'delete',
        dataType: 'text',
      }

      $.ajax(settings)
          .done(function() {
            this.customAlarms.splice(index, 1)
          }.bind(this))
          .fail(function(_xhr, _status, error) {
            console.log(error)
          })
    },
    toggleShowNewAlarmPanel(value) {
      this.showNewAlarmPanel = value
    },
    updateBulkAlarmPauses(alarm, action) {
      const index = _.findIndex(this.bulkAlarmPauses, (a) => a.id === alarm.id)

      if (action === "add" && index === -1) {
        this.bulkAlarmPauses.push(alarm)
      }

      if (action === "remove" && index > -1) {
        this.bulkAlarmPauses.splice(index, 1)
      }
    },
    unselectAllBulkAlarmPauses() {
      if (this.bulkAlarmPauses.length >= 1) { this.bulkAlarmPauses = [] }
    },
    unpause() {
      this.isSubmitting = true
      const alarmIds = this.bulkAlarmPauses.map((alarm) => alarm.id)

      $.ajax({
        url: "/pause",
        method: 'DELETE',
        data: {alarm_pause: {alarm_ids: alarmIds}},
        success: (data) => {
          data.forEach((alarm) => this.updateAlarmPauses(alarm))
        }
      }).done(() => {
        this.isSubmitting = false
      })
    },
  },
})
