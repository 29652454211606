/* eslint-disable camelcase */
import $ from 'jquery'

export default class AddLocationModal {
  constructor(el) {
    this._handleResponse = this._handleResponse.bind(this)
    this._handleResponseError = this._handleResponseError.bind(this)

    this.$locationSelect = $(el)
    this.$modal = $('#js--location-modal')
    this.$modal.on('ajax:success', this._handleResponse).on('ajax:error', this._handleResponseError)
  }

  _handleResponse(_, response) {
    const {name, parent_id} = response
    const $parentOption = this.$locationSelect.find(`option[value=${parent_id}]`)

    let prefix = $parentOption.html().match(/(&nbsp;)+/)
    prefix = prefix ? prefix[0] : ''

    $parentOption.after(`<option value='${response.id}'>${prefix}&nbsp;&nbsp; ${name}</option>`)
    this.$locationSelect.val(response.id)
    this.$modal.modal('toggle')
  }

  _handleResponseError(_, response) {
    const $nameInput = this.$modal.find('#location_name')

    $nameInput
        .addClass('is-invalid')
        .after(`<p class='invalid-feedback'>${response.responseJSON.name[0]}</p>`)
  }
}
