import _ from 'underscore'
import MonitoringPointSummary from '../shared/monitoring_point_summary'

/**
The `channel-search` component is useful for selecting channels from a list for
use in a larger workflow. Emits a `select` event when a channel is selected from
the results list.

@name channel-search
@prop {array} channels
  A list of valid channel Objects for selection.
@prop {array} selected
  A list of currently-selected channel Objects. These are suppressed from the
  search results.

@template components/channels/search
@design https://zpl.io/1yrq6eQ

@wrapper
  <div class="channels">{example}</div>
@example
  {
    "channels": [{
      "id": 1,
      "name": "Temperature CH:1",
      "uuid": "5dede3b0-f2a1-4069-b83f-3f35c7a32324",
      "type": "Channel::Temperature",
      "device": {"name": "Chiller", "path": "/devices/645329209841963433", "serial": "420010"},
      "equipment": [{"id": 2, "name": "My Custom Equipment", "path": "/equipment/2"}],
      "location": {"id": 4, "name": "Inventing Room", "path": "/locations/4"},
      "localized_type": "Temperature",
      "sensor": null
    },
    {
      "id": 2,
      "name": "Relative Humidity CH:2",
      "uuid": "f60156a7-9d71-46cf-a378-b1c30cd86600",
      "type": "Channel::RelativeHumidity",
      "device": {"name": "Chiller", "path": "/devices/645329209841963433", "serial": "420010"},
      "equipment": [
        {"id": 3, "name": "My Warehouse", "path": "/equipment/3"},
        {"id": 1, "name": "My Freezer Humidity", "path": "/equipment/1"},
        {"id": 2, "name": "My Custom Equipment", "path": "/equipment/2"}
      ],
      "location": {"id": 4, "name": "Inventing Room", "path": "/locations/4"},
      "localized_type": "Relative Humidity",
      "sensor": "Sensor: dab9aac3f2c04723"
    }],
    "selected": []
  }
*/

const channelSearch = {
  name: 'channel-search',
  template: '#channel-search-template',
  components: {MonitoringPointSummary},
  props: {
    channels: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      searchTerm: '',
      showResults: false,
      focusIndex: 0,
    }
  },
  computed: {
    channelOptions() {
      const selectable = _.chain(this.channels).without(...this.selected)
      return selectable.value()
    },
    matchingChannels() {
      if (!this.showResults) {
        return []
      }

      const search = this.searchTerm.toLowerCase()
      return _.filter(this.channelOptions, (channel) => {
        const attrs = _.union(
            [
              channel.name,
              channel.device.name,
              channel.device.serial,
              channel.localized_type,
              channel.location.name,
              channel.sensor,
            ],
            _.pluck(channel.equipment, 'name'),
        )

        return _.any(attrs, (attr) => (attr || '').toLowerCase().includes(search))
      })
    },
    focusableElements() {
      if (this.showResults) {
        const resultElements = this.$refs.results ? this.$refs.results.map((res) => res.$el) : []
        return [this.$refs.searchInput, ...resultElements]
      }

      return []
    },
  },
  methods: {
    handleFocusOut(event) {
      const nextFocusedElement = event.relatedTarget
      const newIndex = this.focusableElements.indexOf(nextFocusedElement)
      this.showResults = newIndex >= 0
      if (this.showResults && this.focusIndex != newIndex) {
        this.focusIndex = newIndex
      }
    },
    handleKeyDown() {
      this.setFocusIndex(this.focusIndex + 1)
    },
    handleKeyUp() {
      this.setFocusIndex(this.focusIndex - 1)
    },
    setFocusIndex(index) {
      const focusable = this.focusableElements
      const newIndex = focusable.length ? index % focusable.length : 0
      this.focusIndex = newIndex < 0 ? focusable.length - 1 : newIndex
      focusable[this.focusIndex].focus()
    },
    onFocus() {
      this.showResults = true
    },
  },
}

export default channelSearch
