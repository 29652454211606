import moment from '~/moment'

import DatapointRequester from '../../services/charts/datapoint_requester'
import MonitoringPointIcon from '../shared/monitoring_point_icon'
import NumberFormatter from '../../services/number_formatter'

const ChannelSection = {
  name: 'channel-section',
  template: '#channel-section',
  components: {MonitoringPointIcon},
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: this.channel.name,
      unit: this.channel.display_unit,
      currentReading: '-',
      requester: new DatapointRequester(gon.dps_url, {[this.channel.id]: this.channel}),
    }
  },
  mounted() {
    if (!this.channel.timestamp) {
      return
    }

    const timestamp = moment(this.channel.timestamp).valueOf()
    const requester = this.requester
    const formatter = new NumberFormatter()

    requester.fetch(timestamp, timestamp, 1, (channel) => {
      const value = channel.values()[0]
      this.currentReading = formatter.format(value, channel.precision())
    })
  },
  methods: {
    alertType() {
      if (this.channel.alerts.length > 0) {
        const hasExcursion = this.channel.alerts.some((alert) => alert.excursion_at)
        return hasExcursion ? 'error' : 'warning'
      }

      return 'check'
    },
    extraAlertCount() {
      const totalAlerts = this.channel.alerts.length
      return totalAlerts > 1 ? `+${totalAlerts - 1}` : ''
    },
  },
}

export default ChannelSection
