import $ from 'jquery'
import mixin from '../../helpers/mixin'
import WrapperMixin from '../../mixins/wrapper_mixin'

class UserRole {
  constructor(attrs) {
    if (attrs == null) {
      attrs = {}
    }
    this.custom = attrs.custom != null ? attrs.custom : true
    this.enabled = attrs.enabled != null ? attrs.enabled : true
    this.id = attrs.id
    this.name = attrs.name != null ? attrs.name : ''
    this.path = attrs.path != null ? attrs.path : '/user_roles'
  }

  enable() {
    $.ajax({url: `${this.path}/selections`, method: 'post'}).done(() => {
      this.enabled = true
    })
  }

  disable() {
    $.ajax({url: `${this.path}/selections`, method: 'delete'}).done(() => {
      this.enabled = false
    })
  }

  serialize() {
    return {name: this.name}
  }

  validate() {
    return this.name.length > 0
  }
}

mixin.extend(UserRole, WrapperMixin)

export default UserRole
