import mixin from '../../helpers/mixin'
import WrapperMixin from '../../mixins/wrapper_mixin'

class ContactEndpoint {
  constructor(attrs) {
    this.isPrimaryEmail = this.isPrimaryEmail.bind(this)
    this.rawEndpoint = this.rawEndpoint.bind(this)
    this.serialize = this.serialize.bind(this)

    if (attrs == null) {
      attrs = {}
    }

    this._destroy = attrs._destroy != null ? attrs._destroy : false
    this.baseEndpoint = attrs.baseEndpoint != null ? attrs.baseEndpoint : ''
    this.countryCode = attrs.countryCode != null ? attrs.countryCode : ''
    this.errors = attrs.errors != null ? attrs.errors : {}
    this.formattedEndpoint = attrs.formattedEndpoint != null ? attrs.formattedEndpoint : ''
    this.id = attrs.id
    this.label = attrs.label
    this.type = attrs.type != null ? attrs.type : 'EmailContactEndpoint'
    this.primary = attrs.primary != null ? attrs.primary : false
    this.editable = attrs.editable != null ? attrs.editable : true
    this.blacklisted = attrs.blacklisted != null ? attrs.blacklisted : false
  }

  isPrimaryEmail() {
    return (this.type === 'EmailContactEndpoint') && (this.label === 'primary')
  }

  rawEndpoint() {
    if (this.type === 'PhoneContactEndpoint') {
      return [this.countryCode, this.baseEndpoint].join('')
    } else {
      return this.baseEndpoint
    }
  }

  serialize() {
    return {
      _destroy: this._destroy,
      id: this.id,
      label: this.label,
      raw_endpoint: this.rawEndpoint(),
      type: this.type,
    }
  }
}

mixin.extend(ContactEndpoint, WrapperMixin)

export default ContactEndpoint
