const EVENT_LISTENERS = {
  'getting-started': {
    category: 'call-to-action',
    label: 'click on getting started article',
    nonbounce: true,
  },
  'email-support': {
    category: 'support',
    label: 'clicks on email support',
    nonbounce: true,
  },
  'view-support': {
    category: 'support',
    label: 'clicks on support site link',
    nonbounce: true,
  },
}

export default class Analytics {
  constructor($container) {
    if (!(window.ga || window.gtag)) {
      return
    }

    for (const event in EVENT_LISTENERS) {
      const options = EVENT_LISTENERS[event]

      $container.on('click', `[data-analytics='${event}']`, (e) => {
        this.event(options.category, event, options.label, 1, !!options.nonbounce)
      })
    }
  }

  send(options) {
    if (window.ga) {
      window.ga('send', options)
    } else if (window.gtag) {
      window.gtag('event', options.eventAction, options)
    }
  }

  event(category, action, label, value, interactive) {
    this.send({
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
      nonInteraction: !interactive,
    })
  }
}
