import {initVue} from '~/vue'
import VueGridLayout from 'vue-grid-layout'

import DashboardGrid from '../../components/dashboards/dashboard_grid'

initVue('#vue--dashboards-show', {
  components: {DashboardGrid},
  plugins: [VueGridLayout],
  data() {
    return {
      timeFrame: gon.time_frame,
      totalColumns: gon.total_columns,
      widgets: gon.widgets,
      size: gon.size,
      orientation: gon.orientation,
    }
  },
})
