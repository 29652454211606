import Highcharts from '~/highcharts'

export default class ExcursionBreakdown {
  constructor(type) {
    this.type = type
    this.data = gon[this.type]
    this.containerId = `js--${this.type}ExcursionBreakdown`

    Highcharts.chart(this.containerId, {
      chart: {
        type: 'pie',
        margin: [0, 0, 0, 0],
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0,
      },
      credits: {
        enabled: false,
      },
      exporting: false,
      plotOptions: {
        pie: {
          shadow: false,
        },
      },
      series: [
        {
          name: 'Excursions',
          data: this.data,
          innerSize: '70%',
          borderWidth: 2,
          dataLabels: {
            formatter() {
              return this.y
            },
            distance: 3,
            crop: false,
          },
          events: {
            click(e) {
              window.location.href = e.point.path
            },
          },
        },
      ],
      title: false,
    },
    )
  }
}
