export default class User {
  constructor(attrs) {
    if (!attrs) {
      attrs = {}
    }

    this.currentPassword = ''
    this.decimalSeparator = attrs.decimalSeparator
    this.displayTimeZone = attrs.displayTimeZone
    this.email = attrs.email != null ? attrs.email : ''
    this.errors = attrs.errors != null ? attrs.errors : {}
    this.firstName = attrs.firstName != null ? attrs.firstName : ''
    this.id = attrs.id
    this.lastName = attrs.lastName != null ? attrs.lastName : ''
    this.ssoForced = attrs.ssoForced != null ? attrs.ssoForced : false
    this.loggedIn = attrs.loginCount > 0
    this.password = ''
    this.passwordConfirmation = ''
    this.pinNumber = attrs.pinNumber
    this.url = attrs.url
    this.reports = attrs.reports != null ? attrs.reports : []
    this.apiToken = attrs.singleAccessToken
    this.accountId = attrs.accountId
    this.locale = attrs.locale
    this._destroy = false
  }

  serialize() {
    return {
      _destroy: this._destroy,
      id: this.id,
      current_password: this.currentPassword,
      decimal_separator: this.decimalSeparator,
      display_time_zone: this.displayTimeZone,
      email: this.email,
      first_name: this.firstName,
      last_name: this.lastName,
      password: this.password,
      password_confirmation: this.passwordConfirmation,
      pin_number: parseInt(this.pinNumber / 100),
      locale: this.locale,
    }
  }
}
