import _ from 'underscore'
import { Modal } from 'bootstrap'
import ModalDialog from '~/components/shared/modal_dialog'
import DateTimePicker from '~/components/shared/date_time_picker'

const INDEFINITE_VALUE = 1
const CUSTOM_VALUE = -1

const AlarmPauseModal = {
  name: 'alarm-pause-modal',
  template: '#template-alarm-pause-modal',
  components: {ModalDialog, DateTimePicker},
  emits: ['update'],
  props: {
    alarms: {
      type: Array,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    title: {
      type: String,
    },
    url: {
      type: String,
      required: true,
    },
    showChannels: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      pauseComment: "",
      pauseSelection: "",
      startAt: "",
      endAt: "",
      selectedAlarms: this.alarms.map((alarm) => alarm.id),
      isSubmitting: false
    }
  },
  mounted() {
    this.$el.addEventListener('shown.bs.modal', this.setCurrentTime)
  },
  unmounted() {
    this.$el.removeEventListener('shown.bs.modal', this.setCurrentTime)
  },
  computed: {
    calculateEndTime() {
      if(this.endAt) {
        return this.endAt
      } else if (this.pauseSelection === INDEFINITE_VALUE || this.pauseSelection === CUSTOM_VALUE) {
        return null
      } else {
        return moment().add(this.pauseSelection, 'seconds').toISOString()
      }
    },
    customDurationError() {
      return this.endAt && this.endAt < this.startAt
    },
    channelSelectionError() {
      return this.showChannels && !this.selectedAlarms.length
    },
    showBulkWarning() {
      return this.id === "bulk"
    }
  },
  methods: {
    pause() {
      this.isSubmitting = true
      const endTime = this.calculateEndTime

      $.ajax({
        method: 'POST',
        url: this.url,
        data: _.extend({alarm_pause: {alarm_ids: this.selectedAlarms, ends_at: endTime, starts_at: this.startAt}, comment: {text: this.pauseComment}}, this.metadata),})
        .done((data) => {
          this.pauseSelection = ''
          this.pauseComment = ''
          this.startAt = moment().toISOString()
          this.endAt = ''
          this.selectedAlarms = this.alarms.map((alarm) => alarm.id)

          data.forEach((alarm) => this.$emit('update', alarm))
          this.isSubmitting = false
          this.closeModal()
      })
    },
    closeModal() {
      const modalEl = document.getElementById(`js--alarm-pause-modal-${this.id}`)
      const bootstrapModal = Modal.getInstance(modalEl)
      bootstrapModal.hide()
    },
    setCurrentTime() {
      this.startAt = moment().toISOString()
    },
  },
  watch: {
    pauseSelection(newValue, oldValue) {
      if (oldValue === CUSTOM_VALUE) {
        if(this.endAt) this.endAt = ""
      }
    },
    "alarms.length"() {
      this.selectedAlarms = this.alarms.map((alarm) => alarm.id)
    }
  },
}

export default AlarmPauseModal
