import selectMixin from '../mixins/select'

// The nested-select component is an alternative to the simple-select
// component, which uses a template that overrides the multiselect option
// rendering to visually communicate option nesting depth.
export default {
  name: 'nested-select',
  template: '#nested-select-template',
  mixins: [selectMixin],
}
