/**
The `pill-button` component is best used for displaying a selected value as it has a built in close/remove button.
Some common components it's a part of include multi-selects and filters.

@name pill-button
@prop {string} value
  The text to display within the pill.
@prop {string} [size='sm']
  The size of the pill to render. Valid options are 'sm', 'md', and 'lg'.

@template components/pill_button
@design https://zpl.io/B1eG6dq
@a11y -
  Individuals should be able to interact with the pill button using keyboard,
  mouse, screenreader or other assistive technology (AT), and there should be
  hover or focus states for the <i class='material-icons'>close</i> button.
  When using a keyboard or AT, an individual should be able to use the
  <i class='material-icons'>keyboard_return</i> or
  <i class='material-icons'>space_bar</i> keys to complete the action.

  When using a screen reader, once an individual is focused on the
  <i class='material-icons'>close</i> button the icon should not be announced
  and the following should be declared: "*Remove `{value}`*".

  In the example below this would be "*Remove I'm a default small pill + a close
  button!*".

@example
  {
    "value": "I'm a default small pill + a close button!"
  }
@example
  {
    "value": "I'm a medium pill!",
    "size": "md"
  }
*/
const pillButton = {
  name: 'pill-button',
  template: '#pill-button-template',
  props: {
    value: {
      type: String,
      required: true,
    },
    size: {
      type: String, // 'sm' | 'md' | 'lg'
      default: 'sm',
    },
  },
}

export default pillButton
