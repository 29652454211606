import pluralize from '../../helpers/pluralize'
import NotificationRow from '../notifications/notification_row'

export default {
  name: 'escalation-policy-details',
  template: '#escalation-policy-details-template',
  components: {NotificationRow},
  props: {
    policy: {
      type: Object,
      required: true,
    },
    device: {
      type: Object,
      required: false,
    },
    showDelayInterval: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    showHardwareAlerts(group) {
      return group.hasHardwareNotification() && this.device.supportsHardwareAlerts
    },
    pluralize,
  },
}
