import $ from 'jquery'
import _ from 'underscore'
import {createGrid} from 'ag-grid-community'

import {initVue} from '~/vue'
import DaterangeFilter from '../../components/shared/daterange_filter'
import MultiselectFilter from '../../components/shared/multiselect_filter'

import GridFilter from '../../components/mixins/grid_filter'

import DateRenderer from '../../renderers/date_renderer'
import DateTimeRenderer from '../../renderers/date_time_renderer'
import initElement from '../../helpers/init_element'

const calibrationFilters = initVue('#vue--calibrations-index', {
  mixins: [GridFilter],
  components: {DaterangeFilter, MultiselectFilter},
  data() {
    return {
      appliedFilters: {
        calibrationDate: {},
        calibrationDueDate: {},
        adjustedSensorsCount: {},
        adjustmentType: [],
        labName: [],
        referenceNumber: [],
        sensorCalibrationsCount: {},
        technicianName: [],
      },
      selectedFilters: {
        adjustedSensorsCount: {},
        adjustmentType: [],
        labName: [],
        referenceNumber: [],
        sensorCalibrationsCount: {},
        technicianName: [],
      },
      options: {
        labName: _.uniq(_.pluck(gon.data, 'lab_name')).sort(),
        referenceNumber: _.uniq(_.pluck(gon.data, 'reference_number')).sort(),
        technicianName: _.uniq(_.flatten(_.pluck(gon.data, 'technician_name'))).sort(),
      },
      filters() {
        return {
          calibrationDate: this.doesCalibrationDatePass,
          calibrationDueDate: this.doesCalibrationDueDatePass,
          adjustedSensorsCount: this.doesAdjustedSensorsCountPass,
          labName: 'lab_name',
          referenceNumber: 'reference_number',
          sensorCalibrationsCount: this.doesSensorCalibrationsCountPass,
          technicianName: 'technician_name',
        }
      },
      customFilterPills() {
        return {
          adjustedSensorsCount: {
            label: gon.labels['x_sensors_adjusted'].replace('%{adjustedSensorsCountToString}', this.adjustedSensorsCountToString),
            onClose: this.clearAdjustedSensorsCount,
          },
          sensorCalibrationsCount: {
            label: gon.labels['x_sensors_calibrated'].replace('%{sensorCalibrationsCountToString}', this.sensorCalibrationsCountToString),
            onClose: this.clearSensorCalibrationsCount,
          },
        }
      },
      adjustedSensorsCountRadio: '',
      sensorCalibrationsCountRadio: '',
      panelFilters: ['adjustedSensorsCount', 'labName', 'referenceNumber', 'sensorCalibrationsCount', 'technicianName'],
      rowCount: gon.data.length,
    }
  },
  computed: {
    adjustedSensorsCountToString() {
      if (this.appliedFilters.adjustedSensorsCount.min || this.appliedFilters.adjustedSensorsCount.max) {
        return $('[name=adjusted_sensors_count]:checked').parent().text().trim()
      }
    },
    sensorCalibrationsCountToString() {
      if (this.appliedFilters.sensorCalibrationsCount.min || this.appliedFilters.sensorCalibrationsCount.max) {
        return $('[name=sensor_calibrations_count]:checked').parent().text().trim()
      }
    },
  },
  watch: {
    adjustedSensorsCountRadio(newValue) {
      const radioValueToRange = {less_than_25: {max: 24}, from_25_to_50: {min: 25, max: 50}, more_than_50: {min: 51}}
      this.selectedFilters.adjustedSensorsCount = radioValueToRange[newValue] || {}
    },
    sensorCalibrationsCountRadio(newValue) {
      const radioValueToRange = {less_than_50: {max: 49}, from_50_to_100: {min: 50, max: 100}, more_than_100: {min: 101}}
      this.selectedFilters.sensorCalibrationsCount = radioValueToRange[newValue] || {}
    },
  },
  methods: {
    clearAllFilters() {
      this.clearDateRanges()
      this.clearPanelFilters()
      this.applyPanelFilters()
    },
    clearDateRanges() {
      this.appliedFilters.calibrationDate = {}
      this.appliedFilters.calibrationDueDate = {}
    },
    clearAdjustedSensorsCount() {
      this.adjustedSensorsCountRadio = ''
      this.appliedFilters.adjustedSensorsCount = {}
      this.updateFilters()
    },
    clearSensorCalibrationsCount() {
      this.sensorCalibrationsCountRadio = ''
      this.appliedFilters.sensorCalibrationsCount = {}
      this.updateFilters()
    },
    doesRangeFilterPass(filterValue, rowValue) {
      const passMin = _.isUndefined(filterValue.min) || filterValue.min <= rowValue
      const passMax = _.isUndefined(filterValue.max) || filterValue.max >= rowValue

      return passMin && passMax
    },
    doesAdjustedSensorsCountPass(row) {
      return this.doesRangeFilterPass(this.appliedFilters.adjustedSensorsCount, row.data.adjusted_sensors_count)
    },
    doesSensorCalibrationsCountPass(row) {
      return this.doesRangeFilterPass(this.appliedFilters.sensorCalibrationsCount, row.data.sensor_calibrations_count)
    },
    doesCalibrationDatePass(row) {
      return this.doesDateRangeFilterPass(this.appliedFilters.calibrationDate, row.data.calibration_date)
    },
    doesCalibrationDueDatePass(row) {
      return this.doesDateRangeFilterPass(this.appliedFilters.calibrationDueDate, row.data.calibration_due_date)
    },
    onPanelFiltersCleared() {
      this.adjustedSensorsCountRadio = ''
      this.sensorCalibrationsCountRadio = ''
    },
  },
})

initElement('#vue--calibrations-index', () => {
  const grid = createGrid(document.querySelector('#grid--calibrations-index'), {
    pagination: true,
    paginationPageSize: gon.page_size,
    paginationPageSizeSelector: false,
    isExternalFilterPresent() {
      return calibrationFilters.isFilterPresent
    },
    doesExternalFilterPass(node) {
      return calibrationFilters.doesFilterPass(node)
    },
    rowStyle: {background: 'white'},
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    suppressColumnVirtualisation: true,
    domLayout: 'autoHeight',
    defaultColDef: {
      resizable: true,
      cellStyle: {
        'white-space': 'normal',
        'padding': '10px',
      },
      autoHeight: true,
      sortable: true,
      filter: false,
      maxWidth: 200,
    },
    columnDefs: [
      {
        headerName: gon.labels['reference_number'],
        field: 'reference_number',
        cellRenderer: 'ReferenceNumberRenderer',
      },
      {
        headerName: gon.labels['calibration_date'],
        field: 'calibration_date',
        cellRenderer: DateRenderer,
      },
      {
        headerName: gon.labels['calibration_due_date'],
        field: 'calibration_due_date',
        cellRenderer: DateRenderer,
      },
      {
        headerName: gon.labels['lab_name'],
        field: 'lab_name',
      },
      {
        headerName: gon.labels['technician_name'],
        field: 'technician_name',
      },
      {
        headerName: gon.labels['sensor_calibrations_count'],
        field: 'sensor_calibrations_count',
      },
      {
        headerName: gon.labels['adjusted_sensors_count'],
        field: 'adjusted_sensors_count',
      },
      {
        headerName: gon.labels['submitted_at'],
        field: 'submitted_at',
        cellRenderer: DateTimeRenderer,
      },
    ],
    rowData: gon.data,
    onGridReady(event) {
      event.api.autoSizeAllColumns()
      event.api.setGridAriaProperty('label', gon.labels['calibrations_table'])
    },
    onCellKeyDown({column, node: {data}, event: {key}}) {
      if (column.colId === 'reference_number' && key === 'Enter') {
        location.href = data.calibration_path
      }
    },
    components: {
      ReferenceNumberRenderer(params) {
        return `<a href='${params.data.calibration_path}'>${params.value}</a>`
      },
    },
  })

  calibrationFilters.grid = grid
})
