import ErrorMessage from '../shared/error_message'

export default {
  name: 'time-slot',
  template: '#time-slot-template',
  components: {ErrorMessage},
  props: {
    timeSlot: {
      type: Object,
      required: true,
    },
  },
}
