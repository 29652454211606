import _ from 'underscore'
import {initVue} from '~/vue'

import FloorplanArea from '../../components/floorplans/floorplan_area'
import ModalDialog from '../../components/shared/modal_dialog'
import Uploader from '../../components/shared/uploader'
import SearchDropdown from '../../components/shared/search_dropdown'

// The interval in milliseconds to auto-update the floorplan
const UPDATE_INTERVAL = 10_000

initVue('#vue--floorplans-index', {
  components: {FloorplanArea, SearchDropdown, ModalDialog, Uploader},
  data() {
    let selectedFloorplan = gon.selected_id ? _.findWhere(gon.floorplans, {id: gon.selected_id}) : gon.floorplans[0]
    selectedFloorplan ||= gon.floorplans[0]

    return {
      selectedFloorplan,
      images: [],
      floorplans: gon.floorplans,
      interval: null,
      uploadCompleted: false,
    }
  },
  computed: {
    editPath() {
      return `${this.selectedFloorplan.path}/edit`
    },
    getFloorplanName() {
      return `${gon.floorplan_prefix}: ${this.selectedFloorplan.name}`
    },
    floorplanOptions() {
      return this.floorplans.map((fp) => {
        return {value: fp, label: fp.name}
      })
    },
  },
  methods: {
    addImage(path) {
      this.images.push(path)
    },
    handleStatusChange(status) {
      this.uploadCompleted = status === 'success'
    },
    removeImage(path) {
      this.images.splice(this.images.indexOf(path), 1)
    },
    selectFloorplan(id) {
      const floorplan = this.floorplans.find((f) => f.id === id)
      this.selectedFloorplan = floorplan
    },
    updateFloorplan() {
      $.ajax({
        url: this.selectedFloorplan.path,
        dataType: 'json',
        success: (response) => {
          this.selectedFloorplan = response
        },
      })
    },
  },
  mounted() {
    this.interval = setInterval(this.updateFloorplan, UPDATE_INTERVAL)
  },
  unmounted() {
    clearInterval(this.interval)
  },
})
