import moment from '~/moment'
import _ from 'underscore'
import AlarmPauseModal from '../alarms/alarm_pause_modal'
import TemplateRow from './template_row'
import { currentAlarmPause } from '../alarms/alarm_utils'

export default {
  name: 'template-group-row',
  template: '#template-group-row-template',
  components: {TemplateRow, AlarmPauseModal},
  props: ['templateGroup', 'colors', 'editable', 'pauseUrl', 'bulkAlarmPauses'],
  data() {
    return {
      isSubmitting: false
    }
  },
  computed: {
    pausedAlarm() {
      return _.find(this.templateGroup.alarms, (alarm) => alarm.paused === true)
    },
    paused() {
      return this.pausedAlarm !== undefined
    },
    alarmPauseDetails() {
      const alarm = this.pausedAlarm

      if(this.paused) {
        return currentAlarmPause(alarm)
      }
    },
    isChecked() {
      return _.includes(this.bulkAlarmPauses, this.templateGroup.alarms[0])
    }
  },
  methods: {
    timestamp(isoString) {
      return moment(isoString).format(momentDateTimeFormat)
    },
    unpause() {
      this.isSubmitting = true
      const alarmIds = this.templateGroup.alarms.map((alarm) => alarm.id)

      $.ajax({
        url: this.pauseUrl,
        method: 'DELETE',
        data: {alarm_pause: {alarm_ids: alarmIds}},
        success: (data) => {
          data.forEach((alarm) => this.$emit('update', alarm))
          this.$emit('unselect-bulk-pauses')
        }
      }).done(() => {
        this.isSubmitting = false
      })
    },
    updateBulkAlarmPause(event) {
      const action = event.target.checked ? "add" : "remove"
      this.templateGroup.alarms.forEach((alarm) => {
        this.$emit("update-bulk-alarm", alarm, action)
      }
    )}
  }
}
