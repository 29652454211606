import $ from 'jquery'

import NumericInput from '~/components/shared/numeric_input'
import FlashMessage from '~/services/flash_message'

const ReferenceLines = {
  name: 'reference-lines',
  template: '#reference-lines-template',
  components: {NumericInput},
  props: {
    channels: {
      type: Array,
      required: true,
    },
    chartCoordinator: {
      type: Object,
    },
  },
  data() {
    const referenceLineUrl = `${gon.base_url}/reference_lines`
    return {
      referenceLines: [],
      referenceLineUrl,
      referenceLineUrls: [
        `${gon.base_url}/alarm_reference_lines`,
        referenceLineUrl,
      ],
    }
  },
  methods: {
    channelDisplayName(referenceLine) {
      const displayUnit = referenceLine.display_unit ? ` (${referenceLine.display_unit})` : ''
      return `${referenceLine.channel_name}${displayUnit}`
    },
    onCancel(referenceLine) {
      if (referenceLine.id) {
        referenceLine.editing = false
      } else {
        this.referenceLines.splice(this.referenceLines.indexOf(referenceLine), 1)
      }
    },
    onDelete(referenceLine, event) {
      const confirmationText = event.target.getAttribute('data-confirmation')
      if (!confirm(confirmationText)) {
        return
      }

      $.ajax({
        url: referenceLine.url,
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        success: (response) => {
          this.referenceLines.splice(this.referenceLines.indexOf(referenceLine), 1)
          const channelNum = parseInt(referenceLine.channel, 10)
          this.chartCoordinator.chartMap[channelNum].removePlotLine(referenceLine)
          new FlashMessage(response.message, 'notice', {modal: true})
        },
      })
    },
    onEdit(referenceLine) {
      referenceLine.editing = true
    },
    onNewReferenceLine() {
      this.referenceLines.push({channel: this.channels[0].id, value: 0, editing: true})
    },
    onSave(referenceLine) {
      const data = JSON.stringify({
        reference_line: {
          channel: referenceLine.channel,
          value: referenceLine.value,
        },
      })

      const url = referenceLine.url ? referenceLine.url : this.referenceLineUrl
      const method = referenceLine.id ? 'PATCH' : 'POST'
      const headers = {'Content-Type': 'application/json'}
      $.ajax({
        url,
        method,
        headers,
        data,
        success: (data) => {
          const index = this.referenceLines.indexOf(referenceLine)
          referenceLine = data.reference_line
          referenceLine.editing = false
          this.referenceLines[index] = referenceLine

          const channelNum = parseInt(referenceLine.channel, 10)
          this.chartCoordinator.chartMap[channelNum].addOrUpdatePlotLine(referenceLine)
        },
      })
    },
  },
  created() {
    this.referenceLineUrls.forEach((url) => {
      $.ajax({
        url,
        success: (data) => {
          this.referenceLines = [...this.referenceLines, ...data.reference_lines]
        },
      })
    })
  },
}

export default ReferenceLines
