import $ from '~/jquery'
import moment from '~/moment'
import _ from 'underscore'

import {initVue} from '~/vue'

import AlertRow from '~/components/alerts/alert_row'
import AlertModal from '~/components/alerts/alert_modal'
import DateTimePicker from '~/components/shared/date_time_picker'
import ModalDialog from '~/components/shared/modal_dialog'
import MultiselectFilter from '~/components/shared/multiselect_filter'
import Paginator from '~/components/shared/paginator'
import SnoozeForm from '~/components/alerts/snooze_form'
import BulkAcknowledgeModal from '../../components/alerts/bulk_acknowledge_modal'

import FilterSet from '~/services/filter_set'
import pluralize from '~/helpers/pluralize'

const DEFAULT_FILTERS = {
  active: false,
  acknowledgement_status: '',
  severity: '',
  started_before: '',
  started_after: '',
  ended_before: '',
  ended_after: '',
  type: '',
  channel_ids: [],
  location_ids: [],
  tokens: [],
}

initVue('#vue--alarm_events-index', {
  components: {AlertRow, AlertModal, DateTimePicker, ModalDialog, MultiselectFilter, Paginator, SnoozeForm, BulkAcknowledgeModal},
  data: () => {
    const filters = new FilterSet(DEFAULT_FILTERS)
    filters.update(gon.filters)

    filters.map('tokens', {
      get(f) {
        return _.map(f, (token) => _.find(gon.devices, (d) => d.value == token))
      },
      set(v) {
        return _.map(v, 'value')
      },
    })

    filters.map('location_ids', {
      get(f) {
        return _.map(f, (id) => _.find(gon.locations, (l) => l.value == id))
      },
      set(v) {
        return _.map(v, 'value')
      },
    })

    return {
      alerts: gon.alerts,
      devices: gon.devices,
      locations: gon.locations,
      locales: gon.locales,
      pagination: gon.pagination,
      bulkSnoozeSubmittable: false,
      rowsExpanded: false,
      showFilterPanel: false,
      selectedAlert: null,
      selected: [],
      filters,
    }
  },
  mounted() {
    window.addEventListener('popstate', (event) => {
      this.loadAlerts(event.state)
    })
  },
  beforeUnmount() {
    window.removeEventListener('popstate')
  },
  computed: {
    isFiltered() {
      return this.appliedFilters.length > 0
    },
    appliedFilters() {
      return this.filters.applied()
    },
    bulkRequestMetadata() {
      return {alarm_event_ids: this.selected}
    },
    bulkSelectedSnooze() {
      return _.any(this.selected, (id) => _.any(this.alerts, (alert) => alert.id === id && !!alert.current_snooze))
    },
  },
  methods: {
    updateAlert(alert) {
      const selectedIndex = this.alerts.indexOf(this.selectedAlert)
      const index = _.findIndex(this.alerts, (a) => a.id === alert.id)

      this.alerts[index] = alert
      if (index === selectedIndex) {
        this.selectedAlert = this.alerts[index]
      }
    },
    loadAlerts(url) {
      return $.ajax({
        url,
        method: 'GET',
      }).done((response) => {
        this.alerts = response.data
        this.filters.update(response.meta.filters)
        this.pagination = response.meta.pagination
      })
    },
    changePage(pageNumber) {
      const url = new URL(window.location.href)
      url.searchParams.set('page[number]', pageNumber)
      url.searchParams.set('page[size]', this.pagination.page_size)

      history.pushState(url.toString(), '', url)
      this.loadAlerts(url)
    },
    openModal(alert) {
      this.selectedAlert = alert
      $('#js--alert-modal').modal('show')
    },
    openFilterPanel() {
      this.showFilterPanel = true
      this.filters.resetSelection()
    },
    closeFilterPanel() {
      this.showFilterPanel = false
    },
    applyFilters() {
      this.filters.applySelections()
      this.updateFilters()
    },
    clearFilter(filter) {
      this.filters.clear(filter)
      this.updateFilters()
    },
    clearAllFilters() {
      this.filters.clearAll()
      this.updateFilters()
    },
    updateFilters() {
      const url = new URL(window.location.href)
      this.filters.applySearchParams(url.searchParams, 'alert_search_form')
      url.searchParams.set('page[number]', 1)

      history.pushState(url.toString(), '', url)

      this.loadAlerts(url).done(() => this.closeFilterPanel())
    },
    isSelected(alert) {
      return _.contains(this.selected, alert.id)
    },
    select(alert, event) {
      if (this.isSelected(alert)) {
        if (!event.target.checked) {
          this.selected = _.without(this.selected, alert.id)
        }
      } else {
        if (event.target.checked) {
          this.selected.push(alert.id)
        }
      }
    },
    localize(text) {
      if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(text)) {
        return moment(text).format(momentDateTimeFormat)
      } else {
        return this.locales[text] || text
      }
    },
    expandAllRows() {
      this.rowsExpanded = true
      $('[data-bs-toggle="collapse"][aria-expanded="false"]').click()
    },
    collapseAllRows() {
      this.rowsExpanded = false
      $('[data-bs-toggle="collapse"][aria-expanded="true"]').click()
    },
    deselectAll() {
      this.selected = []
    },
    toggleSelectAll(event) {
      if (event.target.checked) {
        const ids = _.map(this.alerts, 'id')
        this.selected = _.union(this.selected, ids)
      } else {
        this.deselectAll()
      }
    },
    onBulkSnoozeChange(value) {
      this.bulkSnoozeSubmittable = value
    },
    bulkCancelSnooze() {
      $.ajax({
        method: 'DELETE',
        url: `/snooze`,
        data: this.bulkRequestMetadata,
      }).done((data) => {
        data.forEach((alert) => this.updateAlert(alert))
      })
    },
    pluralize,
  },
})
