import _ from 'underscore'
import Multiselect from 'vue-multiselect'

export default {
  name: 'escalation-policy-select',
  template: '#escalation-policy-select-template',
  components: {Multiselect},
  props: {
    model: {
      type: Object,
      required: true,
    },
    policies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedPolicy: null,
      policiesById: _.indexBy(this.policies, 'id'),
    }
  },
  computed: {
    policyOptions() {
      return this.policies.map(function(policy) {
        const app = this.findPolicyApplication(policy)
        const isDisabled = app ? !app._destroy : false
        return {
          id: policy.id,
          name: policy.name,
          $isDisabled: isDisabled,
        }
      }, this)
    },
    appliedPolicies() {
      return this.model.visiblePolicyApplications().map(function(app) {
        return this.policiesById[app.escalationPolicyId]
      }, this)
    },
  },
  methods: {
    onSelect() {
      if (!this.selectedPolicy) {
        return
      }
      const app = this.findPolicyApplication(this.selectedPolicy)
      if (app) {
        app._destroy = false
      } else {
        this.model.escalationPolicyApplications.push({
          escalationPolicyId: this.selectedPolicy.id,
        })
      }
    },
    findPolicyApplication(policy) {
      return this.model.escalationPolicyApplications.find(function(app) {
        return app.escalationPolicyId === policy.id
      })
    },
    removePolicy(policy) {
      const app = this.findPolicyApplication(policy)
      if (app.id) {
        app._destroy = true
      } else {
        const index = this.model.escalationPolicyApplications.indexOf(app)
        this.model.escalationPolicyApplications.splice(index, 1)
      }
      this.selectedPolicy = null
    },
  },
  watch: {
    'model'(newVal, oldVal) {
      this.selectedPolicy = null
    },
  },
}
