import moment from '~/moment'

// UTCDateRenderer is a custom cell renderer for ag-grid that displays a date in UTC time.
export default class UTCDateRenderer {
  init(params) {
    this.eGui = document.createElement('span')
    this.eGui.innerHTML = params.value ? moment(params.value).tz('UTC').format(momentDateFormat) : '-'
  }

  getGui() {
    return this.eGui
  }

  refresh(params) {
    return false
  }
}
