import VueGridLayout from 'vue-grid-layout'
import {initVue} from '~/vue'

import DashboardGrid from '../../../components/dashboards/dashboard_grid'
import DashboardShareModal from '../../../components/dashboards/share_modal'

initVue('#vue--dashboards-widgets-index', {
  components: {DashboardGrid, DashboardShareModal},
  plugins: [VueGridLayout],
  data() {
    return {
      dashboard: gon.dashboard,
      totalColumns: gon.total_columns,
      widgets: gon.widgets,
      size: gon.size,
      timeFrame: gon.time_frame,
      orientation: gon.orientation,
    }
  },
})
