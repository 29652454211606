import moment from '~/moment'

import mixin from '../../helpers/mixin'
import EventsMixin from '../../mixins/events_mixin'

class RangeSelect {
  constructor($container, options) {
    this.minTime = this.minTime.bind(this)
    this.maxTime = this.maxTime.bind(this)
    this._updateInputs = this._updateInputs.bind(this)
    this._toMilliseconds = this._toMilliseconds.bind(this)
    this._updateCannedRange = this._updateCannedRange.bind(this)

    this.$container = $container
    this.$startInput = this.$container.find('input[data-range=start]')
    this.$endInput = this.$container.find('input[data-range=end]')

    this.$container.daterangepicker($.extend({}, options, this._defaultOptions()), this._updateCannedRange)
    this.picker = this.$container.data('daterangepicker')

    this.$container.on('apply.daterangepicker', this._updateInputs)
    this.$container.trigger('apply.daterangepicker', this.picker)
  }

  minTime() {
    return this._toMilliseconds(this.$startInput.val())
  }

  maxTime() {
    return this._toMilliseconds(this.$endInput.val())
  }

  _updateInputs() {
    const startsAt = this.picker.startDate.format(momentDateTimeFormat)
    const endsAt = this.picker.endDate.format(momentDateTimeFormat)

    this.$startInput.val(startsAt)
    this.$endInput.val(endsAt)

    this.$container.find('span').html(`${startsAt} &mdash; ${endsAt}`)
    this.trigger('updated', this.picker)
  }

  _toMilliseconds(datetimeText) {
    const timeInSeconds = moment(datetimeText, momentDateTimeFormat).format('X')
    return parseInt(timeInSeconds, 10) * 1000
  }

  _updateCannedRange(_start, _end, label) {
    this.trigger('cannedRange', label)
  }

  _defaultOptions() {
    return {
      alwaysShowCalendars: true,
      linkedCalendars: false,
      locale: {
        format: momentDateTimeFormat,
        separator: ' &mdash; ',
      },
      showDropdowns: true,
      showCustomRangeLabel: false,
      timePicker: true,
      timePicker24Hour: !momentDateTimeFormat.includes('a'),
      timePickerSeconds: true,
    }
  }
}

mixin.include(RangeSelect, EventsMixin)

export default RangeSelect
