import _ from 'underscore'

import equipmentIcon from '../../helpers/equipment_icon'
import PillLink from '../shared/pill_link'

const MAX_VISIBLE_DEVICES = 4

const EquipmentHeader = {
  name: 'equipment-header',
  template: '#equipment-header',
  components: {PillLink},
  props: {
    equipment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      devices: gon.devices,
      expanded: false,
    }
  },
  computed: {
    deviceCount() {
      return this.devices.length
    },
    expandIcon() {
      return this.expanded ? 'expand_less' : 'expand_more'
    },
    typeIcon() {
      return equipmentIcon(this.equipment)
    },
    canExpand() {
      return this.devices.length > MAX_VISIBLE_DEVICES
    },
    firstDevices() {
      return _.first(this.devices, MAX_VISIBLE_DEVICES)
    },
    remainingDevices() {
      return _.rest(this.devices, MAX_VISIBLE_DEVICES)
    },
  },
  methods: {
    expand() {
      this.expanded = !this.expanded
    },
  },
}

export default EquipmentHeader
