import $ from 'jquery'
import {initVue} from '~/vue'
import VueGridLayout from 'vue-grid-layout'

import DashboardGrid from '../../components/dashboards/dashboard_grid'
import DashboardShareModal from '../../components/dashboards/share_modal'
import FloorplanArea from '../../components/floorplans/floorplan_area'
import SearchDropdown from '../../components/shared/search_dropdown'

import ExcursionBreakdown from '../../services/dashboards/excursion_breakdown'

const DICKSONONE_DASHBOARD_ID = 0

initVue('#vue--dashboards-index', {
  components: {DashboardGrid, DashboardShareModal, SearchDropdown, FloorplanArea},
  plugins: [VueGridLayout],
  data() {
    const dashboards = gon.dashboards
    const defaultDashboardId = dashboards.find((d) => d.id === gon.default_dashboard_id)?.id || DICKSONONE_DASHBOARD_ID

    return {
      dashboards,
      defaultDashboardId,
      selectedDashboardId: defaultDashboardId,
    }
  },
  mounted() {
    this.initDashboard()
  },
  computed: {
    dashboardName() {
      return `${gon.dashboard_prefix}: ${this.selectedDashboard.name}`
    },
    dicksonOneDashboardSelected() {
      return this.selectedDashboardId === DICKSONONE_DASHBOARD_ID
    },
    editPath() {
      return this.selectedDashboard.edit_path
    },
    isDefault() {
      return this.selectedDashboardId === this.defaultDashboardId
    },
    selectedDashboard() {
      return this.dashboards.find((d) => d.id === this.selectedDashboardId) || this.dashboards[0]
    },
    customDashboardLoaded() {
      return !this.dicksonOneDashboardSelected && !!this.selectedDashboard.widgets
    }
  },
  methods: {
    initDashboard() {
      if (this.dicksonOneDashboardSelected) {
        if ($('#js--channelsExcursionBreakdown').length > 0) {
          new ExcursionBreakdown('channels')
        }

        if ($('#js--devicesExcursionBreakdown').length > 0) {
          new ExcursionBreakdown('devices')
        }
      } else {
        this.loadDashboard(this.selectedDashboardId)
      }
    },
    loadDashboard(id) {
      const dashboard = this.dashboards.find((d) => d.id === id)
      if (dashboard.widgets) { return }

      $.ajax({
        url: dashboard.url,
        type: 'GET',
        success: (response) => {
          dashboard.widgets = response.widgets
        },
      })
    },
    selectDashboard(id) {
      this.selectedDashboardId = id
    },
    setDefaultDashboard() {
      $.ajax({
        url: '/preferences',
        type: 'POST',
        data: {
          preference: 'default_dashboard',
          value: this.selectedDashboardId,
        },
        success: (_data) => {
          this.defaultDashboardId = this.selectedDashboardId
        },
      })
    },
  },
  watch: {
    selectedDashboardId() {
      setTimeout(this.initDashboard, 300)
    },
  },
})
