import $ from 'jquery'
import _ from 'underscore'
import NestedSelect from '../shared/nested_select'

export default {
  name: 'location-select',
  template: '#location-select-template',
  components: {NestedSelect},
  props: {
    locationOptions: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Number,
      required: true,
    },
  },
  computed: {
    selectedLocation() {
      return _.find(this.locationOptions, (location) => location.id == this.modelValue)
    },
  },
  methods: {
    addLocation() {
      this.$emit('add-location')
      $('#js--location-modal').modal('toggle')
    },
    onUpdate(event) {
      this.$emit('update:modelValue', event.id)
    },
  },
}
