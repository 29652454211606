import Multiselect from 'vue-multiselect'

export default {
  components: {Multiselect},
  props: {
    valueMethod: {
      type: String,
      required: false,
      default: 'value',
    },
    modelValue: {
      type: [Array, String, Object, Number, null],
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    labelMethod: {
      type: String,
      required: false,
      default: 'label',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    noResult: {
      type: String,
      required: false,
      default: 'No results',
    },
    allowEmpty: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    selection() {
      return this.options.find((option) => option[this.valueMethod] === this.modelValue)
    },
  },
  methods: {
    onSelect(event) {
      this.$emit('update:modelValue', (event ? event[this.valueMethod] : null))
    },
  },
}
