import _ from 'underscore'
import AlarmNotification from '../services/wrappers/alarm_notification'

export default {
  visibleNotifications() {
    return _.flatten(this.notificationGroups.map((group) => group.visibleNotifications()))
  },

  visibleNotificationGroups() {
    return this.notificationGroups.filter((group) => group.visibleNotifications().length > 0)
  },

  allowAddNotificationGroup() {
    return this.visibleNotificationGroups().length < gon.maxEscalationLevels
  },

  allowSaveNotificationGroup() {
    return (this.visibleNotifications().length >= 1) ||
      this.addHardwareNotification ||
      (typeof this.visiblePolicyApplications === 'function' ? this.visiblePolicyApplications().length : undefined)
  },

  existingHardwareNotification() {
    let notification = null

    _.any(this.notificationGroups, (g) => notification = _.detect(g.notifications, (n) => n.hardware()))

    return notification
  },

  existingHardwareDelayInterval() {
    const group = _.detect(this.notificationGroups, (g) => _.any(g.notifications, (n) => n.hardware()))

    return group ? group.delayInterval : 0
  },

  existingHardwareExcursion() {
    const group = _.detect(this.notificationGroups, (g) => _.any(g.notifications, (n) => n.hardware()))

    return group ? group.excursion : true
  },

  serializedNotifications() {
    const other = this.notificationGroups.map((group) => group.serialize())
    let audible = this.existingHardwareNotification()

    if (!this.soundProhibited && ((audible != null) || this.addHardwareNotification)) {
      if (!audible) {
        audible = new AlarmNotification({type: 'HardwareAlarmNotification'})
      }
      const hardware = Object.assign(audible.serialize(), {
        delay_interval: this.hardwareDelayInterval,
        excursion: this.hardwareExcursion,
        _destroy: !this.addHardwareNotification,
      })

      return _.flatten([hardware, other])
    } else {
      return _.flatten(other)
    }
  },
}
