import pinIcon from '../../helpers/pin_icon'

const DEFAULT_ADJUST_KEYBOARD_PIN_POSITION = 0.05
const SHIFT_ADJUST_KEYBOARD_PIN_POSITION = 1
const MAX_POSITION = 100
const MIN_POSITION = 0

const DraggablePin = {
  name: 'draggable-pin',
  template: '#draggable-pin-template',
  props: ['name', 'type', 'position'],
  data() {
    return {
      top: this.position.top,
      left: this.position.left,
      initialPointerPosition: {x: 0, y: 0},
      currentPointerPosition: {x: 0, y: 0},
      initialPinPosition: {top: 0, left: 0},
    }
  },
  computed: {
    icon() {
      return pinIcon(this)
    },
  },
  methods: {
    onDragstart(event) {
      event.dataTransfer.effectAllowed = 'move'
      setTimeout(() => $(event.target).addClass('hide'), 0)

      this.initialPointerPosition = {x: event.pageX, y: event.pageY}
      this.currentPointerPosition = {x: event.pageX, y: event.pageY}

      $(event.target.parentElement).on('dragover', (e) => {
        this.currentPointerPosition = {x: e.pageX, y: e.pageY}
      })
    },
    onDragend(event) {
      const newPosition = this.calculateNewPosition(event)
      $(event.target.parentElement).off('dragover')

      this.top = newPosition.top
      this.left = newPosition.left
      this.initialPinPosition = {top: this.top, left: this.left}
      this.$emit('move', {position: newPosition})

      $(event.target).removeClass('hide')
    },
    calculateNewPosition(event) {
      const dropArea = event.target.parentElement.getBoundingClientRect()

      const offsetXInPx = this.currentPointerPosition.x - this.initialPointerPosition.x
      const offsetX = (offsetXInPx / dropArea.width) * 100

      const offsetYInPx = this.currentPointerPosition.y - this.initialPointerPosition.y
      const offsetY = (offsetYInPx / dropArea.height) * 100

      const top = Math.min(Math.max(this.top + offsetY, MIN_POSITION), MAX_POSITION)
      const left = Math.min(Math.max(this.left + offsetX, MIN_POSITION), MAX_POSITION)

      return {top, left}
    },
    handleDelete() {
      this.$emit('delete')
    },
    handleFocus() {
      this.initialPinPosition = {top: this.top, left: this.left}
    },
    handleSave() {
      if (this.top == this.initialPinPosition.top && this.left == this.initialPinPosition.left) return

      const newPosition = {
        'top': this.top,
        'left': this.left,
      }

      this.$emit('move', {position: newPosition})
    },
    movePinVertical(event) {
      const stringAsFloat = parseFloat(this.top)
      const pinAdjustment = event.shiftKey ? SHIFT_ADJUST_KEYBOARD_PIN_POSITION : DEFAULT_ADJUST_KEYBOARD_PIN_POSITION
      let top = this.top

      if (event.key == 'ArrowUp') {
        top = stringAsFloat - pinAdjustment
        if (top < MIN_POSITION) return
      } else if (event.key == 'ArrowDown') {
        top = stringAsFloat + pinAdjustment
        if (top > MAX_POSITION) return
      }

      this.top = top
    },
    movePinHorizontal(event) {
      const stringAsFloat = parseFloat(this.left)
      const pinAdjustment = event.shiftKey ? SHIFT_ADJUST_KEYBOARD_PIN_POSITION : DEFAULT_ADJUST_KEYBOARD_PIN_POSITION
      let left = this.left

      if (event.key == 'ArrowRight') {
        left = stringAsFloat + pinAdjustment
        if (left > MAX_POSITION) return
      } else if (event.key == 'ArrowLeft') {
        left = stringAsFloat - pinAdjustment
        if (left < MIN_POSITION) return
      }

      this.left = left
    },
  },
}

export default DraggablePin
