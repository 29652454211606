import $ from 'jquery'

window.jQuery = $
window.$ = $

$.ajaxSetup({dataType: 'json'})
$.fn.fadeThenSlideUp = function(fadeDuration = 500, slideDuration = 300) {
  return $(this).fadeTo(fadeDuration, 0.0, function() {
    $(this).slideUp(slideDuration)
  })
}

export default $
