import $ from 'jquery'
import _ from 'underscore'
import {initVue} from '~/vue'

import ModalDialog from '../components/shared/modal_dialog'

import AddLocationModal from '../services/add_location_modal'
import AlertMessage from '../services/alert_message'
import Analytics from '../services/analytics'
import MaskedField from '../services/masked_field'
import PickerFacade from '../services/picker_facade'

import dynamicField from '../helpers/dynamic_field'
import initElement from '../helpers/init_element'

(() => {
  if (typeof math !== 'undefined') {
    math.createUnit('inAq', '248.84 Pa')
  }

  // Provides a way to use Vue Components on pages that are not currently Vue
  // applications. To use, apply the class to the parent container for the page.
  initVue('.vue--shared', {components: {ModalDialog}})

  new Analytics($(document))

  dynamicField('[data-bs-toggle="tooltip"]', (el) => $(el).tooltip({container: 'body'}))
  dynamicField('.js--alertMessage', (el) => new AlertMessage(el))
  dynamicField('.js--mask', (el) => new MaskedField(el))
  dynamicField('.js--date-picker', (el) => new PickerFacade(el, {format: momentDateFormat}))
  dynamicField('.js--datetime-picker', (el) => new PickerFacade(el, {time: true, format: momentDateTimeFormat}))
  dynamicField('.js--addLocation', (el) => new AddLocationModal(el))
  dynamicField('.js--chosen', (el) => $(el).chosen({allow_single_deselect: true, width: '100%'}))
  dynamicField('.js--chosen-ajax', (el) => {
    const $el = $(el)

    $el.ajaxChosen({
      type: 'GET',
      url: $el.data('path'),
      dataType: 'json',
    },
    (data) => {
      return _.map(data, (val) => {
        return {value: val, text: val}
      })
    })
  })

  initElement('.sidebar', function($el) {
    const $activeLink = $($el).find('.nav-item.active')
    const $submenu = $activeLink.closest('.collapse')

    $submenu.addClass('show')
    $submenu.siblings('a.nav-link').removeClass('collapsed').prop('aria-expanded', true)
  })

  initElement('#announcement', function($el) {
    const announcementId = $el.dataset.id
    const announcements = JSON.parse(sessionStorage.getItem('announcements') || '{}')
    if (announcements[announcementId]) {
      return
    }

    $el.classList.remove('hide')
    $el.addEventListener('closed.bs.alert', function() {
      announcements[announcementId] = true
      sessionStorage.setItem('announcements', JSON.stringify(announcements))
    })
  })
})()
