import _ from 'underscore'
import moment from '~/moment'

export default class TimeSlot {
  // Public: Construct a new TimeSlot, which represents a (potentially) multi-day
  // span over which a given start and end time should be considered valid.
  //
  // attrs - An Object with the following attributes:
  //         startTime - A String 24-hour time in the format "HH:mm".
  //         endTime   - A String 24-hour time in the format "HH:mm".
  //         days      - An Array<Integer> containing any set of numbers 0-6.
  constructor(attrs) {
    this.serialize = this.serialize.bind(this)
    this.startsAt = this.startsAt.bind(this)
    this.endsAt = this.endsAt.bind(this)

    this.id = _.uniqueId()
    this.meridianFormat = momentDateTimeFormat.includes('a')
    this.timeFormat = this.meridianFormat ? 'hh:mmA' : 'HH:mm'

    if (attrs) {
      this._destroy = attrs._destroy || false
      this.days = attrs.days
      this.start = this._decomposeTime(attrs.startTime)
      this.end = this._decomposeTime(attrs.endTime)
      this.error = attrs.error
    } else {
      this.days = []
      this.start = this._decomposeTime('00:00')
      this.end = this._decomposeTime('23:59')
      this.error = null
    }
  }

  // Public: Get an Array of server-compatible objects representing all of the
  // single-day time slots necessary to compose a multi-day schedule for the
  // given start and end times.
  //
  // Returns an Array<Object>.
  serialize() {
    const startTime = this._recomposeTime(this.start)
    const endTime = this._recomposeTime(this.end)

    return _.map(_.uniq(this.days).sort(), (day) => ({start_time: startTime, end_time: endTime, day, _destroy: this._destroy}))
  }

  // Public: Get a String representation of the time slot's starting time.
  //
  // Returns a String.
  startsAt() {
    const startTime = this._recomposeTime(this.start)
    return moment(startTime, 'HH:mm').format(this.timeFormat)
  }

  // Public: Get a String representation of the time slot's ending time.
  //
  // Returns a String.
  endsAt() {
    const endTime = this._recomposeTime(this.end)
    return moment(endTime, 'HH:mm').format(this.timeFormat)
  }

  // Internal: Take the passed time string, and convert it to an hour, minute,
  // and/or suffix, based on the user's configured time format.
  //
  // timeString - A String containing a time in 24-hour format.
  //
  // Returns an Array<Integer, Integer, String>.
  _decomposeTime(timeString) {
    let suffix
    if (timeString == null) {
      return [null, null, null]
    }

    const time = moment(timeString, 'HH:mm')

    const hour = parseInt(time.format(this.meridianFormat ? 'h' : 'H'))
    const minute = parseInt(time.format('mm'))
    if (this.meridianFormat) {
      suffix = time.format('A')
    }

    return {hour, minute, suffix}
  }

  // Internal: Convert the passed hour, minute, and suffix for a time in 12-hour
  // format to a 24-hour format time string.
  //
  // hour   - An Integer hour between 1-12.
  // minute - An Integer minute between 0-59.
  // suffix - A String, either "AM" or "PM".
  //
  // Returns a String.
  _recomposeTime({hour, minute, suffix}) {
    return moment(`${hour}:${minute}${suffix}`, this.timeFormat).format('HH:mm')
  }

  visible() {
    return !this._destroy
  }
}
