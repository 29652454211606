import {initVue} from '~/vue'
import initTabs from '../../helpers/tabs'

import FlashAlert from '../../components/alerts/flash_alert'
import ModalDialog from '../../components/shared/modal_dialog'

(() => {
  if (!document.getElementById('vue--devices-edit')) {
    return
  }

  initVue('#vue--devices-edit', {components: {FlashAlert, ModalDialog}})
  initTabs('#device-settings')

  const $input = $('#device_user_cal_date')
  if ($input.length) {
    $input.datepicker()
  }

  // Show/Hide the update button depending on auto-update preferences
  const $updateButton = $('.js--firmwareUpdate')
  $('form#settings-form').on('click', 'input[name=\'firmware_updates\']', function(e) {
    const $input = $(e.target)
    const show = ('update_manually' === $input.attr('value')) || ($input.data('firmware-update') === false)
    $updateButton.toggle(show)
  })
})()
