import $ from 'jquery'
import _ from 'underscore'
import {initVue} from '~/vue'

import Multiselect from 'vue-multiselect'

import ContactForm from '../../components/contacts/contact_form'
import ErrorMessage from '../../components/shared/error_message'
import LocaleSelect from '../../components/shared/locale_select'
import LocationPermissionsTable from '../../components/contacts/location_permissions_table'
import ModalDialog from '../../components/shared/modal_dialog'
import NestedSelect from '../../components/shared/nested_select'
import PinNumber from '../../components/contacts/pin_number'
import SimpleSelect from '../../components/shared/simple_select'

import Contact from '../../services/wrappers/contact'
import ContactEndpoint from '../../services/wrappers/contact_endpoint'
import LocationTree from '../../services/wrappers/location_tree'
import User from '../../services/wrappers/user'

initVue('#vue--contacts-form', {
  components: {
    ContactForm,
    ErrorMessage,
    LocaleSelect,
    LocationPermissionsTable,
    ModalDialog,
    Multiselect,
    NestedSelect,
    PinNumber,
    SimpleSelect,
  },
  data() {
    return {
      locationOptions: gon.locations,
      locationTree: new LocationTree(gon.locations),
      notice: null,
      contact: new Contact(gon.contact),
      showReportsWarning: false,
      reportHandler: '',
      assignedUser: $('#new_assigned_user').val(),
      userRoles: gon.userRoles,
    }
  },
  computed: {
    user() {
      return this.contact.hasUser ? this.contact.user : null
    },
  },
  methods: {
    submit(event) {
      event.target.disabled = true
      const options = {
        url: this.contact.url,
        method: this.contact.id ? 'put' : 'post',
        data: JSON.stringify({
          contact: this.contact.serialize(),
          permit: this.locationTree.serialize(),
        }),
        contentType: 'application/json',
      }

      $.ajax(options)
          .done(function(data) {
            if (data.redirect) {
              window.location = data.redirect
            } else {
              event.target.disabled = false
              this.contact = new Contact(data.contact)
            }
            this.notice = data.notice
            if (this.notice) {
              $('html, body').animate({scrollTop: 0}, 500)
            }
          }.bind(this))
          .fail(function(xhr) {
            event.target.disabled = false
            this.contact = new Contact(xhr.responseJSON.contact)

            // Scroll to the topmost error in event of failure
            setTimeout(function() {
              if ($('.is-invalid').length > 0) {
                $('html, body').animate({scrollTop: $('.is-invalid').offset().top}, 500)
              }
            }, 100)
          }.bind(this))
    },
    processDeletion(event) {
      if (this.contact.user && _.any(this.contact.user.reports)) {
        this.showReportsWarning = true
        $('#js--show-reports-modal').modal('show')
      } else {
        $('#js--delete-contact-modal').modal('show')
      }
    },
    deleteContact(event) {
      const options = {
        url: this.contact.url,
        method: 'delete',
        contentType: 'application/json',
        data: JSON.stringify({
          reporting: {
            handler: this.reportHandler,
            assigned_user_id: this.assignedUser,
          },
        }),
      }

      $.ajax(options).done(function(data) {
        window.location = data.redirect
      })
    },
    onPinNumberUpdate: _.debounce(function(newPin) {
      $.ajax({
        url: `${this.contact.url}/pin_numbers/${newPin}`,
        method: 'GET',
        contentType: 'application/json',
        success: ({pin}) => {
          this.contact.user.pinNumber = pin
        },
      })
    }, 1000),
  },
  watch: {
    'contact.hasUser'(newValue, _oldValue) {
      if (newValue) {
        if (!this.contact.user) {
          this.contact.user = new User()
        }

        const primaryEndpoint = _.detect(this.contact.endpoints, function(endpoint) {
          return endpoint.isPrimaryEmail()
        })

        if (primaryEndpoint) {
          primaryEndpoint.primary = true
          primaryEndpoint._destroy = false
        } else {
          this.contact.endpoints.unshift(new ContactEndpoint({
            label: 'primary',
            type: 'EmailContactEndpoint',
            primary: true,
          }))
        }
      } else {
        this.contact.endpoints.forEach(function(endpoint) {
          endpoint.primary = false
        })
      }

      this.contact.user._destroy = !newValue
    },
  },
})

function syncMailingListCheckboxes(event) {
  const $elem = $(event.target)
  if (!($elem.length > 0)) {
    return
  }

  const $children = $elem.parents('li').first().find('input[type=checkbox].js--child')
  const isChecked = $elem.prop('checked')

  if (isChecked) {
    $children.prop('disabled', false)
  } else {
    $children.prop('checked', false)
    $children.prop('disabled', true)
  }
}

(() => {
  if ($('.js--mailingList').length < 1) {
    return
  }

  const $mailingListParentCheckboxes = $('ul.js--mailingList input[type=checkbox].js--parent')
  $mailingListParentCheckboxes.on('change', syncMailingListCheckboxes)
  syncMailingListCheckboxes({target: $mailingListParentCheckboxes})
})()
