import $ from 'jquery'
import _ from 'underscore'

import {initVue} from '~/vue'

import EscalationPolicyForm from '../../components/escalation_policies/escalation_policy_form'
import EscalationPolicyRow from '../../components/escalation_policies/escalation_policy_row'
import ModalDialog from '../../components/shared/modal_dialog'

import EscalationPolicy from '../../services/wrappers/escalation_policy'
import LocationTree from '../../services/wrappers/location_tree'

initVue('#vue--escalation_policies-index', {
  components: {EscalationPolicyForm, EscalationPolicyRow, ModalDialog},
  data() {
    return {
      locationTree: new LocationTree(gon.locations),
      newPolicy: new EscalationPolicy(),
      selectedLocationId: '',
      policyForNotificationWarning: null,
      showNewForm: false,
      policies: EscalationPolicy.wrapWithDefault(gon.escalationPolicies),
      formStates: gon.escalationPolicies.reduce(function(acc, policy) {
        acc[policy.id] = false
        return acc
      }, {}),
    }
  },
  computed: {
    selectedLocation() {
      return _.detect(this.locationTree.locations, (location) => location.id == this.selectedLocationId)
    },
    locationOptions() {
      return this.locationTree.editableLocations()
    },
    filteredPolicies() {
      return this.policies.filter(function(policy) {
        return this.locationFilterIds.indexOf(policy.locationId) > -1
      }, this)
    },
    locationFilterIds() {
      if (this.selectedLocation) {
        return _.pluck(this.locationTree.tree(this.selectedLocation), 'id')
      } else {
        return _.pluck(this.locationTree.locations, 'id')
      }
    },
    rootLocations() {
      return this.locationTree.rootLocations()
    },
  },
  methods: {
    onCreateNewPolicy() {
      this.newPolicy = new EscalationPolicy({
        locationId: this.selectedLocationId || this.rootLocations[0].id,
      })
      this.showNewForm = true
    },
    optionFor(location) {
      let padding = ''
      for (let i = 0; i < location.depth; i++) {
        padding += '&nbsp;&nbsp;'
      }

      return padding + _.escape(location.name)
    },
    submitOrWarn(policy, callback) {
      if (policy.id) {
        (callback || Function)()
        $('#js--escalation-policy-warning-edit').modal('show')
        this.policyForNotificationWarning = policy
      } else {
        this.submitPolicy(policy, callback)
      }
    },
    submitPolicy(policy, callback) {
      this.policyForNotificationWarning = null
      $('#js--escalation-policy-warning-edit').modal('hide')

      const options = {
        url: policy.url,
        method: policy.id ? 'patch' : 'post',
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
        data: JSON.stringify({
          escalation_policy: policy.serialize(),
        }),
        contentType: 'application/json',
      }

      $.ajax(options)
          .done(function(data, _, xhr) {
            if (xhr.status === 201) {
              this.showNewForm = false
              this.policies.push(new EscalationPolicy(data.escalationPolicy))
            } else {
              this.replacePolicy(policy, data.escalationPolicy)
            }
            this.toggleForm(data.escalationPolicy.id, false)
          }.bind(this))
          .fail(function(xhr) {
            if (policy.id) {
              this.replacePolicy(policy, xhr.responseJSON.escalationPolicy)
            } else {
              this.newPolicy = new EscalationPolicy(xhr.responseJSON.escalationPolicy)
            }
          }.bind(this))
          .always(function(xhr) {
            (callback || Function)()
          })
    },
    destroyPolicy(policy) {
      $.ajax({url: policy.url, method: 'delete'})
          .done(function() {
            this.policies.splice(this.policies.indexOf(policy), 1)
          }.bind(this))
    },
    replacePolicy(policy, data) {
      const index = this.policies.indexOf(policy)
      this.policies[index] = new EscalationPolicy(data)
    },
    toggleForm(policyId, override) {
      if (override !== undefined) {
        this.formStates[policyId] = override
      } else {
        this.formStates[policyId] = !this.formStates[policyId]
      }
    },
  },
})
