/**
The `alert-status` is to be used in conjunction with the `widget-card` component.

@name alert-status

@template components/dashboards/widgets/alert_status
*/

const AlertStatus = {
  name: 'alert-status',
  template: '#alert-status-template',
  props: {
    excursions: {
      type: Number,
      default: 0,
    },
    warnings: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    classBindings() {
      const alertClass = this.alertType == 'check' ? 'safe' : this.alertType
      return alertClass
    },
    alertType() {
      if (this.excursions > 0) {
        return 'error'
      } else if (this.warnings > 0) {
        return 'warning'
      } else {
        return 'check'
      }
    },
  },
}

export default AlertStatus
