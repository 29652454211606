import _ from 'underscore'

import ModalDialog from '../shared/modal_dialog'
import Clipboard from '../../helpers/clipboard'

/**
The `dashboard-share-modal` component defines a modal for a custom dashboard
that allows the modification of its publish/share settings.

@name dashboard-share-modal
@prop {object} dashboard
  The Dashboard object under management.

@template components/dashboards/share_modal
@design https://zpl.io/RMDD7zv

@example
  {
    "dashboard": {
      "path": "/dashboards/1",
      "share_url": "http://www.example.com/dashboards/my-dashboard",
      "ip_addresses": [{"address": "192.168.1.1"}],
      "privacy": "public"
    }
  }
*/

const DashboardShareModal = {
  template: '#dashboard-share-modal-template',
  components: {ModalDialog},
  props: {
    dashboard: {
      type: Object,
      required: true,
    },
  },
  methods: {
    copyShareUrl() {
      Clipboard.copy(this.dashboard.share_url)
    },
    addIpAddress() {
      this.dashboard.ip_addresses.push({address: ''})
    },
    removeIpAddress(index) {
      this.dashboard.ip_addresses.splice(index, 1)
    },
    updateShareSettings: _.debounce(function() {
      if (!this.dashboard.path) { return }

      const addresses = _.reject(this.dashboard.ip_addresses, (ip) => !ip.address)

      $.ajax({
        url: this.dashboard.path,
        method: 'put',
        data: JSON.stringify({
          dashboard: {
            privacy: this.dashboard.privacy,
            ip_addresses: addresses,
          },
        }),
        contentType: 'application/json',
      })
    }, 300),
  },
  watch: {
    'dashboard.privacy': {
      handler() {
        this.updateShareSettings()
      },
    },
    'dashboard.ip_addresses': {
      handler() {
        this.updateShareSettings()
      },
      deep: true,
    },
  },
}

export default DashboardShareModal
