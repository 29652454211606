import _ from 'underscore'
import moment from '~/moment'

import PillLink from '../../shared/pill_link'

/**
The `widget-card` is the outer card template with header information.
It has a slot for channel or alarm content, intended to be used with `%ul.widget-content`.

@name widget-card
@prop {object} monitoringPoint
  Information about the monitoring point.
@prop {boolean} [viewonly=`false`]
  Whether or not the widget's linking behavior should be removed.
@prop {boolean} [showMenu=`false`]
  Whether or not the widget's context menu should be shown.

@template components/dashboards/widgets/widget_card
@design https://zpl.io/6N66mDR

@example
  {
    "monitoringPoint": {
      "type": "channel",
      "id": 1,
      "name": "Temperature",
      "description": "Temperature",
      "device": {
        "id": 421734801711422796,
        "name": "TP125 - 16256524",
        "path": "/devices/421734801711422796"
      },
      "location": {
        "id": 2,
        "name": "Data at DicksonOne",
        "path": "/locations/2"
      },
      "equipment": [{
        "id": 3,
        "name": "Test For Cold Room",
        "path": "/equipment/3"
      }],
      "timestamp": 1500928336
    }
  }
@example
  {
    "monitoringPoint": {
      "type": "location",
      "id": 2,
      "name": "Choco Demo Corp",
      "description": "Location (4 Channels)",
      "path": "/locations/2",
      "alerts": [],
      "timestamp": 1500928336
    }
  }
@example
{
  "viewonly": true,
  "monitoringPoint": {
    "type": "location",
    "id": 2,
    "name": "Choco Demo Corp",
    "description": "Location (4 Channels)",
    "path": "/locations/2",
    "timestamp": 1500928336
  }
}
@example
  {
    "monitoringPoint": {
      "type": "equipment",
      "id": 3,
      "name": "Test For Cold Room",
      "path": "/equipment/3",
      "description": "Cold Room",
      "widget_type": "alerts",
      "channels": [],
      "total_excursions": 0,
      "total_warnings": 0,
      "timestamp": 1500928336
    }
  }
@example
  {
    "monitoringPoint": {
      "type": "data_logger",
      "id": 421734801711422796,
      "name": "3 - DWE",
      "path": "/devices/421734801711422796",
      "description": "421734801711422796",
      "widget_type": "channels",
      "total_excursions": 0,
      "total_warnings": 0,
      "channels": [],
      "timestamp": 1500928336
    }
  }
@example
  {
    "monitoringPoint": {
      "type": "floorplan",
      "id": 1,
      "name": "3 - DWE",
      "path": "/floorplans/1",
      "widget_type": "floorplan",
      "total_excursions": 0,
      "total_warnings": 0,
      "channels": [],
      "timestamp": 1500928336
    }
  }
*/


function timeDistance(timestamp) {
  return timestamp ? moment(timestamp).locale(window.locale).fromNow() : '-'
}

const WidgetCard = {
  name: 'widget-card',
  template: '#widget-card-template',
  components: {PillLink},
  props: {
    monitoringPoint: {
      type: Object,
      required: true,
    },
    viewonly: {
      type: Boolean,
      default: false,
    },
    showMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: this.monitoringPoint.name,
      path: this.monitoringPoint.path,
      description: this.monitoringPoint.description,
      timestamp: timeDistance(this.monitoringPoint.timestamp),
      type: this.monitoringPoint.type,
    }
  },
  mounted() {
    setInterval(this.refreshTimestamp, 1000)
  },
  methods: {
    refreshTimestamp() {
      this.timestamp = timeDistance(this.monitoringPoint.timestamp)
    },
  },
  computed: {
    pills() {
      if (this.type !== 'channel') {
        return []
      }

      const pills = [
        this.monitoringPoint.device,
        this.monitoringPoint.location,
        this.monitoringPoint.equipment?.[0],
      ]

      return _.compact(pills)
    },
  },
}

export default WidgetCard
