import ActiveAlerts from './widgets/active_alerts'
import AlertStatus from './widgets/alert_status'
import ChannelReading from './widgets/channel_reading'
import WidgetCard from './widgets/widget_card'

import FloorplanArea from '../floorplans/floorplan_area'

const REFRESH_INTERVAL = 30_000

/**
 * The `dashboard-widget` component is a wrapper for any dashboard widget type,
 * responsible for rendering the correct content for the selected type/style and
 * monitoring points.
 *
 * @name dashboard-widget
 * @prop {object} widget A widget object.
 * @prop {boolean} [autoUpdate=`true`] Whether or not the widget should auto-update.
 * @prop {boolean} [viewonly=`false`] Whether or not the widget should be rendered in a readonly state.
 *
 * @template components/dashboards/dashboard_widget
 * @design https://zpl.io/g8XXpkZ
 *
 * @example
 * {
 *   "widget": {
 *     "id": 3,
 *     "dashboard_id": 1,
 *     "monitoring_point": {
 *       "id": 2,
 *       "key": "location-2",
 *       "name": "Demo Chocolate Corp",
 *       "description": "Demo Chocolate Corp (1 Channel)",
 *       "path": "/locations/2",
 *       "excursions": 1,
 *       "timestamp": "2023-04-13T21:33:45Z",
 *       "warnings": 0
 *     },
 *     "monitoring_point_type": "Location",
 *     "path": "/dashboards/1/widgets/3",
 *     "style": null,
 *     "x": 1,
 *     "y": 0,
 *     "timeFrame": 3600
 *   },
 *   "autoUpdate": false,
 *   "viewonly": true
 * }
 *
 */
const DashboardWidget = {
  template: '#dashboard-widget-template',
  components: {ActiveAlerts, AlertStatus, ChannelReading, FloorplanArea, WidgetCard},
  props: {
    widget: {
      type: Object,
      required: true,
    },
    autoUpdate: {
      type: Boolean,
      default: true,
    },
    viewonly: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (!this.autoUpdate) {
      return
    }

    this.updateInterval = setInterval(() => {
      this.refresh()
    }, REFRESH_INTERVAL)
  },
  beforeDestroy() {
    clearInterval(this.updateInterval)
  },
  methods: {
    refresh() {
      $.ajax({
        url: this.widget.path,
        success: (response) => this.onRefresh(response),
      })
    },
    onRefresh(widget) {
      this.widget.monitoringPoint = widget.monitoring_point
    },
    deleteWidget() {
      $.ajax({
        url: this.widget.path,
        method: 'DELETE',
        success: () => this.$emit('deleted', this.widget),
      })
    },
  },
}

export default DashboardWidget
