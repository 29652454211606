const EmailNotificationRow = {
  name: 'email-notification-row',
  template: '#email-notification-row-template',
  props: ['notification'],
}

const SmsNotificationRow = {
  name: 'sms-notification-row',
  template: '#sms-notification-row-template',
  props: ['notification'],
}

const VoiceNotificationRow = {
  name: 'voice-notification-row',
  template: '#voice-notification-row-template',
  props: ['notification'],
}

const HardwareNotificationRow = {
  name: 'hardware-notification-row',
  template: '#hardware-notification-row-template',
  props: ['notification'],
}

const RelayNotificationRow = {
  name: 'relay-notification-row',
  template: '#relay-notification-row-template',
  props: ['notification'],
  computed: {
    relayName() {
      if (!this.notification.relayConfiguration) {
        return ''
      }
      return this.notification.relayConfiguration.name
    },
  },
}

export default {
  name: 'notification-row',
  template: '#notification-row-template',
  components: {
    EmailNotificationRow,
    SmsNotificationRow,
    VoiceNotificationRow,
    HardwareNotificationRow,
    RelayNotificationRow,
  },
  props: ['notification'],
  computed: {
    componentName() {
      switch (this.notification.type) {
        case 'EmailAlarmNotification':
          return 'email-notification-row'
        case 'SmsAlarmNotification':
          return 'sms-notification-row'
        case 'VoiceAlarmNotification':
          return 'voice-notification-row'
        case 'HardwareAlarmNotification':
          return 'hardware-notification-row'
        case 'RelayNotification':
          return 'relay-notification-row'
      }
    },
  },
}
