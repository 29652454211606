import $ from 'jquery'

class AjaxModal {
  constructor(options) {
    this.registerListeners = this.registerListeners.bind(this)
    this.triggerModal = this.triggerModal.bind(this)
    this.prepareForm = this.prepareForm.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onError = this.onError.bind(this)
    this.reload = this.reload.bind(this)

    if (!options) {
      options = {}
    }
    this.options = options

    const defaultOptions = {
      linkSelector: null,
      loadingText: 'Loading...',
      modalId: 'dickson-modal',
    }
    this.options = $.extend({}, defaultOptions, this.options)
    this.$modal = this.findOrCreateModal(this.options.modalId)

    if (this.options.linkSelector) {
      this.registerListeners()
    }
  }

  registerListeners() {
    $(document.body).on('click', this.options.linkSelector, (e) => {
      const $target = $(e.currentTarget)
      e.preventDefault()

      if (!$target.attr('disabled')) {
        this.triggerModal($target.attr('href'))
      }
    })
  }

  triggerModal(href) {
    this.$modal.data('href', href)
    this.$modal.data('modalObject', this)
    this.reload()
  }

  prepareForm() {
    const $form = this.$modal.find('form')
    $form.data('type', 'html')
    $form.data('remote', true)

    $form.on('submit', () => $form.find('input[type=submit]').prop('disabled', true))
    $form.on('ajax:error', this.onError)
    $form.on('ajax:success', (evt, data, status, xhr) => this.onSuccess(data))

    this.$modal.trigger('modal:loaded')

    const success = this.options['success']
    if (success) {
      success(this)
    }
  }

  onSuccess(body) {
    if (this.options['formSuccess']) {
      this.options['formSuccess'](this, body)
    } else {
      window.location.reload()
    }
  }

  onError(evt, xhr) {
    this.$modal.html(xhr.responseText)
    this.prepareForm()
  }

  findOrCreateModal(modalId) {
    let $modal = $(`#${modalId}`)
    if ($modal.length > 0) {
      return $modal
    }

    $modal = $(`<div class='modal fade'></div>`).attr('id', modalId)
    $modal.html(this._template())

    return $modal
  }

  closeModal() {
    this.$modal.modal('hide')
  }

  reload() {
    const $modal = this.$modal
    const self = this

    $modal.html(this._template())
    $.ajax({
      url: $modal.data('href'),
      dataType: 'html',
      success(body, textStatus, jqXHR) {
        $modal.html(body)
        self.prepareForm()

        $modal.modal('show')
      },
    })
  }

  _template() {
    return `<div class='modal-dialog'><div class='modal-body'><h3>${this.options.loadingText}</h3></div></div>`
  }
}

export default AjaxModal
