import _ from 'underscore'

import {GridLayout, GridItem} from 'vue-grid-layout'
import DashboardWidget from './dashboard_widget'

const ROW_HEIGHT = 10
const ROW_MARGIN = 10

/**
 * The `dashboard-grid` component is used to render a grid of widgets.
 *
 * @name dashboard-grid
 * @prop {array} widgets An array of widget objects.
 * @prop {boolean} [autoUpdate=`true`] Whether or not the widgets should auto update.
 * @prop {boolean} [editable=`false`] Whether or not the widgets should be editable.
 * @prop {number} timeFrame
 *   An integer number of seconds reflecting the most recent data that should be
 *   populated for the widgets in the grid.
 * @prop {number} totalColumns The total number of columns in the grid.
 *
 * @template components/dashboards/dashboard_grid
 * @design https://zpl.io/g8XXpkZ
 *
 * @example
 * {
 *   "widgets": [
 *     {
 *       "id": 3,
 *       "dashboard_id": 1,
 *       "monitoring_point": {
 *         "id": 2,
 *         "key": "location-2",
 *         "name": "Demo Chocolate Corp",
 *         "description": "Demo Chocolate Corp (1 Channel)",
 *         "path": "/locations/2",
 *         "excursions": 1,
 *         "timestamp": "2023-04-13T21:33:45Z",
 *         "warnings": 0
 *       },
 *       "monitoring_point_type": "Location",
 *       "path": "/dashboards/1/widgets/3",
 *       "style": null,
 *       "x": 1,
 *       "y": 0
 *     }
 *   ],
 *   "autoUpdate": false,
 *   "editable": true,
 *   "totalColumns": 3,
 *   "timeFrame": 3600
 * }
 *
 */
const DashboardGrid = {
  template: '#dashboard-grid-template',
  components: {DashboardWidget, GridItem, GridLayout},
  props: {
    widgets: {
      type: Array,
      required: true,
    },
    autoUpdate: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    timeFrame: {
      type: Number,
      required: true,
    },
    totalColumns: {
      type: Number,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
    orientation: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      layout: this.initialLayout(),
      rowHeight: ROW_HEIGHT,
      rowMargin: [ROW_MARGIN, ROW_MARGIN],
      margin: [0, 0],
      watchers: [],
    }
  },
  computed: {
    gridDisplayDimensions() {
      return `dashboard-is-${this.size}-${this.orientation}`
    },
    gridLineBackground() {
      return `calc(calc(100% - 5px) / ${this.totalColumns})`
    },
  },
  methods: {
    initialLayout() {
      return this.widgets.map((widget, i) => {
        return {
          elId: `widget-${i}`,
          i,
          id: widget.id,
          monitoringPoint: widget.monitoring_point,
          monitoringPointType: widget.monitoring_point_type,
          timeFrame: this.timeFrame,
          x: widget.x,
          y: widget.y,
          w: widget.width,
          h: widget.height,
          path: widget.path,
          static: false,
          style: widget.style,
        }
      })
    },
    onWidgetDeleted(widget) {
      this.layout = _.reject(this.layout, {id: widget.id})
      _.findWhere(this.watchers, {id: widget.id}).unwatch()
    },
    watchWidgetPositions() {
      if (!this.editable) {
        return
      }

      this.watchers = this.layout.map((widget, index) => {
        return {
          id: widget.id,
          unwatch: this.$watch(() => this.layout[index], _.debounce(({path, x, y, w, h}) => {
            $.ajax({
              url: path,
              type: 'PATCH',
              data: {dashboard_widget: {x, y, width: w, height: h}},
            })
          }, 1000), {deep: true}),
        }
      })
    },
  },
}

export default DashboardGrid
