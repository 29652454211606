import ErrorMessage from '../shared/error_message'

const EmailEndpointInput = {
  name: 'email-endpoint-input',
  template: '#email-endpoint-input-template',
  components: {ErrorMessage},
  props: {
    contact: {
      type: Object,
      required: true,
    },
    endpoint: {
      type: Object,
      required: true,
    },
  },
}

const PhoneEndpointInput = {
  name: 'phone-endpoint-input',
  template: '#phone-endpoint-input-template',
  components: {ErrorMessage},
  props: {
    contact: {
      type: Object,
      required: true,
    },
    endpoint: {
      type: Object,
      required: true,
    },
  },
}

export default {
  name: 'contact-endpoint-form',
  template: '#contact-endpoint-form-template',
  components: {EmailEndpointInput, ErrorMessage, PhoneEndpointInput},
  props: {
    contact: {
      type: Object,
      required: true,
    },
    endpoint: {
      type: Object,
      required: true,
    },
  },
  computed: {
    endpointComponent() {
      if (!this.endpoint.type) {
        return
      }
      switch (this.endpoint.type) {
        case 'EmailContactEndpoint': return 'email-endpoint-input'
        case 'PhoneContactEndpoint': return 'phone-endpoint-input'
      }
    },
  },
  methods: {
    destroy() {
      this.$emit('destroy', this.endpoint)
    },
  },
}
