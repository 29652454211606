import _ from 'underscore'
import Draggable from 'vuedraggable'

import {initVue} from '~/vue'

import UserRole from '../../services/user_roles/user_role'
import UserRoleListItem from '../../components/user_roles/user_role_list_item'

initVue('#vue--user_roles-index', {
  components: {Draggable, UserRoleListItem},
  data() {
    const roles = _.partition(UserRole.wrap(gon.userRoles), 'enabled')

    return {
      disabledRoles: roles[1],
      enabledRoles: roles[0],
      newRole: new UserRole(),
    }
  },
  computed: {
    newRoleValid() {
      return this.newRole.validate()
    },
  },
  methods: {
    onChange(event) {
      if (event.added) {
        event.added.element.enable()
      } else if (event.removed) {
        event.removed.element.disable()
      }
    },
    submitRole(role) {
      const options = {
        method: role.id ? 'put' : 'post',
        data: {
          user_role: role.serialize(),
        },
        url: role.path,
      }
      $.ajax(options)
          .done((data, _, xhr) => {
            if (xhr.status === 201) {
              this.enabledRoles.push(new UserRole(data.userRole))
              this.newRole = new UserRole
            }
          })
          .fail((xhr) => {
            new FlashMessage('There was an error saving the user role')
          })
    },
    destroyRole(role) {
      $.ajax({url: role.path, method: 'delete', dataType: 'text'}).done(() => this.removeRole(role))
    },
    removeRole(role) {
      const disabledIndex = this.disabledRoles.indexOf(role)
      const enabledIndex = this.enabledRoles.indexOf(role)

      if (disabledIndex > -1) {
        this.disabledRoles.splice(disabledIndex, 1)
      } else if (enabledIndex > -1) {
        this.enabledRoles.splice(enabledIndex, 1)
      }
    },
  },
})
