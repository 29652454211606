import selectMixin from '../mixins/select'

// The simple-select component is a wrapper around the multiselect
// component. It allows us to use multiselect components for visual
// consistency, without having to change the way an attribute is stored
// or accessed. While multiselect will assign the whole Object
// representing the user's selection, simple-select will assign only the
// value of the selected option (more like a native select field).
export default {
  name: 'simple-select',
  template: '#simple-select-template',
  mixins: [selectMixin],
}
