import pluralize from '../../helpers/pluralize'

import ModalDialog from '../shared/modal_dialog'
import NotificationRow from '../notifications/notification_row'

export default {
  name: 'escalation-policy-row',
  template: '#escalation-policy-row-template',
  components: {ModalDialog, NotificationRow},
  props: {
    policy: {
      type: Object,
      required: true,
    },
    showForm: {
      type: Boolean,
      required: true,
    },
    locationTree: {
      type: Object,
      required: true,
    },
  },
  computed: {
    location() {
      return this.locationTree.find(this.policy.locationId)
    },
    locationEditable() {
      return this.location && this.location.permission === 2
    },
  },
  methods: {
    toggleForm() {
      this.$emit('toggle-form', this.policy.id)
    },
    submit() {
      this.$emit('submit', this.policy)
    },
    showHardwareAlerts(group) {
      return group.hasHardwareNotification()
    },
    warnOrConfirmDestroy() {
      if (this.policy.alarms.length > 0) {
        this.toggleModal('show')
      } else {
        const deleteConfirm = confirm('Are you sure you want to delete this policy?')
        if (deleteConfirm) {
          this.destroy()
        }
      }
    },
    destroy() {
      this.$emit('destroy', this.policy)
      this.toggleModal('hide')
    },
    toggleModal(display) {
      const escalationPolicyModal = this.$refs[`warning-modal-delete-${this.policy.id}`].$el
      $(escalationPolicyModal).modal(display)
    },
    pluralize,
  },
}
