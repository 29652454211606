import $ from 'jquery'
import LocaleSelect from './locale_select'

const LocaleForm = {
  name: 'locale-form',
  template: '#locale-form-template',
  components: {LocaleSelect},
  methods: {
    submit(value) {
      $.ajax({
        url: '/locales',
        method: 'post',
        data: {
          locale: value,
        },
        success() {
          window.location.reload()
        },
        complete() {},
      })
    },
  },
}

export default LocaleForm
