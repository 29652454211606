import _ from 'underscore'
import moment from '~/moment'

import {DatePicker} from 'v-calendar'

const DateTimePicker = {
  name: 'date-time-picker',
  components: {DatePicker},
  emits: ['update:modelValue'],
  data() {
    return {
      inputId: _.uniqueId('date-time-picker_'),
      is24hr: !momentDateTimeFormat.includes('a'),
      popoverVisible: false,
      masks: {
        input: momentDateTimeFormat,
        modelValue: 'iso',
      },
    }
  },
  props: {
    inputName: String,
    label: String,
    placeholder: String,
    required: Boolean,
    modelValue: String,
    disabled: Boolean,
    minDate: String
  },
  computed: {
    arrow_icon() {
      return this.popoverVisible ? 'arrow_drop_up' : 'arrow_drop_down'
    },
    datetime: {
      get() {
        return this.modelValue ? moment(this.modelValue).toDate() : null
      },
      set(value) {
        this.$emit('update:modelValue', value?.toISOString())
      },
    },
    isoString() {
      return moment(this.modelValue).toISOString()
    },
  },
  methods: {
    setPopoverVisible(visible) {
      this.popoverVisible = visible
    },
  },
  template: `
  <div class="filter-form-group datepicker-form-group">
    <label :for="inputId" v-if="label" class="form-label">{{label}}<abbr title="required" v-if="required">*</abbr></label>
    <date-picker mode="dateTime" :is24hr="is24hr" v-model="datetime" :popover="{visibility: 'focus'}" @popoverDidShow="setPopoverVisible(true)" @popoverDidHide="setPopoverVisible(false)" class="datepicker" :min-date="minDate">
      <template #default="{ inputValue, inputEvents }">
        <input type="hidden" :name="inputName" class="form-control" :value="isoString">
        <input :id="inputId" :value="inputValue" :placeholder="placeholder" class="form-control" v-on="inputEvents" :disabled="disabled">
      </template>
    </date-picker>
  </div>`,
}

export default DateTimePicker
