import _ from 'underscore'
import Alarm from '../../services/wrappers/alarm'

import AlarmConditionForm from './alarm_condition_form'
import AlertSchedule from '../alert_schedules/alert_schedule'
import ErrorMessage from '../shared/error_message'
import EscalationPolicySelect from '../escalation_policies/escalation_policy_select'
import NotificationPolicyForm from '../escalation_policies/notification_policy_form'

export default {
  name: 'alarm-form',
  template: '#alarm-form-template',
  components: {
    AlarmConditionForm,
    AlertSchedule,
    ErrorMessage,
    EscalationPolicySelect,
    NotificationPolicyForm,
  },
  props: {
    alarm: {
      type: Object,
      required: true,
    },
    device: {
      type: Object,
      required: true,
    },
    policies: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },
  data() {
    const alarm = new Alarm(this.alarm)

    return {
      cachedAlarm: _.clone(alarm),
      showCreatePolicy: _.any(alarm.notificationGroups) || _.isEmpty(this.policies),
    }
  },
  computed: {
    allowReferenceLine() {
      if (!this.alarm.conditionOption()) {
        return false
      }
      return this.alarm.conditionOption().allowReferenceLine
    },
    allowAddEscalationPolicy() {
      return !_.isEmpty(this.alarm.conditionOption())
    },
    allowSave() {
      return this.alarm.allowSaveNotificationGroup()
    },
  },
  methods: {
    triggerSubmit() {
      if (this.checkRelay()) {
        this.$emit('submit', this.alarm, this)
      }
    },
    checkRelay() {
      const message = 'You\'ve chosen to trigger a relay channel already in use. ' +
        'This may make it difficult to determine which alarm case triggered the relay. ' +
        'If this is acceptable please click ok and continue, otherwise, edit the relay channel you\'d like to trigger.'

      const relayInUse = _.any(this.visibleNotifications, function(notif) {
        const relayConfig = this.findRelay(notif.relayConfigurationId) || {}
        if (_.isEmpty(relayConfig.notificationIds)) {
          return false
        } else {
          return _.indexOf(relayConfig.notificationIds, notif.id) >= 0
        }
      }.bind(this))

      return relayInUse ? window.confirm(message) : true
    },
    findRelay(relayId) {
      return gon.device.relayPods.find(function(relay) {
        return relay.id === relayId
      })
    },
    idFor(prefix) {
      return prefix + '-' + (this.alarm.id || 'new')
    },
    triggerClose() {
      Object.assign(this.alarm, this.cachedAlarm)
      this.$emit('close')
    },
    toggleCreatePolicy(value) {
      this.showCreatePolicy = value
    },
  },
}
