/**
The `pill-link` component is useful for displaying tags and assets while
allowing the user to click through to view more detail elsewhere in the
application.

@name pill-link
@prop {string} value
  The text to display within the pill.
@prop {string} path
  The destination URL.
@prop {string} [size='sm']
  The size of the pill to render. Valid options are 'sm', 'md', and 'lg'.

@template components/shared/pill_link
@design https://zpl.io/B1eG6dq
@a11y -
  The pill link uses an anchor tag, and should be used for navigation between
  pages/destinations.

  If the href is equal to `''`, `undefined`, or `null` and an `onClick` event is
  used than an individual using a keyboard or assistive technology (AT) will not
  be able to interact with the component as expected.

  An individual should be able to interact with the component using a keyboard,
  mouse, screen reader or other AT, and the link should have hover and focus
  states.

  When using a keyboard or AT, an individual should be able use the
  <i class='material-icons'>keyboard_return</i> key to interact/navigate.

@example
  {
    "value": "I'm a default small pill!",
    "path": "/dashboard"
  }
@example
  {
    "value": "I'm a medium pill!",
    "path": "/dashboard",
    "size": "md"
  }
*/

const PillLink = {
  name: 'pill-link',
  template: '#pill-link-template',
  props: {
    value: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    size: {
      type: String, // 'sm' | 'md' | 'lg'
      default: 'sm',
    },
  },
}

export default PillLink
