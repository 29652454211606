import {createGrid} from 'ag-grid-community'
import initElement from '../../helpers/init_element'

initElement('#js--rf_gateways-index', () => {
  const gridOptions = {
    rowStyle: {background: 'white'},
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    suppressCellFocus: true,
    suppressColumnVirtualisation: true,
    domLayout: 'autoHeight',
    defaultColDef: {
      resizable: true,
      cellStyle: {
        'white-space': 'normal',
        'border-right': '0.5px solid #BDC3C7',
        'padding': '10px',
      },
      autoHeight: true,
      sortable: true,
      filter: true,
      maxWidth: 250,
    },
    onGridReady(event) {
      event.api.sizeColumnsToFit()
    },
    columnDefs: gon.columns,
    rowData: gon.data,
    localeText: {
      noRowsToShow: 'No gateways to display at this time',
    },
    components: {
      GatewayNameRenderer(params) {
        const name = params.data.gateway_edit_path ?
          `<a href='${params.data.gateway_edit_path}'>${params.value}</a>` :
          params.value
        return `${name}<br /><span class='ag-cell-loggerSerial'>${params.data.serial}</span>`
      },
      FirmwareVersionRenderer(params) {
        if (!params.value) {
          return '-'
        }

        let output = params.value + ''

        if (params.data.latest_firmware && (params.data.latest_firmware !== params.value)) {
          const status = params.data.firmware_status != null ? params.data.firmware_status : 'Available'
          output += `<br /><span class='ag-cell__details'><strong>${params.data.latest_firmware}</strong> ${status}</span>`
        }

        return output
      },
      LocationNameRenderer(params) {
        return `<a href='${params.data.location_path}'>${params.value}</a>`
      },
      TimestampRenderer(params) {
        if (!params.value) {
          return '--'
        }

        return moment(new Date(Date.parse(params.value))).format(momentDateTimeFormat)
      },
    },
  }

  createGrid(document.querySelector('#grid--rf_gateways-index'), gridOptions)
})
