import ErrorMessage from '../shared/error_message'
import SimpleSelect from '../shared/simple_select'

import ContactSelect from '../contacts/contact_select'
import AlarmNotification from '../../services/wrappers/alarm_notification'

const RecipientEmailInputs = {
  name: 'recipient-email-inputs',
  template: '#recipient-email-inputs-template',
  components: {ContactSelect},
  props: ['device', 'notification'],
}

const RecipientPhoneInputs = {
  name: 'recipient-phone-inputs',
  template: '#recipient-phone-inputs-template',
  components: {ContactSelect, ErrorMessage, SimpleSelect},
  props: ['device', 'notification'],
  data() {
    return {
      countryOptions: gon.countryOptions,
    }
  },
}

const RelayConfigurationInputs = {
  name: 'relay-configuration-inputs',
  template: '#relay-configuration-inputs-template',
  components: {ErrorMessage, SimpleSelect},
  props: ['device', 'notification'],
  data() {
    return {
      relayPods: this.device.relayPods,
    }
  },
}

export default {
  name: 'notification-inputs',
  template: '#notification-inputs-template',
  components: {ErrorMessage, RecipientEmailInputs, RecipientPhoneInputs, RelayConfigurationInputs, SimpleSelect},
  props: ['group', 'device', 'notification', 'condition', 'index'],
  computed: {
    number() {
      const index = this.group.visibleNotifications().indexOf(this.notification)
      return (index + 1).toString().padStart(2, '0')
    },
    conjunction() {
      return this.index ? 'And' : 'Then'
    },
    recipientComponent() {
      switch (this.notification.type) {
        case 'EmailAlarmNotification':
          return 'recipient-email-inputs'
        case 'SmsAlarmNotification':
        case 'VoiceAlarmNotification':
          return 'recipient-phone-inputs'
        case 'RelayNotification':
          return 'relay-configuration-inputs'
      }
    },
    notificationOptions() {
      if (!this.condition) {
        return []
      }
      return this.condition.notificationOptions
    },
  },
  methods: {
    resetFields() {
      const newNotification = new AlarmNotification({type: this.notification.type})
      Object.assign(this.notification, newNotification)
    },
  },
}
