const convertToJSDateFormat = (rubyFormat) => rubyFormat.replace(/(?:%Y|%m|%d|%I|%M|%S|\s%Z|%p|%H|'.*?')/g, (match) => {
  switch (match) {
    case '%Y': return 'yy'
    case '%m': return 'mm'
    case '%d': return 'dd'
    case '%I': return 'h'
    case '%M': return 'mm'
    case '%S': return 'ss'
    case ' %Z': return ''
    case '%p': return 'TT'
    case '%H': return 'HH'
    default: return match.replace(/'/g, '')
  }
})

const toMomentJSDateFormat = (pickerFormat) => pickerFormat.replace(/(?:yy|mm|dd)/g, (match) => {
  switch (match) {
    case 'yy': return 'YYYY'
    case 'mm': return 'MM'
    case 'dd': return 'DD'
  }
})

const toMomentJSTimeFormat = (pickerFormat) => pickerFormat.replace(/TT/g, 'a')
const toMomentJSDateTimeFormat = (dateFormat, timeFormat) => toMomentJSDateFormat(dateFormat) + ' ' + toMomentJSTimeFormat(timeFormat)

window.defaultDateFormat = convertToJSDateFormat(window.dateFormat)
window.defaultTimeFormat = convertToJSDateFormat(window.timeFormat)
window.momentDateFormat = toMomentJSDateFormat(defaultDateFormat)
window.momentDateTimeFormat = toMomentJSDateTimeFormat(defaultDateFormat, defaultTimeFormat)
