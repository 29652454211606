import ErrorMessage from '../shared/error_message'
import LocationPermissionRow from './location_permission_row'

export default {
  name: 'location-permissions-table',
  template: '#location-permissions-table-template',
  components: {ErrorMessage, LocationPermissionRow},
  props: {
    user: {
      type: Object,
      required: true,
    },
    locationTree: {
      type: Object,
      required: true,
    },
  },
  computed: {
    locations() {
      return this.locationTree.locations
    },
  },
  methods: {
    changeAllPermissions(permission) {
      this.locations.forEach(function(location) {
        location.permission = permission
      })
    },
  },
}
