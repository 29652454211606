import ContactEndpointForm from './contact_endpoint_form'
import ContactEndpoint from '../../services/wrappers/contact_endpoint'

import deepClone from '../../helpers/deep_clone'

export default {
  name: 'contact-form',
  template: '#contact-form-template',
  components: {ContactEndpointForm},
  props: {
    contact: {
      type: Object,
      required: true,
    },
    userRoles: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    locationOptions: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      cachedContact: deepClone(this.contact),
    }
  },
  computed: {
    visibleEndpoints() {
      return this.contact.endpoints.filter(function(endpoint) {
        return !endpoint._destroy
      })
    },
  },
  methods: {
    addEmailEndpoint() {
      const newEndpoint = new ContactEndpoint({type: 'EmailContactEndpoint'})
      this.contact.endpoints.push(newEndpoint)
    },
    addPhoneEndpoint() {
      const newEndpoint = new ContactEndpoint({type: 'PhoneContactEndpoint'})
      this.contact.endpoints.push(newEndpoint)
    },
    submit(event) {
      if (this.contact.id === undefined || this.contact.id === null) {
        event.target.disabled = true
      }
      Object.assign(this.cachedContact, this.contact)
      this.$emit('submit', this.contact, function() {
        event.target.disabled = false
      })
    },
    close() {
      Object.assign(this.contact, this.cachedContact)
      this.$emit('close')
    },
    destroyEndpoint(endpoint) {
      if (endpoint.id) {
        endpoint._destroy = true
        this.$emit('endpoint-edited')
      } else {
        const index = this.contact.endpoints.indexOf(endpoint)
        this.contact.endpoints.splice(index, 1)
      }
    },
  },
}
