export default class TagsRenderer {
  init(params) {
    this.eGui = document.createElement('div')
    this.eGui.classList.add('tags-cell')

    params.value.forEach((tag) => {
      const pill = document.createElement('div')
      pill.classList.add('tag-pill')
      pill.innerHTML = tag
      this.eGui.appendChild(pill)
    })
  }

  getGui() {
    return this.eGui
  }

  refresh(params) {
    return false
  }
}
