import NumberFormatter from '~/services/number_formatter'

/**
 * The `numeric-input` component is used to capture numeric strings using
 * the user preference for decimal separator. The value is then stored as a
 * number that can be passed to the backend.

@name numeric-input

@prop {Number} modelValue - The numeric value.

@example
  {
    "value": 123.45
  }
*/

const NumericInput = {
  name: 'numeric-input',
  props: {
    modelValue: {
      type: Number,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      formatter: new NumberFormatter(),
    }
  },
  computed: {
    displayValue: {
      get() {
        return this.modelValue ? this.formatter.format(this.modelValue) : ''
      },
      set(value) {
        const newValue = this.formatter.parse(value) || 0
        this.$emit('update:modelValue', newValue)
      },
    },
  },
  template: `
    <input
      type="text"
      class="form-control"
      v-model="displayValue"
    />
  `,
}

export default NumericInput
