import _ from 'underscore'
import Channel from './channel'

export default class Device {
  constructor(attrs) {
    if (!attrs) {
      attrs = {}
    }

    this.id = attrs.id
    this.name = attrs.name
    this.path = attrs.path
    this.locationId = attrs.location_id
    this.channels = _.map(attrs.channels ? attrs.channels : [], (object) => new Channel(object))
    this.serial = attrs.serial
    this.registrationCode = attrs.registration_code

    this.preferences = {
      firmwareUpdates: attrs.preferences?.firmware_updates || '',
      sampleInterval: attrs.preferences?.sample_interval || '',
      temperatureDisplayUnit: attrs.preferences?.temperature_display_unit || '',
      pressureDisplayUnit: attrs.preferences?.pressure_display_unit || '',
      userCalInterval: attrs.preferences?.user_cal_interval || '',
      timeZone: attrs.preferences?.time_zone || '',
    }
  }

  serialize() {
    return {
      device: {
        id: this.id,
        name: this.name,
        location_id: this.locationId,
        channels_attributes: _.map(this.channels, (channel) => channel.serialize()),
        serial: this.serial,
        registration_code: this.registrationCode,
        preference_attributes: {
          firmware_updates: this.preferences.firmwareUpdates,
          sample_interval: this.preferences.sampleInterval,
          temperature_display_unit: this.preferences.temperatureDisplayUnit,
          pressure_display_unit: this.preferences.pressureDisplayUnit,
          time_zone: this.preferences.timeZone,
          user_cal_interval: this.preferences.userCalInterval,
        },
      },
    }
  }
}
