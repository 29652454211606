import BaseAlert from '../alerts/base_alert'
import PillLink from '../shared/pill_link'

import pinIcon from '../../helpers/pin_icon'
import pluralize from '../../helpers/pluralize'

const FLIP_DROPDOWN_POSITION = 60

const AlertPin = {
  name: 'alert-pin',
  template: '#alert-pin-template',
  components: {BaseAlert, PillLink},
  props: ['name', 'type', 'path', 'alerts', 'alertModal', 'position'],
  computed: {
    alertCount() {
      return this.alerts.length
    },
    statusClass() {
      if (this.alerts.length > 0) {
        const hasExcursion = this.alerts[0].excursion_at
        return hasExcursion ? 'excursion' : 'warning'
      } else {
        return 'default'
      }
    },
    statusIcon() {
      return pinIcon(this)
    },
    dropdownAlignment() {
      const leftPositionAsNumber = parseFloat(this.position.left)

      if (leftPositionAsNumber > FLIP_DROPDOWN_POSITION) {
        return 'dropdown-menu-right'
      }
    },
    dropdownExpansionDirection() {
      const topPositionAsNumber = parseFloat(this.position.top)

      if (topPositionAsNumber > FLIP_DROPDOWN_POSITION) {
        return 'dropup'
      } else {
        return 'dropdown'
      }
    },
  },
  methods: {pluralize},
}

export default AlertPin
