import $ from 'jquery'
import initElement from '../../../helpers/init_element'

class AccountForm {
  constructor($form) {
    this._confirmChanges = this._confirmChanges.bind(this)
    this._confirmationMessage = this._confirmationMessage.bind(this)

    this.$form = $form
    this.$twilioOverrideField = this.$form.find('input[type=\'checkbox\'][name=\'account[shared_twilio_override]\']')
    this.twilioOverrideInitialValue = this.$twilioOverrideField ? this.$twilioOverrideField.prop('checked') : undefined
    this.$form.on('submit', this._confirmChanges)
  }

  _confirmChanges() {
    if (this.$twilioOverrideField && (this.twilioOverrideInitialValue !== this.$twilioOverrideField.prop('checked'))) {
      return confirm(this._confirmationMessage())
    }
  }

  _confirmationMessage() {
    if (this.$twilioOverrideField.prop('checked')) {
      return this.$form.data('shared-twilio-enable-confirmation')
    } else {
      return this.$form.data('shared-twilio-disable-confirmation')
    }
  }
}

initElement('#js--admin-accounts-edit form#edit_account', ($el) => new AccountForm($($el)))
