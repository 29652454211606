import {initVue} from '~/vue'

import ModalDialog from '../../components/shared/modal_dialog'
import SelectCard from '../../components/shared/select_card'
import DashboardShareModal from '../../components/dashboards/share_modal'

initVue('#vue--dashboards-form', {
  components: {ModalDialog, SelectCard, DashboardShareModal},
  data() {
    return {
      dashboard: gon.dashboard,
      orientations: gon.orientations,
      sizes: gon.sizes,
      selectedOrientation: gon.selected_orientation,
      selectedSize: gon.selected_size,
    }
  },
  methods: {
    getOrientationIcon(orientation) {
      switch (orientation) {
        case 'horizontal': return 'stay_current_landscape'
        case 'vertical': return 'stay_current_portrait'
      }
      return 'hexagon'
    },
    selectOrientation(orientation) {
      this.selectedOrientation = orientation
    },
    selectSize(size) {
      this.selectedSize = size
    },
  },
})
