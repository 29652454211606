import mixin from '../../helpers/mixin'
import AlarmConditionMixin from '../../mixins/alarm_condition_mixin'
import WrapperMixin from '../../mixins/wrapper_mixin'

class AlarmTemplate {
  constructor(attrs) {
    attrs ||= {}

    this._destroy = !!attrs._destroy
    this.errors = attrs.errors != null ? attrs.errors : {}
    this.id = attrs.id != null ? attrs.id : null
    this.unit = attrs.unit != null ? attrs.unit : ''
    this.value = attrs.value != null ? attrs.value : null
    this.channel = attrs.channel
    this.condition = attrs.condition != null ? attrs.condition : ''
  }

  serialize() {
    return {
      _destroy: this._destroy,
      channel_name: this.channelOption()?.name || '',
      condition: this.condition,
      id: this.id,
      unit: this.unit,
      value: this.value,
    }
  }
}

mixin.include(AlarmTemplate, AlarmConditionMixin)
mixin.extend(AlarmTemplate, WrapperMixin)

export default AlarmTemplate
