import ErrorMessage from '../shared/error_message'
import NestedSelect from '../shared/nested_select'
import NotificationPolicyForm from './notification_policy_form'

import EscalationPolicy from '../../services/wrappers/escalation_policy'

export default {
  name: 'escalation-policy-form',
  template: '#escalation-policy-form-template',
  components: {ErrorMessage, NestedSelect, NotificationPolicyForm},
  props: {
    policy: {
      type: Object,
      required: true,
    },
    selectedLocation: {
      type: Object,
      required: false,
    },
    locationOptions: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      cachedPolicy: new EscalationPolicy(this.policy),
    }
  },
  methods: {
    submit(event) {
      event.target.disabled = true
      this.$emit('submit', this.policy, () => event.target.disabled = false)
    },
    close() {
      Object.assign(this.policy, this.cachedPolicy)
      this.$emit('close', this.policy.id)
    },
  },
}
