
import _ from 'underscore'
import moment from '~/moment'

import DatapointRequester from '../../services/charts/datapoint_requester'
import ChannelWrapper from '../../services/charts/channel'
import NumberFormatter from '../../services/number_formatter'

const LABELS = {
  'Channel::Battery': 'Battery',
  'Channel::RelativeHumidity': 'Relative Humidity',
  'Channel::Temperature': 'Temperature',
  'Channel::DifferentialPressure': 'Diff. Pressure',
  'Channel::Boolean': 'Open/Close',
  'Channel::DewPoint': 'Temperature',
}

const Channel = {
  name: 'channel',
  template: '#channel-template',
  props: {
    alerts: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    channel: {
      type: Object,
      required: true,
    },
    dataRange: {
      type: Number,
      required: true,
      default: (24 * 60 * 60 * 1000),
    },
    filter: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      loaded: false,
      summary: {average: '-', minimum: '-', maximum: '-'},
      currentReading: '-',
      requester: new DatapointRequester(gon.dps_url, [this.channel]),
      formatter: new NumberFormatter(),
    }
  },
  computed: {
    displayable() {
      // NOTE: We would normally apply this filtering at the level of the parent
      // component, but we _really_ want to avoid recomputing our summary and
      // current reading data, so we're instead allowing the elements themselves
      // to determine whether or not they should show up in the DOM.
      const query = this.filter.toLowerCase()
      const filtered = _.isEmpty(query) || this.channel.name.toLowerCase().includes(query)

      const currentTime = new Date().getTime()
      const cutoffTime = currentTime - this.dataRange

      return filtered && this.lastReading > cutoffTime
    },
    channelAlerts() {
      const channel = this.channel
      const alerts = _.filter(this.alerts, function(alert) {
        return alert.token === channel.token && (alert.channel === channel.id || alert.channel === 0)
      })

      return _.partition(alerts, function(alert) {
        return alert.excursion
      })
    },
    excursionCount() {
      return _.size(this.channelAlerts[0])
    },
    excursionGap() {
      return this.maximumGap(this.channelAlerts[0])
    },
    excursionIcon() {
      return this.alertIcon(this.channelAlerts[0])
    },
    warningCount() {
      return _.size(this.channelAlerts[1])
    },
    warningGap() {
      return this.maximumGap(this.channelAlerts[1])
    },
    warningIcon() {
      return this.alertIcon(this.channelAlerts[1])
    },
    statusOk() {
      return this.excursionCount === 0 && this.warningCount === 0
    },
    channelType() {
      return LABELS[this.channel.type]
    },
    devicePath() {
      return '/devices/' + this.channel.token
    },
    alarmPath() {
      return this.devicePath + '/alarms'
    },
    settingsPath() {
      return this.devicePath + '/settings'
    },
    excursionPath() {
      return this.alertPath('excursion')
    },
    warningPath() {
      return this.alertPath('warning')
    },
    statusClass() {
      if (this.excursionCount > 0) {
        return 'channel--excursion'
      } else if (this.warningCount > 0) {
        return 'channel--warning'
      } else {
        return 'channel--success'
      }
    },
    lastReading() {
      if (this.channel.timestamp) {
        return this.channel.timestamp * 1000
      } else {
        return null
      }
    },
    timeDisplay() {
      if (!this.lastReading) {
        return '-'
      }

      return 'as of ' + moment(new Date(this.lastReading)).fromNow()
    },
    unit() {
      return new ChannelWrapper({}, [], this.channel).formattedUnit()
    },
  },
  methods: {
    alertPath(severity) {
      return '/alerts?alert_search_form[severity]=' + severity + '&alert_search_form[tokens][]=' + this.channel.token
    },
    hasCondition(alerts, condition) {
      return _.any(alerts, function(alert) {
        return alert.condition == condition
      })
    },
    maximumGap(alerts) {
      if (!this.showGap(alerts)) {
        return '-'
      }

      const reading = this.currentReading
      const channel = this.channel

      return _.max(_.map(alerts, function(alert) {
        const ch = new ChannelWrapper(alert, [], channel)
        const value = ch._convert(alert.value)

        return Math.abs(value - reading).toFixed(ch.precision())
      }))
    },
    showGap(alerts) {
      return !(this.hasCondition(alerts, '?') || this.hasCondition(alerts, '-') || !this.currentReading)
    },
    alertIcon(alerts) {
      if (this.hasCondition(alerts, '?')) {
        return 'Not Reporting'
      } else if (this.hasCondition(alerts, '-')) {
        return 'Unplugged'
      } else if (this.hasCondition(alerts, '<')) {
        return '<i class=\'material-icons\'>trending_down</i>'
      } else if (this.hasCondition(alerts, '>')) {
        return '<i class=\'material-icons\'>trending_up</i>'
      }
    },
    loadSummary() {
      const requester = this.requester
      const currentTime = new Date().getTime()
      const cutoffTime = currentTime - this.dataRange

      requester.fetch(cutoffTime, currentTime, 'summary', (channel) => {
        const summary = channel.metadata()

        if (summary.avg.value) {
          this.summary = {
            average: this.formatter.format(summary.avg.value, channel.precision()),
            minimum: this.formatter.format(summary.min.value, channel.precision()),
            maximum: this.formatter.format(summary.max.value, channel.precision()),
          }
        }
      }).catch(() => this.loaded = false)
    },
    loadReading() {
      const requester = this.requester
      const timestamp = this.lastReading

      requester.fetch(timestamp, timestamp, 1, (channel) => {
        const value = channel.values()[0]
        this.currentReading = this.formatter.format(value, channel.precision())
      }).catch(() => this.loaded = false)
    },
    load() {
      // NOTE: `loaded` here is being used to short-circuit additional requests
      // after the initial requests have been enqueued. It doesn't imply that
      // the requests completed successfully.
      //
      // We reset this flag to `false` if we encounter any errors while loading.
      this.loaded = true
      this.loadSummary()
      this.loadReading()
    },
  },
  mounted() {
    if (this.displayable) {
      this.load()
    }
  },
  watch: {
    displayable(value, _oldValue) {
      if (!value || !this.lastReading || this.loaded) {
        return
      }

      this.load()
    },
  },
}

export default Channel
