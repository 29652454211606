import _ from 'underscore'
import MonitoringPointSummary from './monitoring_point_summary'

/**
The `monitoring-point-search` component is useful for selecting monitoring points
(channel, equipment, data logger or location) from a list for use in a larger workflow.
Emits a `select` event when a monitoring point is selected from the results list.

@name monitoring-point-search
@prop {Object} monitoringPoints
  A hash of monitoring point Objects for selection indexed by type (channel, equipment,
  device or location).
@prop {array} selected
  A list of currently-selected monitoring point Objects. These are suppressed from the
  search results.

@template components/monitoring_points/search
@design https://zpl.io/1yrq6eQ

@example
  {
    "monitoringPoints": {
      "channels": [{
        "id": 1,
        "name": "Temperature CH:1",
        "uuid": "5dede3b0-f2a1-4069-b83f-3f35c7a32324",
        "type": "Channel::Temperature",
        "class": "Channel",
        "key": "channel-1",
        "localized_type": "Temperature",
        "device": {"name": "Chiller", "serial": "420010"},
        "equipment": [{"id": 2, "name": "My Custom Equipment"}],
        "location": {"id": 4, "name": "Inventing Room"},
        "sensor": "Sensor: dab9aac3f2c04723",
        "labels": ["Inventing Room", "[Chiller]", "Sensor: dab9aac3f2c04723"]
      }],
      "equipment": [{
        "id": 1,
        "name": "My Freezer Humidity",
        "class": "Equipment",
        "key": "equipment-1",
        "type": "freezer",
        "localized_type": "Freezer",
        "location": {"id": 4, "name": "Inventing Room"},
        "labels": ["12 Channels", "[Temperature]", "[Relative Humidity]", "[Freezer]", "Inventing Room"]
      }]
    },
    "selected": []
  }
*/

const MonitoringPointSearch = {
  name: 'monitoring-point-search',
  template: '#monitoring-point-search-template',
  components: {MonitoringPointSummary},
  props: {
    monitoringPoints: {
      type: Object,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      searchTerm: '',
      showResults: false,
      focusIndex: 0,
    }
  },
  computed: {
    matchingMonitoringPoints() {
      if (!this.showResults) {
        return []
      }

      return _.chain(this.matchingLocations())
          .union(this.matchingEquipment(), this.matchingDevices(), this.matchingChannels())
          .compact()
          .value()
    },
    focusableElements() {
      if (this.showResults) {
        const resultElements = this.$refs.results ? this.$refs.results.map((res) => res.$el) : []
        return [this.$refs.searchInput, ...resultElements]
      }

      return []
    },
  },
  methods: {
    handleFocusOut(event) {
      const nextFocusedElement = event.relatedTarget
      const newIndex = this.focusableElements.indexOf(nextFocusedElement)
      this.showResults = newIndex >= 0
      if (this.showResults && this.focusIndex != newIndex) {
        this.focusIndex = newIndex
      }
    },
    handleKeyDown() {
      this.setFocusIndex(this.focusIndex + 1)
    },
    handleKeyUp() {
      this.setFocusIndex(this.focusIndex - 1)
    },
    matching(monitoringPoints, resolveAttrs) {
      if (monitoringPoints.length === 0) {
        return []
      }

      const search = this.searchTerm.toLowerCase()
      return _.filter(monitoringPoints, (monitoringPoint) => {
        const attrs = resolveAttrs(monitoringPoint)
        return _.any(attrs, (attr) => (attr || '').toLowerCase().includes(search))
      })
    },
    matchingChannels() {
      const channelOptions = _.without(this.monitoringPoints.channels, ...this.selected)
      return this.matching(channelOptions, (channel) => {
        return _.union(
            [
              channel.name,
              channel.device.name,
              channel.device.serial,
              channel.localized_type,
              channel.location.name,
              channel.sensor,
            ],
            _.pluck(channel.equipment, 'name'),
        )
      })
    },
    matchingDevices() {
      const deviceOptions = _.without(this.monitoringPoints.devices, ...this.selected)
      return this.matching(deviceOptions, (device) => [
        device.name,
        device.serial,
        device.location.name,
      ])
    },
    matchingEquipment() {
      const equipmentOptions = _.without(this.monitoringPoints.equipment, ...this.selected)
      return this.matching(equipmentOptions, (equipment) => [
        equipment.name,
        equipment.localized_type,
        equipment.location.name,
      ])
    },
    matchingLocations() {
      const locationOptions = _.without(this.monitoringPoints.locations, ...this.selected)
      return this.matching(locationOptions, (location) => [
        location.name,
        location.parent?.name,
      ])
    },
    selectMonitoringPoint(monitoringPoint) {
      this.$emit('select', monitoringPoint)
      this.showResults = false
      this.searchTerm = ''
    },
    setFocusIndex(index) {
      const focusable = this.focusableElements
      const newIndex = focusable.length ? index % focusable.length : 0
      this.focusIndex = newIndex < 0 ? focusable.length - 1 : newIndex
      focusable[this.focusIndex].focus()
    },
    onFocus() {
      this.showResults = true
    },
  },
}

export default MonitoringPointSearch
