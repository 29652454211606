import Color from 'color'
import $ from 'jquery'
import _ from 'underscore'

import DatapointRequester from '../charts/datapoint_requester'

const SPARKLINE_WINDOW = (24 * 60 * 60 * 1000)

// RecentReadings displays recent readings data for a device.
export default class RecentReadings {
  constructor($element, channels) {
    this.$element = $element
    this._channels = {}

    _.pairs(channels).forEach((...args) => {
      const [id, metadata] = args[0]
      if (!metadata.display) {
        return
      }

      this._channels[id] = metadata
    })

    this.requester = new DatapointRequester(gon.dps_url, this._channels)
    this.template = _.template($('.js--recent-readings-tpl').html())
    this.maxTime = Date.now()
    this.minTime = this.maxTime - SPARKLINE_WINDOW
  }

  // Public: Render rows for all channels. Remove rows for channels with no data.
  //
  // Returns a Promise.
  render() {
    const channels = []

    return this.requester
        .fetch(this.minTime, this.maxTime, null, (channel) => {
          channel.extra = this._channels[channel.number]
          return channels.push(channel)
        })
        .then(() => {
          return _.sortBy(channels, 'number').map((channel) => this._renderRow(channel))
        })
  }

  // Internal: Render row for a single channel and initialize a sparkline graph.
  //
  // channel - An Object containing channel information.
  //
  // Returns nothing.
  _renderRow(channel) {
    const readings = this.template({channel, alert: this._alert(channel)})

    $(readings)
        .appendTo(this.$element)
        .find('.js--sparkline')
        .sparkline(channel.values(), {
          width: '100px',
          height: '25px',
          lineColor: channel.extra.color,
          fillColor: this._fillColor(channel.extra.color),
        })
  }

  // Internal: Find the first active alert for the passed channel, if there is
  // one. Prioritizes excursions over warnings.
  //
  // channel - An Object containing channel information.
  //
  // Returns an Object or null.
  _alert(channel) {
    const alerts = _.filter(gon.alerts || [], (alert) => alert.channel === channel.number)
    return _.sortBy(alerts, (alert) => !alert.excursion)[0]
  }

  // Internal: Get the fill color for a sparkline graph.
  //
  // rgbString - A String containing RGB values.
  //
  // Returns a String.
  _fillColor(rgbString) {
    const color = new Color(rgbString)
    if (color.isDark()) {
      color.lighten(0.6)
    } else {
      color.lighten(0.3)
    }

    return color.rgb().string()
  }
}
