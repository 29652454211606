import equipmentIcon from './equipment_icon'

const icons = {
  'Location': 'pin_drop',
  'Device': 'tablet_android',
}

export default function pinIcon(pin) {
  if (Object.prototype.hasOwnProperty.call(pin, 'alerts') && pin.alerts?.length) {
    const hasExcursion = pin.alerts[0].excursion_at
    return hasExcursion ? 'error' : 'warning'
  } else {
    return icons[pin.type] || equipmentIcon(pin)
  }
}
