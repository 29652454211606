export default {
  name: 'pin-number',
  template: '#pin-number-template',
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      default: 6,
    },
    assignedLength: {
      type: Number,
      default: 2,
    },
  },
  emits: ['input'],
  data() {
    return {
      editable: false,
      editableLength: this.length - this.assignedLength,
      visible: false,
    }
  },
  computed: {
    assignedDigits() {
      return this.pinDigits(this.modelValue).slice(this.editableLength)
    },
    editableDigits() {
      return this.pinDigits(this.modelValue).slice(0, this.editableLength)
    },
    visibilityIcon() {
      return this.visible ? 'visibility_off' : 'visibility'
    },
  },
  methods: {
    handleFocus(index) {
      this.$refs[`pin-${index}`][0].value = ''
    },
    handleInput(index) {
      if (!/^\d$/.test(this.editableDigits[index])) {
        this.$refs[`pin-${index}`][0].value = ''
        return
      }

      if (index < this.editableLength - 1) {
        this.$refs[`pin-${index + 1}`][0].focus()
      } else {
        this.editable = false
      }

      this.$emit('input', this.editableDigits.join(''))
    },
    onEdit() {
      this.editable = !this.editable

      if (this.editable) {
        this.visible = true
      }
    },
    pinDigits(pinNumber) {
      const digits = pinNumber.toString().split('').map(Number)
      return new Array(this.length - digits.length).fill(0).concat(digits)
    },
    toggleVisibility() {
      this.visible = !this.visible
    },
  },
}
