import {createGrid} from 'ag-grid-community'
import _ from 'underscore'

import {initVue} from '~/vue'
import DaterangeFilter from '../../../components/shared/daterange_filter'
import GridFilter from '../../../components/mixins/grid_filter'
import ModalDialog from '../../../components/shared/modal_dialog'

import DateRenderer from '../../../renderers/date_renderer'
import TagsRenderer from '../../../renderers/tags_renderer'
import initElement from '../../../helpers/init_element'

const sensorFilters = initVue('#vue--calibrations-build-selection', {
  components: {DaterangeFilter, ModalDialog},
  mixins: [GridFilter],
  data() {
    return {
      appliedFilters: {
        serial: [],
        calibrationDueDate: {},
        calibrationInterval: [],
        lastCalibrationDate: {},
        sku: [],
        device: [],
        location: [],
        tags: [],
      },
      selectedFilters: {
        serial: [],
        calibrationInterval: [],
        lastCalibrationDate: {},
        sku: [],
        device: [],
        location: [],
        tags: [],
      },
      options: {
        calibrationInterval: _.uniq(_.pluck(gon.data, 'calibration_interval')).sort(),
        device: _.uniq(_.pluck(gon.data, 'device')).sort(),
        location: _.uniq(_.pluck(gon.data, 'location')).sort(),
        sku: _.uniq(_.pluck(gon.data, 'sku')).sort(),
        serial: _.uniq(_.pluck(gon.data, 'serial')).sort(),
        tags: _.uniq(_.flatten(_.pluck(gon.data, 'tags'))).sort(),
      },
      filters() {
        return {
          calibrationDueDate: this.doesCalibrationDueDatePass,
          calibrationInterval: 'calibration_interval',
          device: 'device',
          lastCalibrationDate: this.doesLastCalibrationDatePass,
          location: 'location',
          sku: 'sku',
          serial: 'serial',
          tags: this.doesTagsFilterPass,
        }
      },
      customFilterPills() {
        return {
          lastCalibrationDate: {
            label: this.appliedLastCalibrationDateToString,
            onClose: () => this.clearLastCalibrationDate(true),
          },
        }
      },
      calibrationDueDateRadio: '',
      panelFilters: ['calibrationInterval', 'device', 'lastCalibrationDate', 'location', 'model', 'serial', 'tags'],
      rowCount: gon.data.length,
    }
  },
  computed: {
    calibrationDueDateRangeToString() {
      return this.dateRangeToString(this.appliedFilters.calibrationDueDate)
    },
    appliedLastCalibrationDateToString() {
      const lastCalibrationDate = this.appliedFilters.lastCalibrationDate
      if (_.isEmpty(lastCalibrationDate)) {
        return ''
      }
      return [lastCalibrationDate.start, lastCalibrationDate.end].map((date) => moment(date).format(momentDateFormat)).join(' - ')
    },
    selectedLastCalibrationDateToString() {
      return this.dateRangeToString(this.selectedFilters.lastCalibrationDate)
    },
    selectedSensorIds() {
      return _.pluck(this.selectedRows, 'sensor_id')
    },
  },
  watch: {
    calibrationDueDateRadio(newValue) {
      if (Number.isInteger(parseInt(newValue))) {
        const startDate = new Date()
        const endDate = moment().add(newValue, 'days').toDate()
        this.setCalibrationDueDateRange(startDate, endDate)
      } else {
        this.clearCalibrationDueDate(true)
      }
    },
    selectedRows(newSelection) {
      if (this.appliedSelection) {
        const adjustmentData = _.chain(this.appliedSelection)
            .union(newSelection)
            .groupBy('sensor_id')
            .map(function(values, sensorId) {
              return {
                sensor_id: sensorId,
                calibration_adjustments_attributes: _.map(values, function(value) {
                  const adjustment = {index: value.index}
                  if (!newSelection.includes(value)) {
                    adjustment._destroy = true
                  }

                  return adjustment
                }),
              }
            }).value()

        $.ajax({
          url: $(this.$el).find('form').prop('action'),
          method: 'PUT',
          contentType: 'application/json',
          data: JSON.stringify({build: {sensor_calibrations_attributes: adjustmentData}}),
          success: function(_response) {
            this.appliedSelection = newSelection
          }.bind(this),
        })
      } else {
        this.appliedSelection = newSelection
      }
    },
  },
  methods: {
    clearAllFilters() {
      this.calibrationDueDateRadio = ''
      this.clearLastCalibrationDate()
      this.clearPanelFilters()
      this.applyPanelFilters()
    },
    clearCalibrationDueDate(updateFilters) {
      this.appliedFilters.calibrationDueDate = {}
      if (updateFilters) {
        this.updateFilters()
      }
    },
    clearLastCalibrationDate(updateFilters) {
      this.selectedFilters.lastCalibrationDate = {}
      this.appliedFilters.lastCalibrationDate = {}
      if (updateFilters) {
        this.updateFilters()
      }
    },
    doesCalibrationDueDatePass(row) {
      return this.doesDateRangeFilterPass(this.appliedFilters.calibrationDueDate, row.data.calibration_due_date)
    },
    doesLastCalibrationDatePass(row) {
      return this.doesDateRangeFilterPass(this.appliedFilters.lastCalibrationDate, row.data.last_calibration_date)
    },
    doesTagsFilterPass(row) {
      return _.isEmpty(this.appliedFilters.tags) ||
        _.any(_.intersection(this.appliedFilters.tags, row.data.tags))
    },
    setCalibrationDueDateRange(start, end) {
      this.appliedFilters.calibrationDueDate = {start, end}
      this.updateFilters()
    },
  },
})

initElement('#vue--calibrations-build-selection', () => {
  const grid = createGrid(document.querySelector('#grid--sensors'), {
    pagination: true,
    paginationPageSize: gon.page_size,
    paginationPageSizeSelector: false,
    isExternalFilterPresent() {
      return sensorFilters.isFilterPresent
    },
    doesExternalFilterPass(node) {
      return sensorFilters.doesFilterPass(node)
    },
    rowStyle: {background: 'white'},
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    suppressColumnVirtualisation: true,
    domLayout: 'autoHeight',
    defaultColDef: {
      resizable: true,
      cellStyle: {
        'white-space': 'normal',
        'padding': '10px',
      },
      autoHeight: true,
      sortable: true,
      filter: false,
    },
    columnDefs: [
      {
        headerName: gon.labels['name'],
        field: 'name',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        pinned: 'left',
      },
      {
        headerName: gon.labels['serial'],
        field: 'serial',
        pinned: 'left',
      },
      {
        headerName: gon.labels['calibration_due_date'],
        field: 'calibration_due_date',
        cellRenderer: DateRenderer,
      },
      {
        headerName: gon.labels['calibration_interval'],
        field: 'calibration_interval',
      },
      {
        headerName: gon.labels['last_calibration_date'],
        field: 'last_calibration_date',
        cellRenderer: DateRenderer,
      },
      {
        headerName: gon.labels['sku'],
        field: 'sku',
      },
      {
        headerName: gon.labels['device'],
        field: 'device',
      },
      {
        headerName: gon.labels['location'],
        field: 'location',
      },
      {
        headerName: gon.labels['tags'],
        field: 'tags',
        cellRenderer: TagsRenderer,
        suppressSizeToFit: true,
      },
    ],
    rowData: gon.data,
    onGridReady(event) {
      event.api.autoSizeAllColumns()
      event.api.setGridAriaProperty('label', gon.labels['sensors_table'])
      event.api.forEachNode((node) => {
        const sensorSelected = gon.selected[node.data.sensor_id]
        if (!sensorSelected) {
          return
        }

        const channelSelected = sensorSelected.includes(node.data.index)
        node.setSelected(channelSelected)
      })
    },
    onSelectionChanged: sensorFilters.onSelectionChanged,
  })

  sensorFilters.grid = grid
})
