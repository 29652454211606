import $ from 'jquery'

export default class MaskedField {
  // Public: Construct a new MaskedField using the passed element.
  //
  // el - A collection of HTML elements to mask.
  constructor(el) {
    const $elements = $(el)

    $elements.each(this._setup)
    $elements.on('click', '.js--mask__cover, .js--mask__content i', this._toggle)
  }

  // Initialize: Set up visual and control elements for masking functionality.
  //
  // el - An HTML element representing the mask container.
  //
  // Returns nothing.
  _setup(_, el) {
    $(el)
        .append('<i class=\'material-icons\'>visibility_off</i>')
        .wrapInner('<span class=\'js--mask__content js--mask__conceal\' />')
        .prepend('<span class=\'js--mask__cover\' />')
        .show()
  }

  // Internal: Toggle which element is concealed within a masked component.
  //
  // e - A click event.
  //
  // Returns nothing.
  _toggle(e) {
    const $el = $(e.currentTarget).closest('.js--mask')
    $el.find('.js--mask__cover, .js--mask__content').toggleClass('js--mask__conceal')
  }
}
