import $ from 'jquery'
import _ from 'underscore'
import initElement from '../../../helpers/init_element'

class UserForm {
  constructor($form) {
    this._confirmRoleSelection = this._confirmRoleSelection.bind(this)

    this.$form = $form
    this.$roleSelect = this.$form.find('select[name=\'user[role]\']')

    this._originalRole = this.$roleSelect.val()
    this.$form.on('submit', this._confirmRoleSelection)
  }

  _internalRoles() {
    return this.$roleSelect.data('internal-roles')
  }

  _currentRole() {
    return this.$roleSelect.val()
  }

  _confirmRoleSelection() {
    const internal = _.contains(this._internalRoles(), this._currentRole())

    if (internal && (this._currentRole() !== this._originalRole)) {
      return confirm(this.$form.data('internal-confirmation'))
    } else {
      return true
    }
  }
}

initElement('#js--admin-users-edit form', ($el) => new UserForm($($el)))
