import mixin from '../../helpers/mixin'
import AlarmConditionMixin from '../../mixins/alarm_condition_mixin'
import AlarmNotificationsMixin from '../../mixins/alarm_notifications_mixin'
import EscalationPoliciesMixin from '../../mixins/escalation_policies_mixin'
import WrapperMixin from '../../mixins/wrapper_mixin'

import AlertSchedule from './alert_schedule'
import EscalationPolicy from './escalation_policy'

import underscoreKeys from '../../helpers/underscore_keys'

class Alarm {
  constructor(attrs) {
    if (attrs == null) {
      attrs = {}
    }

    this.addReferenceLine = attrs.addReferenceLine != null ? attrs.addReferenceLine : false
    this.channel = attrs.channel != null ? attrs.channel : ''
    this.channelName = attrs.channelName != null ? attrs.channelName : ''
    this.createReusableTemplate = attrs.createReusableTemplate != null ? attrs.createReusableTemplate : false
    this.condition = attrs.condition != null ? attrs.condition : ''
    this.custom = attrs.custom != null ? attrs.custom : false
    this.customDelay = attrs.customDelay != null ? attrs.customDelay : false
    this.device = attrs.device
    this.errors = attrs.errors != null ? attrs.errors : {}
    this.escalationPolicies = EscalationPolicy.wrap(attrs.escalationPolicies)
    this.escalationPolicyApplications = attrs.escalationPolicyApplications != null ? attrs.escalationPolicyApplications : []
    this.alarmTemplateId = attrs.alarmTemplateId
    this.templateGroupId = attrs.templateGroupId
    this.alertSchedule = new AlertSchedule(attrs.alertSchedule)
    this.id = attrs.id != null ? attrs.id : null
    this.lockVersion = attrs.lockVersion
    this.message = attrs.message != null ? attrs.message : ''
    this.paused = attrs.paused
    this.pauseUrl = attrs.pauseUrl
    this.alarmPauses = attrs.alarmPauses
    this.templateName = attrs.templateName
    this.unit = attrs.unit != null ? attrs.unit : ''
    this.url = attrs.url != null ? attrs.url : window.location.pathname
    this.value = attrs.value != null ? attrs.value : ''
    this.addHardwareNotification = (this.existingHardwareNotification() != null)
    this.hardwareDelayInterval = this.existingHardwareDelayInterval()
    this.hardwareExcursion = this.existingHardwareExcursion()
  }

  serialize() {
    return {
      add_reference_line: this.addReferenceLine,
      alarm_notifications_attributes: this.serializedNotifications(),
      alert_schedule_attributes: this.alertSchedule.serialize(),
      channel: this.channel,
      create_alarm_template: this.createReusableTemplate,
      condition: this.condition,
      escalation_policy_applications_attributes: this.escalationPolicyApplications.map(underscoreKeys),
      lock_version: this.lockVersion,
      template_name: this.templateName,
      unit: this.unit,
      value: this.value,
    }
  }

  get customEscalationPolicy() {
    let customPolicy = this.escalationPolicies.find((policy) => policy.custom)
    if (!customPolicy) {
      customPolicy = new EscalationPolicy({custom: true})
      this.escalationPolicies.push(customPolicy)
    }
    return customPolicy
  }

  get notificationGroups() {
    return this.customEscalationPolicy.notificationGroups
  }

  get soundProhibited() {
    return this.conditionOption()?.soundProhibited
  }
}

mixin.include(Alarm, AlarmConditionMixin)
mixin.include(Alarm, AlarmNotificationsMixin)
mixin.include(Alarm, EscalationPoliciesMixin)
mixin.extend(Alarm, WrapperMixin)

export default Alarm
