import $ from 'jquery'
import initElement from '../../../helpers/init_element'

initElement('#js--devices-replacements-new', () => {
  const $form = $('form')
  const $button = $('.js--replacementWarningButton')

  $button.on('click', function(e) {
    e.preventDefault()

    const url = $button.prop('href')
    const data = {registration_code: $('input[name=\'registration_code\'').val()}

    $.post(url, data, function(data) {
      if (data.content) {
        $(data.content).appendTo($form).modal('show')
      } else {
        $form.trigger('submit')
      }
    })
  })
})
