import _ from 'underscore'

const WrapperMixin = {
  wrapWithDefault(attrList) {
    if (_.isEmpty(attrList)) {
      return [new (this)]
    } else {
      return this.wrap(attrList)
    }
  },

  wrap(attrList) {
    return _.toArray(attrList).map((attrs) => {
      return new (this)(attrs)
    })
  },
}

export default WrapperMixin
