import _ from 'underscore'

export default {
  conditionOptions() {
    return this.channelOption()?.conditionOptions || []
  },

  valueOptions() {
    return this.channelOption()?.valueOptions || []
  },

  channelOption() {
    return _.find(gon.channels, (option) => {
      return option.channelNum === this.channel
    })
  },

  conditionOption() {
    return _.find(this.conditionOptions(), (option) => {
      return option.value === this.condition
    })
  },

  defaultUnit() {
    return this.conditionOption()?.defaultUnit || this.unitOptions()[0]?.value || ''
  },

  unitOptions() {
    return this.conditionOption()?.unitOptions || []
  },

  requiresValueAndUnit() {
    return this.conditionOption()?.requiresValueAndUnit
  },
}
