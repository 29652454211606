import _ from 'underscore'
import {initVue} from '~/vue'

import ChannelSearch from '../../components/channels/search'
import EquipmentHeader from '../../components/equipment/equipment_header'
import ModalDialog from '../../components/shared/modal_dialog'
import MonitoringPointSummary from '../../components/shared/monitoring_point_summary'
import SelectCard from '../../components/shared/select_card'

import equipmentIcon from '../../helpers/equipment_icon'

initVue('#vue--equipment-form', {
  components: {ChannelSearch, EquipmentHeader, ModalDialog, MonitoringPointSummary, SelectCard},
  data() {
    const equipment = gon.equipment
    const channels = gon.channels
    const connectedIds = _.pluck(gon.connected_channels, 'id')
    const connectedChannels = _.filter(channels, (ch) => connectedIds.includes(ch.id))

    return {
      channels,
      connectedChannels,
      equipment,
      locationId: gon.location_id,
      sameLocationOnly: true,
      type: equipment?.type || '',
      types: gon.equipment_types,
    }
  },
  computed: {
    connectedChannelIds() {
      const ids = _.pluck(this.connectedChannels, 'id')
      return ids.length ? ids : ['']
    },
    customType() {
      return this.type == 'custom'
    },
    selectableChannels() {
      let selectableChannels = _.chain(this.channels)
      if (this.sameLocationOnly) {
        selectableChannels = selectableChannels.filter((channel) => channel.location.id == this.locationId, this)
      }

      return selectableChannels.value()
    },
    totalConnectedChannels() {
      return this.connectedChannels.length
    },
  },
  methods: {
    connect(channel) {
      const connected = [channel, ...this.connectedChannels]
      this.connectedChannels = connected
    },
    disconnect(channel) {
      const connected = _.without(this.connectedChannels, channel)
      this.connectedChannels = connected
    },
    getIcon: equipmentIcon,
    selectType(type) {
      this.type = type
    },
  },
})
