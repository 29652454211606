import $ from 'jquery'

class FeatureFlags {
  constructor($checkboxes) {
    this.bindEvents = this.bindEvents.bind(this)
    this.onChange = this.onChange.bind(this)

    this.$checkboxes = $checkboxes
    this.bindEvents()
  }

  bindEvents() {
    this.$checkboxes.change(this.onChange)
  }

  onChange(event) {
    $(event.target).closest('form').submit()
  }
}

$('#js--admin-feature_flags-index').each(() => {
  const $featureFlagCheckboxes = $('.feature_flag :checkbox')
  if ($featureFlagCheckboxes.length > 0) {
    new FeatureFlags($featureFlagCheckboxes)
  }
})
