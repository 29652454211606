import _ from 'underscore'

import mixin from '../../helpers/mixin'
import AlarmNotificationsMixin from '../../mixins/alarm_notifications_mixin'
import EscalationPoliciesMixin from '../../mixins/escalation_policies_mixin'
import WrapperMixin from '../../mixins/wrapper_mixin'

import Alarm from './alarm'
import AlarmTemplate from './alarm_template'
import AlertSchedule from './alert_schedule'
import EscalationPolicy from './escalation_policy'
import NotificationGroup from './notification_group'

import underscoreKeys from '../../helpers/underscore_keys'

class TemplateGroup {
  constructor(attrs) {
    this.conditionOption = this.conditionOption.bind(this)
    this.hasAppliedAlarms = this.hasAppliedAlarms.bind(this)
    this.serialize = this.serialize.bind(this)

    if (!attrs) {
      attrs = {}
    }

    this.alarms = Alarm.wrapWithDefault(attrs.alarms)
    this.alarmTemplates = AlarmTemplate.wrap(attrs.alarmTemplates)
    this.alertSchedule = new AlertSchedule(attrs.alertSchedule)
    this.templateApplications = attrs.templateApplications || []
    this.errors = attrs.errors || {}
    this.escalationPolicies = EscalationPolicy.wrap(attrs.escalationPolicies)
    this.escalationPolicyApplications = attrs.escalationPolicyApplications || []
    this.id = attrs.id
    this.lockVersion = attrs.lockVersion
    this.name = attrs.name
    this.notificationGroups = NotificationGroup.wrap(attrs.notificationGroups)
    this.unapplyUrl = attrs.unapplyUrl
    this.url = attrs.url

    this.addHardwareNotification = (this.existingHardwareNotification() != null)
    this.hardwareDelayInterval = this.existingHardwareDelayInterval()
    this.hardwareExcursion = this.existingHardwareExcursion()
  }

  conditionOption() {
    return gon.conditionOption
  }

  get visibleAlarmTemplates() {
    if (this.alarmTemplates) {
      return this.alarmTemplates.filter((template) => !template._destroy)
    } else {
      return []
    }
  }

  hasAppliedAlarms() {
    return !_.isEmpty(this.alarms.filter((alarm) => alarm.id != null))
  }

  serialize() {
    return {
      alert_schedule_attributes: this.alertSchedule.serialize(),
      alarm_templates_attributes:
        this.alarmTemplates.map((template) => template.serialize()),
      alarm_notifications_attributes: this.serializedNotifications(),
      template_applications_attributes: this.templateApplications.map(underscoreKeys),
      escalation_policy_applications_attributes: this.escalationPolicyApplications.map(underscoreKeys),
      lock_version: this.lockVersion,
      name: this.name,
    }
  }
}

mixin.include(TemplateGroup, AlarmNotificationsMixin)
mixin.include(TemplateGroup, EscalationPoliciesMixin)
mixin.extend(TemplateGroup, WrapperMixin)

export default TemplateGroup
