import _ from 'underscore'

import TimeSlot from './time_slot'
import TimeSlotWrapper from '../../services/wrappers/time_slot'

export default {
  name: 'alert-schedule',
  template: '#alert-schedule-template',
  components: {TimeSlot},
  props: {
    alertSchedule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      always: _.isEmpty(this.alertSchedule.timeSlots),
    }
  },
  computed: {
    visibleTimeSlots() {
      return _.filter(this.alertSchedule.timeSlots, function(timeSlot) {
        return !timeSlot._destroy
      })
    },
  },
  methods: {
    addTimeSlot() {
      this.alertSchedule.timeSlots.push(new TimeSlotWrapper())
    },
    deleteTimeSlot(index) {
      const timeSlot = Object.assign(this.alertSchedule.timeSlots[index], {_destroy: true})
      this.alertSchedule.timeSlots[index] = timeSlot
    },
  },
  watch: {
    'always'(value, oldValue) {
      if (value) {
        this.alertSchedule.timeSlots = []
      } else {
        this.addTimeSlot()
      }
    },
  },
}
