import _ from 'underscore'
import {initVue} from '~/vue'

import ContactRow from '../../components/contacts/contact_row'
import ContactSearch from '../../components/contacts/contact_search'

import Contact from '../../services/wrappers/contact'

initVue('#vue--contacts-index', {
  components: {ContactRow, ContactSearch},
  data() {
    return {
      contacts: _.sortBy(Contact.wrapWithDefault(gon.contacts), 'lastName'),
      userRoles: gon.userRoles,
      searchTerm: '',
      sortOption: 'lastName',
    }
  },
  computed: {
    filteredContacts() {
      if (_.isEmpty(this.searchTerm)) {
        return this.contacts
      }
      return this.contacts.filter(function(contact) {
        return contact.fullName.toLowerCase().includes(this.searchTerm)
      }.bind(this))
    },
    displayContacts() {
      return this.sortContacts(this.filteredContacts)
    },
    noResults() {
      return !_.isEmpty(this.searchTerm) && _.isEmpty(this.filteredContacts)
    },
  },
  methods: {
    filterContacts(query) {
      this.searchTerm = query.toLowerCase()
    },
    sortContacts(contacts) {
      let withValue
      let withoutValue

      switch (this.sortOption) {
        case 'lastName':
          return _.sortBy(contacts, 'lastName')
        case 'firstName':
          return _.sortBy(contacts, 'firstName')
        case 'role':
          [withValue, withoutValue] = _.partition(contacts, (c) => !!c.userRoleId)
          withValue = _.sortBy(withValue, function(c) {
            const role = this.userRoles.find((r) => r.id === c.userRoleId)
            return (role ? role.name : '')
          }, this)

          return withValue.concat(withoutValue)
        case 'location':
          [withoutValue, withValue] = _.partition(contacts, (c) => _.isEmpty(c.locations))
          return _.sortBy(withValue, (c) => c.locations[0].name).concat(withoutValue)
      }
    },
  },
})
