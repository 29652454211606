import MonitoringPointIcon from './monitoring_point_icon'

/**
The `monitoring-point-summary` component renders a single channel, location, data logger or equipment, with iconography.
Various metadata labels for associated records in the
system are passed into the component using slots: `.labels{slot: "labels"}`

@name monitoring-point-summary
@prop {object} item
  A monitoring point (channel, equipment, data logger or location) Object.
@prop {string} type
  Type of monitoring point.

@template components/monitoring_points/summary
@design https://zpl.io/1yrq6eQ

@wrapper
  <ul class="monitoring-point-list">{example}</ul>
@example
  {
    "item": {
      "id": 1,
      "name": "Temperature CH:1",
      "uuid": "5dede3b0-f2a1-4069-b83f-3f35c7a32324",
      "type": "Channel::Temperature",
      "device": {"name": "Chiller", "path": "/devices/645329209841963433", "serial": "420010"},
      "equipment": [{"id": 2, "name": "My Custom Equipment", "path": "/equipment/2"}],
      "location": {"id": 4, "name": "Inventing Room", "path": "/locations/4"},
      "localized_type": "Temperature",
      "sensor": "Sensor: dab9aac3f2c04723"
    },
    "type": "channel"
  }
@example
  {
    "item": {
      "id": 2,
      "name": "Relative Humidity CH:1",
      "uuid": "f60156a7-9d71-46cf-a378-b1c30cd86600",
      "type": "Channel::RelativeHumidity",
      "device": {"name": "Chiller", "path": "/devices/645329209841963433", "serial": "420010"},
      "equipment": [],
      "location": {"id": 4, "name": "Inventing Room", "path": "/locations/4"},
      "localized_type": "Relative Humidity",
      "sensor": "Sensor: dab9aac3f2c04723"
    },
    "type": "channel",
    "v-on:delete": "console.log('removing channel')"
  }
*/

const MonitoringPointSummary = {
  name: 'monitoring-point-summary',
  template: '#monitoring-point-summary-template',
  components: {MonitoringPointIcon},
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: this.item.name,
      itemType: this.item.localized_type || this.type,
    }
  },
  computed: {
    showDeleteIcon() {
      return this.$attrs?.onDelete
    },
  },
}

export default MonitoringPointSummary
