import _ from 'underscore'

export default {
  name: 'template-application-form',
  template: '#template-application-form-template',
  props: {
    options: {
      type: Array,
      required: true,
    },
    templateGroup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {searchTerm: ''}
  },
  computed: {
    displayedOptions() {
      const term = this.searchTerm

      if (term === '') {
        return this.options
      } else {
        const termMatcher = new RegExp(term, 'i')

        return _.filter(this.options, function(option) {
          const locationMatch = option.locationName.match(termMatcher) != null
          const deviceMatch = option.deviceName.match(termMatcher) != null
          const nameMatch = option.name.match(termMatcher) != null

          return locationMatch || deviceMatch || nameMatch
        })
      }
    },
    appliedOptions() {
      return _.filter(this.templateGroup.templateApplications, function(option) {
        return !option._destroy
      })
    },
    optionCount() {
      return this.displayedOptions.length
    },
    appliedCount() {
      return this.appliedOptions.length
    },
  },
  methods: {
    idFor(option) {
      return 'option-' + option.templateableType + '-' + option.templateableId
    },
    isSelected(option) {
      const existingOption = _.findWhere(this.appliedOptions, option)
      return existingOption != null && !existingOption._destroy
    },
    toggleOption(option) {
      const existingOption = _.findWhere(this.templateGroup.templateApplications, option)

      if (existingOption) {
        if (existingOption.id) {
          existingOption._destroy = !existingOption._destroy
        } else {
          this.templateGroup.templateApplications.splice(this.templateGroup.templateApplications.indexOf(existingOption), 1)
        }
      } else {
        this.templateGroup.templateApplications.push(option)
      }
    },
  },
}
