import $ from 'jquery'
import _ from 'underscore'
import moment from '~/moment'

import {initVue} from '~/vue'
import AdjustmentUploader from '../../../components/calibrations/adjustment_uploader'
import ModalDialog from '../../../components/shared/modal_dialog'
import MonitoringPointIcon from '../../../components/shared/monitoring_point_icon'

import DatapointRequester from '../../../services/charts/datapoint_requester'

initVue('#vue--calibrations-build-adjustment', {
  components: {AdjustmentUploader, ModalDialog, MonitoringPointIcon},
  data() {
    return {
      readingsUrl: gon.readings_url,
      adjustments: gon.adjustments,
      currentAdjustment: null,
      calibrationPoints: 3,
      searchTerm: '',
    }
  },
  computed: {
    adjustmentsCount() {
      return this.adjustments.length
    },
    filteredAdjustments() {
      const search = this.searchTerm.toLowerCase()
      return _.filter(this.adjustments, (adjustment) => {
        const {device, location, serial} = adjustment
        const attrs = [adjustment.channel.name, device, location, serial]

        return _.any(attrs, (attr) => (attr || '').toLowerCase().includes(search))
      })
    },
    filteredAdjustmentsCount() {
      return this.filteredAdjustments.length
    },
    incomplete() {
      return _.any(this.adjustments, (adjustment) => {
        return adjustment.points ? !this.fullySupplied(adjustment) : true
      })
    },
  },
  methods: {
    partialReadings(adjustment) {
      return _.filter(adjustment.values, function(reading) {
        return reading.standard || reading.test
      })
    },
    fullySupplied(adjustment) {
      const supplied = _.filter(adjustment.values, function(reading) {
        return reading.standard && reading.test
      }).length
      const expectedPoints = adjustment.points || 5

      return supplied >= expectedPoints
    },
    captureReading(index) {
      $.ajax({
        url: `/channels/${this.currentAdjustment.channel.uuid}`,
        method: 'GET',
        success: (response) => {
          const timestamp = response.timestamp * 1000
          const requester = new DatapointRequester(this.readingsUrl, [response])

          requester.fetch(timestamp, timestamp, 1, (ch) => {
            const adjustment = this.currentAdjustment

            adjustment.values[index].test = ch.values()[0]
            adjustment.timestamps[index].test = moment(timestamp).toISOString()

            this.submit(adjustment)
          })
        },
      })
    },
    channelHighlight(adjustment) {
      return adjustment == this.currentAdjustment ? 'active' : ''
    },
    clearReading(index) {
      const adjustment = this.currentAdjustment

      adjustment.values[index].test = null
      adjustment.timestamps[index].test = null

      this.submit(adjustment)
    },
    formattedTimestamp(index) {
      const timestamp = this.timestampFor(index)
      if (!timestamp) {
        return '&nbsp;'
      }

      return moment(this.timestampFor(index)).format(momentDateTimeFormat)
    },
    timestampFor(index) {
      return this.currentAdjustment.timestamps[index].test
    },
    selectAdjustment(adjustment) {
      if (adjustment.points && this.partialReadings(adjustment).length !== 0) {
        this.calibrationPoints = adjustment.points
      } else {
        adjustment.points = this.calibrationPoints
      }

      this.currentAdjustment = adjustment
    },
    hasCalibrationPoints(count) {
      if (!this.calibrationPoints) {
        return false
      }
      return this.calibrationPoints >= count
    },
    submit(adjustment) {
      const values = _.first(adjustment.values, adjustment.points)
      const timestamps = _.first(adjustment.timestamps, adjustment.points)

      $.ajax({
        url: document.querySelector('form#adjustments-form').action,
        method: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify({
          build: {
            calibration_adjustments_attributes: [{
              id: adjustment.id,
              points: adjustment.points,
              reference_number: adjustment.reference_number,
              standard_readings: _.map(values, (reading, i) => ({value: reading.standard, timestamp: timestamps[i].standard})),
              test_readings: _.map(values, (reading, i) => ({value: reading.test, timestamp: timestamps[i].test})),
            }],
          },
        }),
      })
    },
  },
  watch: {
    calibrationPoints(points) {
      this.currentAdjustment.points = points
      this.submit(this.currentAdjustment)
    },
  },
})
