import {createGrid} from 'ag-grid-community'
import _ from 'underscore'

import {initVue} from '~/vue'
import Multiselect from 'vue-multiselect'

import DaterangeFilter from '../../components/shared/daterange_filter'
import MultiselectFilter from '../../components/shared/multiselect_filter'
import GridFilter from '../../components/mixins/grid_filter'

import UTCDateRenderer from '../../renderers/utc_date_renderer'
import TagsRenderer from '../../renderers/tags_renderer'

import postBulkDownload from '../../helpers/bulk_download'
import pluralize from '../../helpers/pluralize'

const certificatesFilters = initVue('#vue--certificates-index', {
  mixins: [GridFilter],
  components: {DaterangeFilter, Multiselect, MultiselectFilter},
  data() {
    return {
      appliedFilters: {
        calibrationDate: {},
        devices: [],
        locations: [],
        tags: [],
        sensors: [],
      },
      selectedFilters: gon.selected,
      options: {
        devices: _.uniq(_.pluck(gon.data, 'device_name')).sort(),
        locations: _.uniq(_.pluck(gon.data, 'location_name')).sort(),
        tags: _.uniq(_.flatten(_.pluck(gon.data, 'tags'))).sort(),
        sensors: _.uniq(_.pluck(gon.data, 'sensor_serial_number')).sort(),
      },
      filters() {
        return {
          calibrationDate: this.doesCalibrationDatePass,
          devices: 'device_name',
          locations: 'location_name',
          tags: this.doesTagsFilterPass,
          sensors: 'sensor_serial_number',
        }
      },
      bulkDownloadOpen: false,
      panelFilters: ['devices', 'locations', 'tags'],
      rowCount: gon.data.length,
    }
  },
  computed: {
    sidePanelAppliedFilters() {
      return this.appliedFilters.devices.concat(this.appliedFilters.locations, this.appliedFilters.tags)
    },
    sidePanelSelectedFilters() {
      return this.selectedFilters.devices.concat(this.selectedFilters.locations, this.selectedFilters.tags)
    },
  },
  methods: {
    bulkDownload() {
      this.closeBulkDownload()
      const selectedCertificates = this.grid.gridOptions.api.getSelectedRows()
      postBulkDownload(selectedCertificates, this.bulkDownloadSuccess)
    },
    bulkDownloadSuccess(data) {
      new FlashMessage(data.message, 'notice')
    },
    clearAllFilters() {
      this.appliedFilters.sensors = []
      this.clearCalibrationDate()
      this.clearPanelFilters()
      this.applyPanelFilters()
    },
    clearCalibrationDate() {
      this.appliedFilters.calibrationDate = {}
    },
    closeBulkDownload() {
      this.bulkDownloadOpen = false
    },
    doesCalibrationDatePass(row) {
      return this.doesDateRangeFilterPass(this.appliedFilters.calibrationDate, row.data.calibration_date)
    },
    doesTagsFilterPass(row) {
      return _.isEmpty(this.appliedFilters.tags) ||
        _.any(_.intersection(this.appliedFilters.tags, row.data.tags))
    },
    onSelectedRowFilteredOut(row) {
      row.setSelected(false)
    },
    openBulkDownload() {
      this.bulkDownloadOpen = true
    },
    pluralize,
  },
})

$('#vue--certificates-index').each(() => {
  const gridOptions = {
    pagination: true,
    paginationPageSize: gon.page_size,
    paginationPageSizeSelector: false,
    isExternalFilterPresent() {
      return certificatesFilters.isFilterPresent
    },
    doesExternalFilterPass(node) {
      return certificatesFilters.doesFilterPass(node)
    },
    rowStyle: {
      background: 'white',
    },
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    suppressColumnVirtualisation: true,
    domLayout: 'autoHeight',
    defaultColDef: {
      resizable: true,
      cellStyle: {
        'white-space': 'normal',
        'padding': '10px',
      },
      autoHeight: true,
      sortable: true,
      filter: false,
    },
    columnDefs:
      [
        {
          headerName: gon.labels['sensor_serial_number'],
          field: 'sensor_serial_number',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
        },
        {
          headerName: gon.labels['calibration_date'],
          field: 'calibration_date',
          cellRenderer: UTCDateRenderer,
        },
        {
          headerName: gon.labels['filename'],
          field: 'filename',
          cellRenderer: 'CertificateFileRenderer',
          maxWidth: 300,
        },
        {
          headerName: gon.labels['device_name'],
          field: 'device_name',
          cellRenderer: 'DeviceNameRenderer',
        },
        {
          headerName: gon.labels['location_name'],
          field: 'location_name',
          cellRenderer: 'LocationNameRenderer',
        },
        {
          headerName: gon.labels['tags'],
          field: 'tags',
          cellRenderer: TagsRenderer,
          suppressSizeToFit: true,
        },
      ],
    rowData: gon.data,
    localeText: {
      noRowsToShow: 'No certificates to display at this time',
    },
    onGridReady(event) {
      event.api.autoSizeAllColumns()
      event.api.setGridAriaProperty('label', gon.labels['grid_name'])
    },
    onSelectionChanged: certificatesFilters.onSelectionChanged,
    components: {
      CertificateFileRenderer(params) {
        return `<a href='${params.data.certificate_path}' class='pill-link'><i class='material-icons' aria-hidden='true'>download</i> ${gon.labels['download_pdf']}</a>`
      },
      DeviceNameRenderer(params) {
        return `<a href='${params.data.device_path}' class='pill-link'>${params.value}</a>`
      },
      LocationNameRenderer(params) {
        return `<a href='${params.data.location_path}' class='pill-link'>${params.value}</a>`
      },
    },
  }

  certificatesFilters.grid = createGrid(document.querySelector('#grid--certificates-index'), gridOptions)
  certificatesFilters.applyPanelFilters()
})
