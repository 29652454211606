import _ from 'underscore'

export default class Channel {
  constructor(attrs) {
    if (attrs == null) {
      attrs = {}
    }

    this.id = attrs.id
    this.defaultName = attrs.default_name

    if (attrs.name !== this.defaultName) {
      this.customName = attrs.name
    }

    this.templateGroups = attrs.template_groups ? attrs.template_groups : []
    this.type = attrs.type
  }

  serialize() {
    return {
      id: this.id,
      name: this.customName || this.defaultName,
      template_group_ids: _.pluck(this.templateGroups, 'id'),
    }
  }

  nameIsEditable() {
    return this.type !== 'Channel::Battery'
  }

  get name() {
    return this.customName || this.defaultName
  }
}
