import $ from 'jquery'
import _ from 'underscore'

import {initVue} from '~/vue'
import ModalDialog from '../../components/shared/modal_dialog'
import UnassignedDeviceForm from '../../components/rf_devices/unassigned_device_form'

import FlashMessage from '../../services/flash_message'
import Device from '../../services/wrappers/device'
import LocationTree from '../../services/wrappers/location_tree'

initVue('#vue--rf_devices-index', {
  components: {ModalDialog, UnassignedDeviceForm},
  data() {
    const tree = new LocationTree(gon.locations)

    return {
      deviceLimit: gon.device_limit,
      deviceCount: gon.device_count,
      devices: _.map(gon.devices, (device) => new Device(device)),
      recentDevices: _.map(gon.recent_devices, (device) => new Device(device)),
      templateOptions: gon.templates,
      locationOptions: tree.editableLocations(),
      activeComponent: null,
      hasClaimableDevices: !!gon.claimable,
      replacementDevices: gon.replacement_devices,
      registrationCodes: '',
      claimError: false,
    }
  },
  mounted() {
    $('#js--location-modal').on('ajax:success', (_, response) => {
      this.addLocation(response)
      $('#js--location-modal').modal('toggle')
    })
  },
  computed: {
    reachedDeviceLimit() {
      if (!this.deviceLimit) {
        return false
      } else {
        return this.deviceCount >= this.deviceLimit
      }
    },
  },
  methods: {
    addLocation(location) {
      const index = _.findIndex(this.locationOptions, function(loc) {
        return loc.id === location.parent_id
      })
      this.locationOptions.splice(index + 1, 0, location)
      this.activeComponent.device.locationId = location.id
    },
    markActiveComponent(component) {
      this.activeComponent = component
    },
    registerDevice(deviceMeta) {
      const index = _.findIndex(this.devices, function(d) {
        return d.id === deviceMeta.id
      })
      const device = this.devices.splice(index, 1)[0]

      new FlashMessage('Your device was successfully registered!', 'notice')

      this.recentDevices.push(device)
      this.deviceCount += 1
    },
    claimDevices() {
      this.claimError = false
      const codes = this.registrationCodes.replace(/[^0-9]+/g, ' ').split(' ')

      $.ajax({
        url: '/rf_devices',
        method: 'POST',
        data: {rf_device: {registration_code: codes}},
        success: this.handleClaimedDevices,
      })
    },
    handleClaimedDevices(response) {
      _.each(response.devices, (device) => {
        this.devices.push(new Device(device))
      })

      this.hasClaimableDevices = !!response.claimable
      this.registrationCodes = ''

      if (response.devices.length > 0) {
        $('#claim-devices').modal('hide')

        const firstId = response.devices[0].id
        setTimeout(function() {
          const element = document.getElementById(firstId)
          element.scrollIntoView({behavior: 'smooth', block: 'center'})
          element.classList.add('highlighted')
        }, 300)
      } else {
        this.claimError = true
      }
    },
  },
})
