import {createGrid} from 'ag-grid-community'
import $ from 'jquery'
import _ from 'underscore'

import {initVue} from '~/vue'
import DaterangeFilter from '../../components/shared/daterange_filter'
import GridFilter from '../../components/mixins/grid_filter'
import MultiselectFilter from '../../components/shared/multiselect_filter'

import DateRenderer from '../../renderers/date_renderer'
import DateTimeRenderer from '../../renderers/date_time_renderer'

const RADIO_VALUES_TO_RANGES = {
  in_6_months: {start: [6, 'months'], end: [0, 'seconds']},
  in_12_months: {start: [1, 'year'], end: [0, 'seconds']},
  more_than_12_months: {end: [1, 'year']},
}
const DUE_DATE_RANGES = {
  overdue: {end: 0},
  due: {start: 0, end: 30},
  upcoming: {start: 30, end: 90},
  calibrated: {start: 90},
}

const sensorFilters = initVue('#vue--sensors-index', {
  components: {DaterangeFilter, MultiselectFilter},
  mixins: [GridFilter],
  data() {
    return {
      appliedFilters: {
        calibrationDueDate: {},
        serial: [],
        sku: [],
        dataLogger: [],
        location: [],
        channels: [],
        equipment: [],
        lastReported: {},
        connected: gon.currently_connected,
      },
      selectedFilters: {
        serial: [],
        sku: [],
        dataLogger: [],
        location: [],
        channels: [],
        equipment: [],
        lastReported: {},
        connected: gon.currently_connected,
      },
      options: {
        serial: _.chain(gon.data).pluck('serial').uniq().sort().value(),
        sku: _.chain(gon.data).pluck('sku').uniq().sort().value(),
        dataLogger: _.chain(gon.data).map((l) => l.data_logger.name).uniq().sort().value(),
        location: _.chain(gon.data).map((l) => l.location.name).uniq().sort().value(),
        channels: _.chain(gon.data).pluck('channels').flatten().uniq().sort().value(),
        equipment: _.chain(gon.data).pluck('equipment').flatten().uniq().sort().value(),
      },
      panelFilters: ['serial', 'sku', 'dataLogger', 'location', 'channels', 'equipment', 'lastReported', 'connected'],
      filters() {
        return {
          calibrationDueDate: this.doesCalibrationDueDatePass,
          serial: 'serial',
          sku: 'sku',
          dataLogger: (row) => {
            return _.isEmpty(this.appliedFilters.dataLogger) ||
              this.appliedFilters.dataLogger.includes(row.data.data_logger.name)
          },
          location: (row) => {
            return _.isEmpty(this.appliedFilters.location) ||
              this.appliedFilters.location.includes(row.data.location.name)
          },
          channels: (row) => {
            return _.isEmpty(this.appliedFilters.channels) ||
              _.any(_.intersection(this.appliedFilters.channels, row.data.channels))
          },
          equipment: (row) => {
            return _.isEmpty(this.appliedFilters.equipment) ||
              _.any(_.intersection(this.appliedFilters.equipment, row.data.equipment))
          },
          lastReported: this.doesLastReportedPass,
          connected: 'connected',
        }
      },
      customFilterPills() {
        return {
          connected: {
            label: [gon.labels['currently_connected'], this.appliedFilters.connected].join(' '),
            onClose: this.clearConnected,
          },
          lastReported: {
            label: [gon.labels['last_reported'], this.lastReportedToString].join(': '),
            onClose: this.clearLastReported,
          },
        }
      },
      calibrationDueDateRadio: gon.calibration,
      lastReportedRadio: '',
      rowCount: gon.data.length,
    }
  },
  mounted() {
    this.selectCalibrationRange(this.calibrationDueDateRadio)
  },
  watch: {
    calibrationDueDateRadio(newValue) {
      this.selectCalibrationRange(newValue)
    },
    lastReportedRadio(newValue) {
      this.selectLastReportedRange(newValue)
    },
  },
  computed: {
    lastReportedToString() {
      if (this.appliedFilters.lastReported.start || this.appliedFilters.lastReported.end) {
        return $('[name=last_reported]:checked').parent().text().trim()
      }
    },
  },
  methods: {
    clearAllFilters() {
      this.calibrationDueDateRadio = ''
      this.clearDateRanges()
      this.clearPanelFilters()
      this.applyPanelFilters()
    },
    clearConnected() {
      this.selectedFilters.connected = ''
      this.appliedFilters.connected = ''
      this.updateFilters()
    },
    clearDateRanges() {
      this.appliedFilters.calibrationDueDate = {}
    },
    clearLastReported() {
      this.lastReportedRadio = ''
      this.appliedFilters.lastReported = {}
      this.updateFilters()
    },
    doesCalibrationDueDatePass(row) {
      return this.doesDateRangeFilterPass(this.appliedFilters.calibrationDueDate, row.data.calibration_due_date)
    },
    doesLastReportedPass(row) {
      if (_.isEmpty(this.appliedFilters.lastReported)) return true

      const rowValue = row.data.last_reported
      const {start, end} = this.appliedFilters.lastReported
      return (start == null || start.isBefore(rowValue)) && (end == null || end.isAfter(rowValue))
    },
    clearCalibrationDueDate(updateFilters) {
      this.appliedFilters.calibrationDueDate = {}
      if (updateFilters) {
        this.updateFilters()
      }
    },
    onPanelFiltersCleared() {
      this.lastReportedRadio = ''
      this.selectedFilters.connected = ''
    },
    setCalibrationDueDateRange(start, end) {
      this.appliedFilters.calibrationDueDate = {start, end}
      this.updateFilters()
    },
    selectLastReportedRange(value) {
      const range = RADIO_VALUES_TO_RANGES[value]

      if (range) {
        const start = range.start != null ? moment.utc().subtract(...range.start) : null
        const end = range.end != null ? moment.utc().subtract(...range.end) : null

        this.selectedFilters.lastReported = {start, end}
      } else {
        this.selectedFilters.lastReported = {}
      }
    },
    selectCalibrationRange(value) {
      const range = DUE_DATE_RANGES[value]

      if (range) {
        const startDate = range.start != null ? moment.utc().add(range.start, 'days').toDate() : null
        const endDate = range.end != null ? moment.utc().add(range.end, 'days').toDate() : null

        this.setCalibrationDueDateRange(startDate, endDate)
      } else {
        this.clearCalibrationDueDate(true)
      }
    },
  },
})

const HyperlinkComparator = (valueA, valueB) => valueA.name.localeCompare(valueB.name)

$('#vue--sensors-index').each(() => {
  const gridOptions = {
    pagination: true,
    paginationPageSize: gon.page_size,
    paginationPageSizeSelector: false,
    isExternalFilterPresent() {
      return sensorFilters.isFilterPresent
    },
    doesExternalFilterPass(node) {
      return sensorFilters.doesFilterPass(node)
    },
    rowStyle: {background: 'white'},
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    suppressColumnVirtualisation: true,
    domLayout: 'autoHeight',
    defaultColDef: {
      resizable: true,
      cellStyle: {
        'white-space': 'normal',
        'padding': '10px',
      },
      autoHeight: true,
      sortable: true,
      filter: false,
      maxWidth: 200,
    },
    columnDefs: [
      {
        headerName: gon.labels['serial'],
        field: 'serial',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        pinned: 'left',
      },
      {
        headerName: gon.labels['sku'],
        field: 'sku',
      },
      {
        headerName: gon.labels['data_logger'],
        field: 'data_logger',
        cellRenderer: 'HyperlinkRenderer',
        comparator: HyperlinkComparator,
      },
      {
        headerName: gon.labels['location'],
        field: 'location',
        cellRenderer: 'HyperlinkRenderer',
        comparator: HyperlinkComparator,
      },
      {
        headerName: gon.labels['port'],
        field: 'port',
      },
      {
        headerName: gon.labels['channels'],
        field: 'channels',
      },
      {
        headerName: gon.labels['calibration_interval'],
        field: 'calibration_interval',
      },
      {
        headerName: gon.labels['last_calibration'],
        field: 'last_calibration',
        cellRenderer: DateRenderer,
      },
      {
        headerName: gon.labels['calibration_due_date'],
        field: 'calibration_due_date',
        cellRenderer: DateRenderer,
      },
      {
        headerName: gon.labels['last_calibration_certificate'],
        field: 'last_calibration_certificate',
        cellRenderer: 'HyperlinkRenderer',
      },
      {
        headerName: gon.labels['last_reported'],
        field: 'last_reported',
        cellRenderer: DateTimeRenderer,
      },
      {
        headerName: gon.labels['currently_connected'],
        field: 'connected',
      },
      {
        headerName: gon.labels['equipment'],
        field: 'equipment',
      },
    ],
    rowData: gon.data,
    localeText: {
      noRowsToShow: 'No sensors to display at this time',
    },
    onGridReady(event) {
      event.api.autoSizeAllColumns()
      event.api.setGridAriaProperty('label', gon.labels['calibrations_table'])
    },
    onCellKeyDown({column, node: {data}, event: {key}}) {
      const colId = column.colId
      if (data[colId]?.path && key === 'Enter') {
        location.href = data[colId].path
      }
    },
    components: {
      HyperlinkRenderer(params) {
        if (params.value.path) {
          return `\
            <a
              class='pill-link'
              target='${params.value.target ? '_blank': '_self'}'
              href='${params.value.path}'
            >${params.value.name}</a>\
          `
        } else if (params.value.name) {
          return params.value.name
        } else {
          return '-'
        }
      },
    },
  }

  sensorFilters.grid = createGrid(document.querySelector('#grid--sensors-index'), gridOptions)
  sensorFilters.updateFilters()
})
