import _ from 'underscore'
import AlarmColor from '../../services/alarm_color'

import AlertScheduleSummary from '../alert_schedules/alert_schedule_summary'
import EscalationPolicyDetails from '../escalation_policies/escalation_policy_details'

/**
The `template-row` component describes a mechanism for rendering identical
alarms for multiple channels all at once, grouped together by their base
template.

@name template-row
@prop {object} group
  The serialized TemplateGroup object that includes this template and the
  related alarms.
@prop {object} template
  The serialized AlarmTemplate object, containing at least the display condition
  and record ID.
@prop {object} [colors=`new AlarmColor()`]
  An instance of AlarmColor, typically injected via a parent component to get
  consistent colors applied to the alarms on the page.
@prop {boolean} [editable=`false`]
  A boolean indicating whether or not to render action triggers.

@template components/alarm_templates/template_row
@design https://zpl.io/1yW7oD5

@wrapper
  <div class="alarm-template-container card with-shadow">{example}</div>
@example
  {
    "group": {
      "alarms": [
        {"alarmTemplateId": 1, "channelName": "Channel 1", "paused": true, "device": {"name": "Device 1"}},
        {"alarmTemplateId": 1, "channelName": "Channel 2", "paused": false, "device": {"name": "Device 2"}}
      ]
    },
    "template": {
      "id": 1,
      "condition": "Temperature is above 10°F"
    },
    "editable": true
  }
*/

const TemplateRow = {
  name: 'template-row',
  template: '#template-row-template',
  components: {AlertScheduleSummary, EscalationPolicyDetails},
  props: {
    group: {
      type: Object,
      required: true,
    },
    template: {
      type: Object,
      required: true,
    },
    colors: {
      type: Object,
      required: false,
      default() {
        return new AlarmColor()
      },
    },
  },
  computed: {
    device() {
      return {supportsHardwareAlerts: true}
    },
    appliedAlarms() {
      return _.filter(this.group.alarms, (alarm) => alarm.alarmTemplateId === this.template.id)
    },
    highlightStyle() {
      const color = this.colors.colorFor(this.template)
      return `background-color: ${color.background}; border: 1px solid ${color.border};`
    },
  },
}

export default TemplateRow
