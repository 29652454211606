import {initVue} from '~/vue'
import initElement from '../../../helpers/init_element'
import Multiselect from 'vue-multiselect'

const formId = '#vue--admin-release_logs-form'

initElement(formId, () => {
  // NOTE: When Vue attempts to mount, this input is temporarily removed from
  // the page, so we need to grab its value prior to the mount.
  const application = document.querySelector('[name="release_log[application]"]').value

  initVue(formId, {
    components: {Multiselect},
    data() {
      return {
        application,
        availableSkus: gon.available_skus,
        selectedSkus: gon.selected_skus,
      }
    },
    computed: {
      isFirmware() {
        return this.application === 'firmware'
      },
    },
    methods: {
      addSku(sku) {
        this.availableSkus.push(sku)
        this.selectedSkus.push(sku)
      },
    },
  })
})
