import pluralize from '../../helpers/pluralize'

export default {
  name: 'time-delay',
  template: '#time-delay-template',
  props: ['delayInterval', 'device', 'notificationStyle', 'formStyle'],
  computed: {
    display() {
      return this.delayInterval !== ''
    },
    timeDelay() {
      if (this.delaySeconds >= 60) {
        const minutes = Math.round(this.delaySeconds / 60)
        return pluralize({'one': '1 minute', 'other': '%{count} minutes'}, minutes)
      } else {
        return pluralize({'one': '1 second', 'other': '%{count} seconds'}, this.delaySeconds)
      }
    },
    delaySeconds() {
      if (this.delayInterval) {
        return this.device.sampleInterval * this.delayInterval + this.device.alarmDelayBuffer
      } else {
        return 0
      }
    },
    timeDelayNotificationStyle() {
      if (this.timeDelay === '0 seconds') {
        return 'Immediately'
      } else {
        return 'after ' + this.timeDelay
      }
    },
  },
}
