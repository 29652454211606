import $ from 'jquery'

export default class PreferenceToggle {
  // Public: Initialize a new PreferenceToggle.
  //
  // $el - The toggle element.
  constructor($el) {
    this.update = this.update.bind(this)
    this.refresh = this.refresh.bind(this)
    this.$el = $el
    this._options = this.$el.data()
    this._isVisible = this._options.isVisible
    this._self = $(this)

    this._registerListeners()
    this.refresh()
  }

  // Public: Handle a successful response from the server, updating the
  // visibility of the associated timestamp elements and changing the toggle
  // text.
  //
  // data - An Object containing the data returned by the server.
  //
  // Returns nothing.
  update(data) {
    this._isVisible = this._toBoolean(data.preferences[this._options.preference])
    this.refresh()
  }

  // Public: Bind an event handler with jQuery.
  //
  // Returns nothing.
  bind(eventType, handler) {
    this._self.on(eventType, handler)
  }

  // Public: Get preference data for posting back to the server.
  //
  // Returns an Object.
  postData() {
    return {
      preference: this._options.preference,
      value: !this._isVisible,
    }
  }

  // Public: Update the view to reflect the current toggle state.
  //
  // Returns nothing.
  refresh() {
    $(this._options.target).toggleClass('hide', !this._isVisible)
    this.$el.text(this._displayText())
  }

  // Internal: Add a click handler to the toggle element. Clicking the element
  // will trigger a click event on the toggle object.
  //
  // Returns nothing.
  _registerListeners() {
    return this.$el.click((e) => {
      e.preventDefault()
      this._self.triggerHandler('click')
    })
  }

  // Internal: Get the link text for display.
  //
  // Returns a String.
  _displayText() {
    return this._isVisible ? `Hide ${this._options.name}` : `Show ${this._options.name}`
  }

  // Internal: Get the Boolean form of the passed String object.
  //
  // string - A String, either "true" or "false".
  //
  // Returns a Boolean.
  _toBoolean(string) {
    return string !== 'false'
  }
}
