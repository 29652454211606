const UserRoleListItem = {
  name: 'user-role-list-item',
  template: '#user-role-list-item-template',
  props: {
    role: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
    }
  },
  methods: {
    triggerDestroy() {
      this.$emit('destroy', this.role)
    },
    toggleEditMode() {
      this.editMode = !this.editMode
    },
    submit() {
      if (!this.role.validate()) {
        return
      }
      this.$emit('submit', this.role)
      this.editMode = false
    },
  },
}

export default UserRoleListItem
