import _ from 'underscore'
import pluralize from '../../helpers/pluralize'

import NotificationGroupForm from './notification_group_form'
import TimeDelay from './time_delay'

import NotificationGroup from '../../services/wrappers/notification_group'

export default {
  name: 'notification-policy-form',
  template: '#notification-policy-form-template',
  components: {NotificationGroupForm, TimeDelay},
  props: {
    policyObject: {
      type: Object,
      required: true,
    },
    device: {
      type: Object,
      required: false,
    },
  },
  computed: {
    allowHardwareAlert() {
      if (this.device) {
        return this.device.supportsHardwareAlerts && !this.policyObject.soundProhibited
      } else {
        return !this.policyObject.soundProhibited
      }
    },
    allowAddNotificationGroup() {
      return this.policyObject.allowAddNotificationGroup()
    },
    disableWarning() {
      return this.policyObject.hardwareDelayInterval > this.minimumExcursionInterval
    },
    minimumExcursionInterval() {
      const excursionGroups = _.filter(this.policyObject.notificationGroups, (ng) => ng.excursion)
      return _.min(_.pluck(excursionGroups, 'delayInterval'))
    },
  },
  methods: {
    addNotificationGroup() {
      if (this.allowAddNotificationGroup) {
        const excursion = this.minimumExcursionInterval == 0 || _.isEmpty(this.policyObject.notificationGroups)
        this.policyObject.notificationGroups.push(new NotificationGroup({excursion}))
      }
    },
    idFor(prefix) {
      return `${prefix}-${this.policyObject.id || 'new'}`
    },
    updateHardwareExcursionToggle() {
      if (this.policyObject.hardwareDelayInterval > this.minimumExcursionInterval) {
        this.policyObject.hardwareExcursion = true
      }
    },
    pluralize,
  },
}
