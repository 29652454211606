import $ from 'jquery'
import _ from 'underscore'
import AjaxModal from '../ajax_modal'

export default class AnnotationSummarizer {
  constructor(charts) {
    this.update = this.update.bind(this)
    this._appendAnnotationSummaries = this._appendAnnotationSummaries.bind(this)
    this._appendAnnotationFlags = this._appendAnnotationFlags.bind(this)
    this._removeAnnotationFlags = this._removeAnnotationFlags.bind(this)
    this._removeAnnotationSummaries = this._removeAnnotationSummaries.bind(this)
    this._launchModal = this._launchModal.bind(this)
    this._launchAnnotationModal = this._launchAnnotationModal.bind(this)
    this._launchAlarmModal = this._launchAlarmModal.bind(this)
    this._updateCommentCount = this._updateCommentCount.bind(this)

    this.charts = charts
    if (!this._annotationSummaries) {
      this._annotationSummaries = []
    }
    this._annotationModalTemplate = _.template($('.js--annotation-modal-tpl').html())
    this._alarmEventsModal = new AjaxModal({
      modalId: 'alarm-events',
      formSuccess: this._updateCommentCount,
    })
    this.init()
  }

  init() {
    $('body')
        .on('ajax:success', '.js--acknowledge-button', {summarizer: this}, function(evt) {
          $(this).siblings('a.js--alarm-comment').removeClass('hide')
          $(this).remove()

          const alarmEvent = evt.data.summarizer._findAlarmEvent(evt.target.dataset.id)
          alarmEvent.isAcknowledged = true
        })
        .on('ajax:error', '.js--acknowledge-button', (evt, xhr) => new FlashMessage(xhr.responseText))

    _.each(this.charts, (chart) => {
      chart.on('click', (_event, data) => {
        this._launchModal({
          at: data.point.x,
          channel: (data.point.options.extra != null ? data.point.options.extra.channel : undefined) || (data.point.series.options.extra != null ? data.point.series.options.extra.channel : undefined),
          content: data.point.text,
          id: (data.point.options.extra != null ? data.point.options.extra.id : undefined),
          type: (data.point.options.extra != null ? data.point.options.extra.type : undefined),
        })
      })
    })

    $('.js--annotation-summary').on('click', '.js--annotation-edit', (event) => {
      const $el = $(event.currentTarget)
      this._launchAnnotationModal({
        at: $el.data('at'),
        channel: $el.data('channel'),
        content: $el.data('content'),
        id: $el.data('id'),
      })
    })

    $('.js--annotation-summary').on('click', '.js--alarm-comment', (event) => {
      const $el = $(event.currentTarget)
      this._launchAlarmModal({id: $el.data('id')})
    })
  }

  update(minTs, maxTs) {
    $.ajax({
      url: `${gon.base_url}/annotations.json`,
      data: {
        min_ts: minTs / 1000,
        max_ts: maxTs / 1000,
      },
      success: (data) => {
        this._annotationSummaries = []
        this._appendAnnotationSummaries(data.summary)
        this._appendAnnotationFlags(data.flags)
      },
    })
  }

  _appendAnnotationSummaries(summaries) {
    this._annotationSummaries = this._annotationSummaries.concat(summaries)
    this._annotationSummaries = _.sortBy(this._annotationSummaries, (s) => -s.ts)

    const template = _.template($('.js--annotation-summary-tpl').html())
    const html = template({annotations: this._annotationSummaries})
    $('.js--annotation-summary').html(html)
  }

  _appendAnnotationFlags(flags) {
    _.each(this.charts, (chart) => chart.addFlags(flags))
  }

  _removeAnnotationFlags(flags) {
    _.each(this.charts, (chart) => chart.removeFlags(flags))
  }

  _removeAnnotationSummaries(summaries) {
    for (const summary of summaries) {
      $(`.annotation[data-id='${summary.type}-${summary.id}']`).remove()
      this._annotationSummaries = _.reject(this._annotationSummaries, (s) => s.id === summary.id)
    }
  }

  // Internal: Find the alarm event for the passed id
  //
  // alarmEventId - id of the alarmEvent
  //
  // Returns an Object or undefined.
  _findAlarmEvent(alarmEventId) {
    return this._annotationSummaries.find(({id, type}) => {
      return type === 'alarmEvent' && id == alarmEventId
    })
  }

  _launchModal(annotation) {
    if (annotation.type === 'alarmEvent') {
      this._launchAlarmModal(annotation)
    } else {
      this._launchAnnotationModal(annotation)
    }
  }

  _launchAnnotationModal(annotation) {
    const $modal = $(this._annotationModalTemplate({annotation}))
    $modal
        .on('hidden.bs.modal', () => $(this).remove())
        .on('ajax:success', 'form', (event, data) => {
          $modal.modal('hide')
          if (data.action !== 'create') {
            this._removeAnnotationFlags(data.flag)
            this._removeAnnotationSummaries(data.summary)
          }
          if (data.action !== 'destroy') {
            this._appendAnnotationFlags(data.flag)
            this._appendAnnotationSummaries(data.summary)
          }
        })
        .modal('show')
  }

  _launchAlarmModal(annotation) {
    this._alarmEventsModal.triggerModal(`/alerts/${annotation.id}`)
  }

  _updateCommentCount(modal, text) {
    const json = JSON.parse(text)
    const $row = $(`[data-id='alarmEvent-${json.commentable_id}']`)

    $row.find('a.js--alarm-comment').removeClass('hide')
    $row.find('.js--acknowledge-button').remove()
    $row
        .find('.js--comment-count')
        .text(json.comment_count)

    const alarmEvent = this._findAlarmEvent(json.commentable_id)
    alarmEvent.isAcknowledged = true
    alarmEvent.commentCount = json.comment_count

    modal.closeModal()
  }
}
