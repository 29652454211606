import $ from 'jquery'

export default class AlertMessage {
  constructor(el, keepMessage) {
    this.performPostRequest = this.performPostRequest.bind(this)
    this.$elems = $(el)

    if (!keepMessage) {
      this.delaySlideUp()
    }

    this.registerListeners()
  }

  registerListeners() {
    this.$elems.on('click', 'a.close', (e) => {
      const $elem = $(e.delegateTarget)
      const ajaxOptions = $elem.data('ajax')

      if (ajaxOptions) {
        this.performPostRequest($elem, ajaxOptions)
      } else {
        this.slideUp($elem)
      }

      return $(e.currentTarget).attr('href') !== '#'
    })
  }

  performPostRequest($elem, options) {
    $.ajax({
      url: options.url,
      method: 'POST',
      data: this.formatData(options.data),
      success: () => {
        this.slideUp($elem)
      },
      error() {
        alert(options.error_message)
      },
    })
  }

  formatData(data) {
    if (data.value === '{current_time}') {
      data.value = (new Date).toJSON()
    }

    return data
  }

  slideUp($elem) {
    $elem.stop().fadeThenSlideUp()
  }

  delaySlideUp() {
    this.$elems.filter(':not(\'.persistent\')').delay(5000).fadeThenSlideUp()
  }
}
