/**
 * The `flash-alert` component defines a component that can be used to display an
 * alert message to the user.
 *
 * @name flash-alert
 * @prop {String} title The title of the alert
 * @prop {String} message The message of the alert
 * @prop {Boolean} [dismissible=`false`] Whether the alert can be closed by the user
 * @prop {String} [type=`'notice'`] The type of alert, can be 'notice', 'warning', or 'error'
 * @prop {Number} [timeout=`5000`] The number of milliseconds to wait before automatically closing the alert. Set to `0` to disable.
 *
 * @template components/alerts/flash_alert
 * @design https://zpl.io/O0g6wGk
 *
 * @example
 *   {
 *      "title": "Dismissible Notice Title",
 *      "message": "Dismissible notice message",
 *      "dismissible":true,
 *      "type": "notice",
 *      "timeout": 0
 *   }
 *
 * @example
 *   {
 *      "title": "Transient Warning Title",
 *      "message": "Transient Warning message",
 *      "dismissible":true,
 *      "type": "warning"
 *   }
 *
 * @example
 *   {
 *      "title": "Persistent Error Title",
 *      "message": "Persistent Error message",
 *      "type": "error",
 *      "timeout": 0
 *   }
 *
 */

const FlashAlert = {
  name: 'flash-alert',
  template: '#flash-alert-template',
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'notice',
    },
    timeout: {
      type: Number,
      default: 5000,
    },
  },
  data() {
    return {
      icon: this.getIcon(),
      fadeOut: false,
      visible: true,
    }
  },
  mounted() {
    if (this.timeout > 0) {
      setTimeout(() => {
        this.fadeOut = true
      }, this.timeout)
    }
  },
  watch: {
    fadeOut(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.visible = false
        }, 500)
      }
    },
  },
  methods: {
    handleClose() {
      this.visible = false
    },
    getIcon() {
      switch (this.type) {
        case 'warning':
          return 'warning'
        case 'error':
          return 'error'
        default:
          return 'info'
      }
    },
  },
}

export default FlashAlert
