import AlertPin from './alert_pin'
import DraggablePin from './draggable_pin'
import AjaxModal from '../../services/ajax_modal'

const FloorplanArea = {
  template: '#floorplan-area-template',
  components: {AlertPin, DraggablePin},
  props: {
    floorplan: {
      type: Object,
      required: true,
    },
    pins: {
      type: Array,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      alertModal: new AjaxModal({modalId: 'alarm-events'}),
    }
  },
  computed: {
    orderedPins() {
      return this.pins.sort((a, b) => {
        if (a.position.top === b.position.top) {
          return a.position.left - b.position.left
        }

        return a.position.top - b.position.top
      })
    },
  },
  methods: {
    onDragover(event) {
      if (this.editable) {
        event.preventDefault()
      }
    },
  },
}

export default FloorplanArea
