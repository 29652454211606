export default {
  name: 'location-permission-row',
  template: '#location-permission-row-template',
  props: {
    location: {
      type: Object,
      required: true,
    },
    locationTree: {
      type: Object,
      required: true,
    },
  },
  computed: {
    nestedLocations() {
      return this.locationTree.tree(this.location)
    },
    parentLocation() {
      return this.locationTree.parentLocation(this.location)
    },
    parentPermission() {
      return this.parentLocation ? this.parentLocation.permission : 0
    },
    style() {
      const padding = this.location.level * 20 + 8
      return {
        'padding-left': `${padding}px`,
      }
    },
  },
  methods: {
    idFor(prefix) {
      return `${prefix}-${this.location.id}`
    },
    updateChildPermissions() {
      const permission = this.location.permission

      this.nestedLocations.forEach((child) => {
        if (child.permission < permission) {
          child.permission = permission
        }
      })
    },
  },
}
