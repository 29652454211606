import DatapointRequester from '~/services/charts/datapoint_requester'
import NumberFormatter from '~/services/number_formatter'

import Alert from '~/components/mixins/alert'

/**
 * The `alert-row` component defines a component that can be used to display
 * details about a triggered alert to the user.
 *
 * @name alert-row
 * @prop {Object} alert The alert object.
 *
 * @template components/alerts/alert_row
 * @design https://zpl.io/GExGNZE
 *
 * @example
 *   {
 *     "alert": {
 *       "id": 1,
 *       "condition": "Data Logger not reporting",
 *       "acknowledged_at": "2023-11-20T10:41:53.000Z",
 *       "acknowledger_id": 2,
 *       "escalation_started_at": "2023-08-09T18:02:32.000Z",
 *       "excursion_at": "2023-08-09T18:02:32.000Z",
 *       "returned_to_normal_at": null,
 *       "equipment": [
 *         {
 *           "id": 1,
 *           "name": "Ambient"
 *         }
 *       ],
 *       "device": {
 *         "id": 1,
 *         "name": "Chiller",
 *         "token": "791196350796058499"
 *       }
 *     }
 *   }
 */

const AlertRow = {
  name: 'alert-row',
  template: '#alert-row-template',
  mixins: [Alert],
  emits: ['select', 'show'],
  props: {
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      channel: this.alert.channel,
      requester: new DatapointRequester(gon.dps_url, [this.alert.channel]),
      formatter: new NumberFormatter(),
    }
  },
  methods: {
    show(event) {
      if (event.target.nodeName == 'A') {
        return
      }

      this.$emit('show', this.alert)
    },
  },
}

export default AlertRow
