import $ from 'jquery'
import initElement from '../../helpers/init_element'
import initTabs from '../../helpers/tabs'

const CONDITIONS = [
  // Input Selector                              Value     Target Selector
  ['[name=\'account[password_security_option]\']', 'custom', '[name*=\'account[strong_password_parameters]\']'],
  ['[name=\'account[user_passwords_expire]\']', 'true', '#account_password_expiration_in_days'],
  ['[name=\'account[user_passwords_reuse]\']', 'false', '#account_number_last_passwords'],
  ['[name=\'account[user_passwords_lockout]\']', 'true', '#account_consecutive_failed_logins_limit,#account_failed_login_ban_for_hours'],
  ['[name=\'account[user_sessions_timeout]\']', 'true', '#account_minutes_to_timeout'],
]

class AccountForm {
  constructor($form) {
    this._toggle = this._toggle.bind(this)
    this._toggleFor = this._toggleFor.bind(this)
    this.$form = $form

    for (const condition of CONDITIONS) {
      this._toggleFor(...condition)
    }

    this.$form.on('change', '[name=\'account[password_security_option]\']', (e) => {
      const showMinLength = $('#account_strong_password_parameters_min_number_characters').is(':checked')
      this._toggle('#account_minimum_password_length', (e.currentTarget.value === 'custom') && showMinLength)
    })

    this.$form.on('change', '#account_strong_password_parameters_min_number_characters', (e) => {
      this._toggle('#account_minimum_password_length', $(e.currentTarget).is(':checked'))
    })
  }

  _toggle(selector, enabled) {
    this.$form.find(selector)
        .toggleClass('disabled', !enabled).prop('disabled', !enabled)
        .parent('label').toggleClass('disabled', !enabled)
  }

  _toggleFor(input, value, target) {
    this.$form.on('change', input, (e) => {
      this._toggle(target, (e.currentTarget.value === value))
    })
  }
}

initElement('#js--accounts-edit', ($el) => {
  new AccountForm($($el))
  initTabs('#account-settings')
})
