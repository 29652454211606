import _ from 'underscore'
import {initVue} from '~/vue'

import FlashMessage from '../../services/flash_message'
import FloorplanArea from '../../components/floorplans/floorplan_area'
import ModalDialog from '../../components/shared/modal_dialog'
import NestedSelect from '../../components/shared/nested_select'
import SearchDropdown from '../../components/shared/search_dropdown'

initVue('#vue--floorplans-edit', {
  components: {ModalDialog, FloorplanArea, NestedSelect, SearchDropdown},
  data() {
    return {
      monitoringPoints: {
        'Device': gon.devices,
        'Equipment': gon.equipment,
        'Location': gon.locations,
      },
      locations: gon.locations,
      floorplans: gon.floorplans,
      currentIndex: gon.current_index,
      fallbackLocation: gon.fallback_location,
      validationErrors: {},
    }
  },
  computed: {
    currentEditPath() {
      const query = encodeURIComponent(window.location.search)
      return `${this.currentFloorplan.path}/edit${query}`
    },
    currentFloorplan() {
      return this.floorplans[this.currentIndex]
    },
    floorplanPins() {
      return this.currentFloorplan.pins
    },
    locationOptions() {
      return this.availablePinOptions('Location')
    },
    deviceOptions() {
      return this.availablePinOptions('Device')
    },
    equipmentOptions() {
      return this.availablePinOptions('Equipment')
    },
    isLastFloorplan() {
      return this.currentIndex === this.floorplans.length - 1
    },
    editableLocations() {
      return _.filter(this.locations, 'editable')
    },
  },
  methods: {
    appendBackToParam(path) {
      return `${path}?back_to=${this.currentEditPath}`
    },
    placeDevicePin(id) {
      this.placePin('Device', id)
    },
    placeEquipmentPin(id) {
      this.placePin('Equipment', id)
    },
    placeLocationPin(id) {
      this.placePin('Location', id)
    },
    placePin(type, id) {
      $.ajax({
        url: `${this.currentFloorplan.path}/pins`,
        method: 'POST',
        data: {
          floorplan_pin: {
            pinnable_id: id,
            pinnable_type: type,
            top: _.random(45, 55),
            left: _.random(45, 55),
          },
        },
        success: (pin) => this.floorplanPins.push(pin),
      })
    },
    availablePinOptions(type) {
      return this.monitoringPoints[type].filter((option) => {
        return !this.floorplanPins.some((pin) => pin.pinnable_id === option.id && pin.pinnable_type === type)
      })
    },
    onPinDeleted(event) {
      $.ajax({
        url: `${this.currentFloorplan.path}/pins/${event.id}`,
        method: 'DELETE',
        success: (response) => {
          const pinToDelete = this.floorplanPins.findIndex((pin) => pin.id === event.id)
          this.floorplanPins.splice(pinToDelete, 1)
          new FlashMessage(response.message, 'notice')
        },
        error: () => {
          new FlashMessage('Could not delete pin', 'alert')
        },
      })
    },
    onPinMoved(event) {
      const {id, position} = event
      $.ajax({
        url: `${this.currentFloorplan.path}/pins/${id}`,
        method: 'PATCH',
        data: {
          floorplan_pin: {
            top: position.top,
            left: position.left,
          },
        },
      })
    },
    handleDeleteFloorplan() {
      $.ajax({
        url: this.currentFloorplan.path,
        method: 'DELETE',
        success: () => {
          this.floorplans.splice(this.currentIndex, 1)
          const newIndex = Math.min(this.currentIndex, this.floorplans.length - 1)
          this.currentIndex = newIndex
        },
      })
    },
    handleContinue() {
      this.currentIndex += 1
    },
    onUpdate() {
      $.ajax({
        url: this.currentFloorplan.path,
        method: 'PATCH',
        data: {
          floorplan: {
            name: this.currentFloorplan.name,
            location_id: this.currentFloorplan.location_id,
          },
        },
        success: (data) => {
          this.validationErrors = {}
          new FlashMessage(data.message, 'notice')
        },
        error: (data) => {
          this.validationErrors = data.responseJSON.errors
        },
      })
    },
  },
})
