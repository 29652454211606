import _ from 'underscore'
import {initVue} from '~/vue'
import initTabs from '../../helpers/tabs'
import initElement from '../../helpers/init_element'

import ModalDialog from '../../components/shared/modal_dialog'

class NewDeviceForm {
  constructor($form) {
    this.trackSettings = this.trackSettings.bind(this)
    this.saveToken = this.saveToken.bind(this)

    this.$form = $form
    this.bindEvents()
  }

  bindEvents() {
    this.trackSettings()
    this.$form
        .on('change', '.modal select', this.trackSettings)
        .on('click', '.submit', () => {
          if (this.shouldRequestCard()) {
            this.stripeCheckout().open()
          } else {
            this.$form.submit()
          }

          return false
        })
  }

  shouldRequestCard() {
    const newCardField = this.$form.serializeArray().find((item) => item.name === 'new_card')
    return (newCardField != null ? newCardField.value : undefined) === 'true'
  }

  stripeCheckout() {
    return StripeCheckout.configure({
      key: gon.stripeKey,
      locale: 'auto',
      token: this.saveToken,
      email: gon.userEmail,
      description: 'Add a new device',
    })
  }

  trackSettings() {
    const usesDefaults = _.all(this.$form.find('.modal select'), (select) => $(select).val() === '')
    const label = usesDefaults ? 'Company Default' : 'Custom'

    $('.registration__settingsStatus').text(label)
  }

  saveToken(token) {
    this.$form.find('[name=\'device[payment_token]\']').val(token.id)
    this.$form.submit()
  }
}

initVue('#vue--devices-new', {components: {ModalDialog}})
initElement('#vue--devices-new', () => {
  initTabs('#d1-device-form')
  new NewDeviceForm($('.device-register-form'))
})
