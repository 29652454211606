import _ from 'underscore'

import TimeSlot from '../../services/wrappers/time_slot'

export default {
  name: 'coverage-calendar',
  template: '#coverage-calendar-template',
  props: {
    colors: {
      type: Object,
      required: true,
    },
    customAlarms: {
      type: Array,
      required: true,
    },
    templateGroups: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      channelOptions: gon.channels,
    }
  },
  computed: {
    alarms() {
      return _.reject(
          _.union(_.flatten(_.map(this.templateGroups, 'alarms')), this.customAlarms),
          (alarm) => !alarm.id,
      )
    },
    channels() {
      const self = this

      // Cache all colors in the order that alarms appear on the page.
      _.each(this.alarms, function(alarm) {
        self.colors.colorFor(alarm)
      })

      return _.map(this.channelOptions, function(channel) {
        const alarms = _.select(self.alarms, function(alarm) {
          return alarm.channel === channel.channelNum
        })

        return {
          name: channel.name,
          days: [
            self.slotsFor(alarms, 0),
            self.slotsFor(alarms, 1),
            self.slotsFor(alarms, 2),
            self.slotsFor(alarms, 3),
            self.slotsFor(alarms, 4),
            self.slotsFor(alarms, 5),
            self.slotsFor(alarms, 6),
          ],
          noAlarms: _.isEmpty(alarms),
        }
      })
    },
  },
  methods: {
    isEmpty: _.isEmpty,
    styleFor(alarm) {
      const color = this.colors.colorFor(alarm)

      return `
        background-color: ${color.background};
        color: ${color.text};
        border: 1px solid ${color.border};
      `
    },
    slotsFor(alarms, day) {
      const alarmData = []

      _.each(alarms, function(alarm) {
        if (alarm.alertSchedule.empty) {
          alarmData.push({
            id: alarm.id,
            alarmTemplateId: alarm.alarmTemplateId,
            message: alarm.message,
            paused: alarm.paused,
            slots: [new TimeSlot()],
          })
        } else {
          let slots = _.select(alarm.alertSchedule.originalSlots, function(slot) {
            return slot.day === day
          })
          slots = _.map(slots, function(slot) {
            return new TimeSlot(slot)
          })

          if (slots.length > 0) {
            alarmData.push({
              id: alarm.id,
              alarmTemplateId: alarm.alarmTemplateId,
              message: alarm.message,
              paused: alarm.paused,
              slots: _.sortBy(slots, 'startHour'),
            })
          }
        }
      })

      return _.sortBy(alarmData, 'id')
    },
  },
}
