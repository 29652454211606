import moment from '~/moment'

// NOTE: This is implemented to handle resolution of date strings without time
// zone information in the user's local time zone. Just passing the String to
// `new Date` will typically evaluate it in UTC, which is usually not correct
// for our use cases.
function parseDateISOString(s) {
  if (!s || s.includes('Z')) {
    return new Date(s)
  } else {
    const ds = s.split(/\D/).map((s) => parseInt(s))
    ds[1] = ds[1] - 1 // adjust month
    return new Date(...ds)
  }
}

export default class PickerFacade {
  constructor(el, options) {
    this.options = options || {}
    this._setup(el)
  }

  _setup(el) {
    const $el = $(el)

    let currentValue = parseDateISOString($el.val())
    currentValue = isNaN(currentValue.getTime()) ? false : moment(currentValue)

    let minValue = parseDateISOString($el.data('min-date'))
    minValue = isNaN(minValue.getTime()) ? false : moment(minValue)

    let maxValue = parseDateISOString($el.data('max-date'))
    maxValue = isNaN(maxValue.getTime()) ? false : moment(maxValue)

    const $picker = $el
        .clone()
        .removeClass(['js--date-picker', 'js--datetime-picker'])
        .prop('id', `${$el.prop('id')}_picker`)
        .prop('name', '')
        .daterangepicker({
          autoApply: false,
          autoUpdateInput: false,
          startDate: currentValue,
          minDate: minValue,
          maxDate: maxValue,
          singleDatePicker: true,
          showDropdowns: true,
          timePicker: this.options.time,
          timePicker24Hour: !momentDateTimeFormat.includes('a'),
          timePickerSeconds: true,
          locale: {cancelLabel: 'Clear', format: this.options.format},
        })

    $picker
        .on('apply.daterangepicker', (_event, picker) => {
          $el.val(picker.startDate.format())
          $picker.val(picker.startDate.format(this.options.format))
        })
        .on('cancel.daterangepicker', () => {
          $el.val('')
          $picker.val('')
        })

    $el.off().prop('type', 'hidden').removeAttr('placeholder').after($picker)

    if (currentValue) {
      $picker.val(currentValue.format(this.options.format))
    } else {
      $el.val('')
    }
  }
}
