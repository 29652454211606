import {createGrid} from 'ag-grid-community'
import $ from 'jquery'
import _ from 'underscore'
import moment from '~/moment'
import initElement from '../../../helpers/init_element'

const FIRMWARE_UNSUPPORTED = `\
Unsupported
<i
  class="fa fa-question-circle"
  data-bs-toggle="tooltip"
  title="Over-the-air firmware updates are not supported for this logger model."
/>\
`

const showForm = (devices) => {
  $('.manageDevices__form').removeClass('d-none')
  const $submit = $('.manageDevices__form--submit')
  $submit.attr('value', $submit.data('apply-text').replace('{{count}}', devices.length))
}
const hideForm = () => $('.manageDevices__form').addClass('d-none')

const onSelectionChanged = (event) => {
  const rows = event.api.getSelectedRows()
  const rowIds = _.pluck(rows, 'device_id')
  $('#deviceIds').val(rowIds)

  if (rows.length) {
    showForm(rows)
  } else {
    hideForm()
  }
}

initElement('#js--devices-bulk_updates-index', () => {
  $('input[name=firmware_update_requested]').on('change', function(e) {
    const $checkbox = $(e.currentTarget)
    if ($checkbox.prop('checked')) {
      const confirmed = confirm($checkbox.data('confirm'))
      $checkbox.prop('checked', confirmed)
    }
  })

  $('input[name=\'[bulk_update][bulk_archive]\'').on('change', function(e) {
    const $checkbox = $(e.currentTarget)
    if ($checkbox.prop('checked')) {
      const confirmed = confirm($checkbox.data('confirm'))
      $checkbox.prop('checked', confirmed)
    }
  })

  $('#showFormLink').on('click', function(e) {
    e.preventDefault()

    const $formContent = $('.manageDevices__formContent')
    $formContent.toggle()
    if ($formContent.css('display') === 'none') {
      $('#showFormLink i').text('expand_more')
    } else {
      $('#showFormLink i').text('expand_less')
    }
  })

  const gridOptions = {
    rowStyle: {
      background: 'white',
    },
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    suppressCellFocus: true,
    suppressColumnVirtualisation: true,
    domLayout: 'autoHeight',
    defaultColDef: {
      resizable: true,
      cellStyle: {
        'white-space': 'normal',
        'border-right': '0.5px solid #BDC3C7',
        'padding': '10px',
      },
      autoHeight: true,
      sortable: true,
      filter: true,
      maxWidth: 200,
    },
    columnDefs: gon.columns,
    rowData: gon.data,
    localeText: {
      noRowsToShow: 'No devices to display at this time',
    },
    onGridReady(event) {
      return event.api.autoSizeAllColumns()
    },
    getRowClass(params) {
      if (!gon.calibration) {
        return
      }
      if (params.data.calibration_due == null) {
        return
      }

      const calibrationDue = new Date(Date.parse(params.data.calibration_due))
      const currentDate = new Date()
      const twoMonthsFromNow = new Date().setMonth(currentDate.getMonth() + 2)

      if (currentDate > calibrationDue) {
        return 'calibration_due'
      } else if (twoMonthsFromNow > calibrationDue) {
        return 'calibrate_soon'
      }
    },

    components: {
      DeviceNameRenderer(params) {
        return `<a href='${params.data.device_path}'>${params.value}</a><br /><span class='ag-cell-loggerModel'>${params.data.logger_model}</span><span class='ag-cell-loggerSerial'>${params.data.serial}</span>`
      },
      LocationNameRenderer(params) {
        return `<a href='${params.data.location_path}'>${params.value}</a>`
      },
      FirmwareVersionRenderer(params) {
        if (!params.value) {
          return '-'
        }

        let output = params.value + ''

        if (params.data.latest_firmware && (params.data.latest_firmware !== params.value)) {
          const status = params.data.firmware_status != null ? params.data.firmware_status : 'Available'
          output += `<br /><span class='ag-cell__details'><strong>${params.data.latest_firmware}</strong> ${status}</span>`
        }

        return output
      },
      FirmwareUpdateRenderer(params) {
        if (params.data.supports_firmware_updates) {
          return params.value
        } else {
          return FIRMWARE_UNSUPPORTED
        }
      },
      SampleIntervalRenderer(params) {
        if (params.data.interval_throttled) {
          const tooltip = `\
            <i class="material-icons" data-bs-toggle="tooltip"
                title="Due to the limitations of RF devices, this device's
                sample interval has been throttled. The configured value is
                '${params.value}'."
            >help</i>\
          `

          return params.data.effective_sample_interval + ' ' + tooltip
        } else {
          return params.value
        }
      },

      TimestampRenderer(params) {
        if (!params.value) {
          return '--'
        }

        return moment(new Date(Date.parse(params.value))).format(momentDateTimeFormat)
      },
    },
    postSortRows(params) {
      const rowNodes = params.rowNodes
      if (!rowNodes) {
        return
      }

      let nextInsertPos = 0
      for (let i = 0; i < rowNodes.length; i++) {
        if (rowNodes[i].data.calibration_due != null) {
          rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0])
          nextInsertPos++
        }
      }
    },
    onSelectionChanged,
  }

  createGrid(document.querySelector('#grid--devices-bulk_updates-index'), gridOptions)
})
