import $ from 'jquery'
import initElement from '../../helpers/init_element'

initElement('#js--widgets-show', ($container) => {
  $container = $($container)

  const oses = {
    windows: navigator.userAgent.indexOf('Win') !== -1,
    mac_os: navigator.userAgent.indexOf('Mac') !== -1,
  }

  for (const os in oses) {
    $container.find(`.widget[data-os=${os}]`).toggleClass('active', oses[os])
  }

  $container.find('.widgets').each(function() {
    const $widgets = $(this).children('.widget')
    $widgets.detach().sort((a, _) => !oses[$(a).data('os')])

    $(this).append($widgets)
  })
})
