import $ from 'jquery'
import _ from 'underscore'

import DeviceRow from '../../services/locations/device_row'
import PreferenceToggleController from '../../services/locations/preference_toggle_controller'
import initElement from '../../helpers/init_element'

initElement('#js--locations-show', () => {
  if ($('.js--preferenceToggle').length) {
    const controller = new PreferenceToggleController($('.js--preferenceToggle'))

    _.pairs(gon.devices).map(function(...args) {
      const [token, channels] = args[0]
      new DeviceRow(token, channels, controller)
    })
  }

  $('.devices ul').sortable()
      .on('sortstop', function(e, ui) {
        $(this).find('.device-row:not(.hide)').each(function(index) {
          $(this).toggleClass('alt', (index % 2) === 0)
        })
      })
      .on('sortstop', function(e, ui) {
        const params = $(this).sortable('serialize')
        $.ajax({
          dataType: 'json',
          url: $(this).data('order-url'),
          type: 'POST',
          data: params,
        })
      })

  $('#sublocations-view-toggle').on('click', function(e) {
    const pills = $('.pills')
    const pressed = e.currentTarget.getAttribute('aria-checked') === 'true'
    e.currentTarget.setAttribute('aria-checked', String(!pressed))
    pills[0].classList.toggle('pills-list')
  })
})
