import {DirectUpload} from '@rails/activestorage'
import pluralize from '../../helpers/pluralize'

/**
The `uploader` component defines a component that enables a drag-and-drop
interaction for uploading a file.

@name uploader
@prop {array} accept
  A list of the supported file content types, in MIME format.

@template components/uploader
@design https://zpl.io/09617eE

@example
  {
    "accept": ["image/png", "image/jpeg"]
  }
*/

const STATUS = {
  UPLOADING: 0,
  SUCCESS: 1,
  FAILED: 2,
}

const Uploader = {
  name: 'uploader',
  template: '#uploader-template',
  props: {
    accept: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {files: [], status: 'empty'}
  },
  methods: {
    hasFailedUpload() {
      return this.files.some((file) => this.isFailed(file))
    },
    hasUploadInProgress() {
      return this.files.some((file) => this.isUploading(file))
    },
    isUploading(file) {
      return file.status === STATUS.UPLOADING
    },
    isSuccess(file) {
      return file.status === STATUS.SUCCESS
    },
    isFailed(file) {
      return file.status === STATUS.FAILED
    },
    uploadProgressClass(file) {
      return {
        'active': this.isUploading(file),
        'success': this.isSuccess(file),
        'progress-bar-danger': this.isFailed(file),
        'progress-bar-striped': this.isUploading(file),
      }
    },
    dropFiles({dataTransfer: {files: files}}) {
      for (const file of files) {
        this.upload(file)
      }
    },
    selectFiles({target: {files: files}}) {
      for (const file of files) {
        this.upload(file)
      }
      this.$refs.fileInput.value = ''
    },
    setStatus(file, status) {
      file.status = status
      this.triggerStatusChanged()
    },
    remove(file) {
      this.files.splice(this.files.indexOf(file), 1)
      this.$emit('removed', file.path)
      this.triggerStatusChanged()
    },
    triggerStatusChanged() {
      let newStatus = null
      if (this.hasUploadInProgress()) {
        newStatus = 'uploading'
      } else if (this.hasFailedUpload()) {
        newStatus = 'failed'
      } else if (this.files.length > 0) {
        newStatus = 'success'
      } else {
        newStatus = 'empty'
      }

      if (newStatus !== this.status) {
        this.status = newStatus
        this.$emit('status-changed', this.status)
      }
    },
    upload(file) {
      const upload = {
        name: file.name,
        id: null,
      }
      this.setStatus(upload, STATUS.UPLOADING)

      this.files.push(upload)

      if (this.accept.indexOf(file.type) < 0) {
        this.setStatus(upload, STATUS.FAILED)
        upload.error = `Supported file types are: ${this.accept.join(', ')}`
        return
      }

      const uploader = new DirectUpload(file, '/rails/active_storage/direct_uploads')
      uploader.create((error, blob) => {
        if (error) {
          this.setStatus(upload, STATUS.FAILED)
        } else {
          const signedId = blob.signed_id

          this.$emit('uploaded', signedId)

          upload.id = signedId
          this.setStatus(upload, STATUS.SUCCESS)
        }
      })
    },
    pluralize,
  },
}

export default Uploader
