import $ from 'jquery'
import FlashMessage from '../../services/flash_message'
import initElement from '../../helpers/init_element'

function updateLocationOrder() {
  const $button = $(this)
  $button.prop('disabled', true)
  const params = $('#location_tree').nestedSortable('serialize')

  $.ajax({
    dataType: 'html',
    url: 'locations/update_hierarchy',
    type: 'PUT',
    data: params,
    success(data) {
      $button.prop('disabled', false)
      new FlashMessage('Locations successfully updated', 'notice', {icon: 'info'})
    },
    error(data) {
      $button.prop('disabled', false)
      new FlashMessage('There was a problem updating the locations', 'error', {icon: 'warning'})
    },
  })
}

initElement('#js--locations-index', () => {
  $('#location_tree').nestedSortable({
    forcePlaceholderSize: true,
    items: 'li',
    maxLevels: 10,
    placeholder: 'placeholder',
    revert: 150,
    tolerance: 'pointer',
    toleranceElement: '> div',
    protectRoot: true,
    handle: '.location-details.draggable',
  })

  $('button.changes').on('click', updateLocationOrder)
})
