import moment from '~/moment'
import MonitoringPointIcon from '../../shared/monitoring_point_icon'
import DatapointRequester from '../../../services/charts/datapoint_requester'
import NumberFormatter from '../../../services/number_formatter'

const FORMATTER = new NumberFormatter()

/**
The `channel-reading` is to be used in conjunction with the `widget-card` component.

@name channel-reading
@prop {boolean} [hideAdditionalInfo=`false`]
  Whether or not additional channel information should be suppressed.
@prop {object} channel
  A channel object.
@prop {string} [initialReading='-']
  A fallback value to display for the reading, in the event that an appropriate
  value can't be fetched from the datapoint service.
@prop {number} [timeFrame]
  An integer number of seconds reflecting the age of the most recent data that
  should be permitted for populating the component.

@template components/dashboards/widgets/channel_reading
@example
{
  "channel": { "name": "Temperature CH:1" },
  "initialReading": "36.0"
}
*/

const ChannelReading = {
  name: 'channel-reading',
  template: '#channel-reading-template',
  components: {MonitoringPointIcon},
  props: {
    hideAdditionalInfo: {
      type: Boolean,
      default: false,
    },
    channel: {
      type: Object,
      required: true,
    },
    initialReading: {
      type: String,
      default: '-',
    },
    timeFrame: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      name: this.channel.name,
      unit: this.channel.display_unit,
      requester: new DatapointRequester(gon.dps_url, [this.channel]),
      currentReading: '-',
    }
  },
  mounted() {
    if (!this.channel.timestamp) {
      return
    }

    const time = moment(this.channel.timestamp).valueOf()

    return this.updateCurrentReading(time)
  },
  computed: {
    classBindings() {
      const alertClass = this.alertType == 'check' ? 'safe' : this.alertType
      return [alertClass, {minified: this.hideAdditionalInfo}]
    },
    alertType() {
      if (this.channel.alerts.length > 0) {
        const hasExcursion = this.channel.alerts.some((alert) => alert.excursion_at)
        return hasExcursion ? 'error' : 'warning'
      }

      return 'check'
    },
    additionalAlerts() {
      const totalAlerts = this.channel.alerts.length
      return totalAlerts > 1 ? `+${totalAlerts - 1}` : ''
    },
    hasReading() {
      return !!this.channel.timestamp
    },
    staleReading() {
      if (!this.hasReading || !this.timeFrame) {
        return false
      }

      const timestamp = moment(this.channel.timestamp)
      const cutoff = moment(new Date()).subtract(this.timeFrame, 'seconds')

      return timestamp.isBefore(cutoff)
    },
  },
  methods: {
    updateCurrentReading(time) {
      new Promise((resolve) => {
        this.requester.fetch(time, time, 1, (channel) => {
          const value = channel.values()[0]
          resolve(FORMATTER.format(value, channel.precision()))
        })
      }).then((value) => {
        this.currentReading = value
      })
    }
  },
  watch: {
    "channel.timestamp"(newValue) {
      const time = moment(newValue).valueOf()
      return this.updateCurrentReading(time)
    },
  }
}

export default ChannelReading
