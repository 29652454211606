// https://raw.githubusercontent.com/meltingice/ajax-chosen/198982ecfb4652478020497a7103f71aa8967572/lib/ajax-chosen.js
(function($) {
  const DEFAULT_OPTIONS = {
    minTermLength: 3,
    afterTypeDelay: 500,
    jsonTermKey: 'term',
    keepTypingMsg: 'Keep typing...',
    lookingForMsg: 'Looking for',
  }

  return $.fn.ajaxChosen = function(settings, callback, chosenOptions) {
    settings ||= {}
    chosenOptions ||= {}

    const select = this
    let chosenXhr = null
    const options = $.extend({}, DEFAULT_OPTIONS, $(select).data(), settings)

    this.chosen(chosenOptions ? chosenOptions : {})
    return this.each(function() {
      return $(this).next('.chosen-container').find('input').bind('keyup', function() {
        const untrimmedVal = $(this).val()
        const val = $.trim($(this).val())
        const msg = val.length < options.minTermLength ? options.keepTypingMsg : options.lookingForMsg + (' \'' + val + '\'')
        const field = $(this)

        select.next('.chosen-container').find('.no-results').text(msg)
        if (val === $(this).data('prevVal')) {
          return false
        }
        $(this).data('prevVal', val)
        if (this.timer) {
          clearTimeout(this.timer)
        }
        if (val.length < options.minTermLength) {
          return false
        }

        options.data ||= {}
        options.data[options.jsonTermKey] = val
        if (options.dataCallback != null) {
          options.data = options.dataCallback(options.data)
        }

        options.success = function(data) {
          if (data == null) {
            return
          }

          const selectedValues = []
          select.find('option').each(function() {
            if (!$(this).is(':selected')) {
              return $(this).remove()
            } else {
              return selectedValues.push($(this).val() + '-' + $(this).text())
            }
          })
          select.find('optgroup:empty').each(function() {
            return $(this).remove()
          })

          const items = callback != null ? callback(data, field) : data
          let nbItems = 0
          $.each(items, function(i, element) {
            let group; let text; let value
            nbItems++
            if (element.group) {
              group = select.find('optgroup[label=\'' + element.text + '\']')
              if (!group.size()) {
                group = $('<optgroup />')
              }
              group.attr('label', element.text).appendTo(select)
              return $.each(element.items, function(i, element) {
                let text; let value
                if (typeof element === 'string') {
                  value = i
                  text = element
                } else {
                  value = element.value
                  text = element.text
                }
                if ($.inArray(value + '-' + text, selectedValues) === -1) {
                  return $('<option />').attr('value', value).html(text).appendTo(group)
                }
              })
            } else {
              if (typeof element === 'string') {
                value = i
                text = element
              } else {
                value = element.value
                text = element.text
              }
              if ($.inArray(value + '-' + text, selectedValues) === -1) {
                return $('<option />').attr('value', value).html(text).appendTo(select)
              }
            }
          })
          if (nbItems) {
            select.trigger('chosen:updated')
          } else {
            select.data().chosen.no_results_clear()
            select.data().chosen.no_results(field.val())
          }
          if (settings.success != null) {
            settings.success(data)
          }
          return field.val(untrimmedVal)
        }
        return this.timer = setTimeout(function() {
          if (chosenXhr) {
            chosenXhr.abort()
          }
          return chosenXhr = $.ajax(options)
        }, options.afterTypeDelay)
      })
    })
  }
})(jQuery)
