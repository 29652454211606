import _ from 'underscore'

import {initVue} from '~/vue'

import ChannelSearch from '../../../components/channels/search'
import MonitoringPointSummary from '../../../components/shared/monitoring_point_summary'

initVue('#vue--alarm_templates-applications-new', {
  components: {ChannelSearch, MonitoringPointSummary},
  data() {
    return {
      selectedTemplateId: null,
      templates: _.pluck(gon.templates, 'templateGroup'),
      channels: gon.channels,
      appliedChannels: [],
    }
  },
  computed: {
    selectedTemplate() {
      return _.find(this.templates, (template) => template.id === this.selectedTemplateId)
    },
    appliedChannelIds() {
      const ids = _.pluck(this.appliedChannels, 'id')
      return ids.length ? ids : ['']
    },
  },
  methods: {
    apply(channel) {
      const applied = [channel, ...this.appliedChannels]
      this.appliedChannels = applied
    },
    unapply(channel) {
      const applied = _.without(this.appliedChannels, channel)
      this.appliedChannels = applied
    },
  },
  watch: {
    selectedTemplate(template, _old) {
      if (template) {
        const channelApplications = _.filter(template.templateApplications, (app) => app.templateableType === 'Channel')
        const appliedChannels = _.pluck(channelApplications, 'templateableId')
        const channels = _.filter(this.channels, (ch) => appliedChannels.includes(ch.id))

        this.appliedChannels = channels
      } else {
        this.appliedChannels = []
      }
    },
  },
})
