import _ from 'underscore'
import moment from '~/moment'

import {DatePicker} from 'v-calendar'

const DatepickerInput = {
  name: 'datepicker-input',
  components: {DatePicker},
  data() {
    return {
      inputId: _.uniqueId('datepicker-input_'),
      popoverVisible: false,
      masks: {
        input: momentDateFormat,
        modelValue: 'iso',
      },
    }
  },
  props: {
    inputName: String,
    label: String,
    placeholder: String,
    required: Boolean,
    modelValue: Date,
  },
  computed: {
    arrow_icon() {
      return this.popoverVisible ? 'arrow_drop_up' : 'arrow_drop_down'
    },
    isoString() {
      return moment(this.modelValue).toISOString()
    },
  },
  methods: {
    setPopoverVisible(visible) {
      this.popoverVisible = visible
    },
  },
  template: `
  <div class="filter-form-group datepicker-form-group">
    <label :for="inputId" v-if="label" class="form-label">{{label}}<abbr title="required" v-if="required">*</abbr></label>
    <date-picker :model-value="modelValue" @update:model-value="$emit('update:modelValue', $event)" :popover="{visibility: 'focus'}" @popoverDidShow="setPopoverVisible(true)" @popoverDidHide="setPopoverVisible(false)" class="datepicker">
      <template #default="{ inputValue, inputEvents }">
        <input type="hidden" :name="inputName" class="form-control" :value="isoString">
        <input :id="inputId" :value="inputValue" :placeholder="placeholder" class="form-control" v-on="inputEvents">
      </template>
    </date-picker>
  </div>`,
}

export default DatepickerInput
