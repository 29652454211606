import _ from 'underscore'
import MultiselectFilter from '../shared/multiselect_filter'

// Grid Filter is a Vue mixin that wraps common functionalities of custom
// filters for ag-grid. Mixins are still supported by Vue 3, but Composition is
// the preferred solution, moving forward.
// See https://vuejs.org/api/options-composition.html#mixins
export default {
  components: {MultiselectFilter},
  data() {
    return {
      grid: null,
      selectedRows: [],
      customFilterPills: () => ({}),
      filterPanelOpen: false,
    }
  },
  computed: {
    appliedPanelFilters() {
      const filters = _.map(this.appliedFilters, function(applied, type) {
        if (!_.contains(this.panelFilters, type) || _.isEmpty(applied)) return []

        const label = this.customFilterPills()[type]?.label
        return _.isArray(applied) ? applied.map((value) => ({type, value, label})): {type, label}
      }, this)

      return _.flatten(filters)
    },
    hasResults() {
      return this.rowCount > 0
    },
    isFilterPresent() {
      return !_.every(this.appliedFilters, _.isEmpty)
    },
    selectedRowCount() {
      return this.selectedRows.length
    },
    sidePanelSelectedFilters() {
      return _.reject(this.selectedFilters, _.isEmpty)
    },
  },
  methods: {
    applyPanelFilters() {
      this.panelFilters.forEach(function(filter) {
        this.appliedFilters[filter] = this.selectedFilters[filter]
      }, this)
      this.updateFilters()
      this.closeFilterPanel()
    },
    clearPanelFilters() {
      this.panelFilters.forEach(function(filter) {
        this.selectedFilters[filter] = []
      }, this)

      if (typeof this.onPanelFiltersCleared === 'function') {
        this.onPanelFiltersCleared()
      }
    },
    closeFilterPanel() {
      this.filterPanelOpen = false
    },
    doesDateRangeFilterPass(filterValue, rowValue) {
      if (_.isEmpty(filterValue)) return true
      if (!rowValue) return false

      const startsBefore = !filterValue.start || moment(filterValue.start).isSameOrBefore(rowValue)
      const endsAfter = !filterValue.end || moment(filterValue.end).isSameOrAfter(rowValue)

      return startsBefore && endsAfter
    },
    doesFilterPass(row) {
      const pass = _.all(this.filters(), function(filter, name) {
        if (_.isEmpty(this.appliedFilters[name])) return true
        if (typeof filter === 'function') return filter(row)

        const filterValue = this.appliedFilters[name]
        const rowValue = row.data[filter]
        return _.isString(filterValue) ? filterValue === rowValue : filterValue.includes(rowValue)
      }, this)

      if (typeof this.onSelectedRowFilteredOut === 'function' && row.isSelected() && !pass) {
        this.onSelectedRowFilteredOut(row)
      }

      return pass
    },
    openFilterPanel() {
      this.filterPanelOpen = true
    },
    onSelectionChanged(event) {
      this.selectedRows = event.api.getSelectedRows()
    },
    removeFilter(filter) {
      const type = filter.type
      if (this.customFilterPills()[type]?.onClose) {
        this.customFilterPills()[type].onClose()
      } else {
        this.selectedFilters[type] = _.without(this.appliedFilters[type], filter.value)
        this.applyPanelFilters()
      }
    },
    updateFilters() {
      if (!this.grid) {
        return
      }

      this.grid.onFilterChanged()
      this.rowCount = this.grid.paginationGetRowCount()
    },
  },
}
