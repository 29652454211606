import $ from 'jquery'

const QUEUE = []

const observer = new MutationObserver((mutationList) => {
  mutationList.filter((m) => m.type === 'childList').forEach((m) => {
    QUEUE.forEach(({callback, selector}) => {
      $(m.addedNodes).find(selector).addBack(selector).each((_, el) => callback(el))
    })
  })
})
observer.observe(document, {childList: true, subtree: true})

/*
Internal: Set up all elements matching a given selector using the provided
callback, setting up an observer to ensure that any future matching elements
added to the page are initialized in the same way.

selector - A String selector query.
callback - A Function.
*/
const dynamicField = (selector, callback) => {
  $(selector).each((_, el) => callback(el))
  QUEUE.push({selector, callback})
}
export default dynamicField
