/**
The `active-alerts` widget is one of the widget types available for custom
dashboards. It shows all active warnings or excursions.

@name active-alerts
@prop {number} count
  Number of alerts.
@prop {string} type
  Alert type of excursions or warnings.
@prop {boolean} [viewonly=`false`]
  Whether or not the widget's linking behavior should be suppressed.
@prop {boolean} [showMenu=`false`]
  Whether or not the widget's context menu should be shown.

@template components/dashboards/widgets/active_alerts
@design https://zpl.io/ewBBjkm

@example
  {
    "count": 0,
    "type": "excursions"
  }
@example
  {
    "count": 10,
    "type": "excursions"
  }
@example
  {
    "count": 2,
    "type": "warnings"
  }
@example
  {
    "count": 1,
    "type": "warnings",
    "showMenu": true,
    "viewonly": true
  }
*/

const ActiveAlerts = {
  name: 'active-alerts',
  template: '#active-alerts-template',
  props: {
    count: {
      type: Number,
      required: true,
    },
    type: {
      type: String, // 'excursion' | 'warning'
      required: true,
    },
    viewonly: {
      type: Boolean,
      default: false,
    },
    showMenu: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSafe() {
      return this.count == 0
    },
  },
}

export default ActiveAlerts
