import _ from 'underscore'
import moment from '~/moment'
import {initVue} from '~/vue'

import DatepickerInput from '../../../components/shared/datepicker_input'
import ModalDialog from '../../../components/shared/modal_dialog'

initVue('#vue--calibrations-build-information', {
  components: {DatepickerInput, ModalDialog},
  data() {
    return {
      calibration: {
        ...gon.calibration,
        calibrationDate: moment(gon.calibration.calibrationDate).toDate(),
      },
      generatedReferenceNumber: gon.generatedReferenceNumber,
    }
  },
  computed: {
    isAutoGenerated() {
      return this.calibration.referenceNumberAutoGenerated
    },
    incomplete() {
      return !_.isDate(this.calibration.calibrationDate) || _.any([
        this.calibration.referenceNumber?.trim(),
        this.calibration.labName?.trim(),
        this.calibration.technicianName?.trim(),
      ], _.isEmpty)
    },
  },
  methods: {
    setAutoGenerated(autoGenerated) {
      this.calibration.referenceNumberAutoGenerated = autoGenerated
      if (autoGenerated) {
        this.calibration.referenceNumber = this.generatedReferenceNumber
      }
    },
  },
})
