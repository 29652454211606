import _ from 'underscore'
import TimeRange from './time_range'

const RANGES = {
  '1h': {label: 'Past Hour', value: 1, interval: 'hour'},
  '24h': {label: 'Past 24 Hours', value: 24, interval: 'hours'},
  '7d': {label: 'Past 7 Days', value: 7, interval: 'days'},
  '1m': {label: 'Past Month', value: 1, interval: 'month'},
  '3m': {label: 'Past 3 Months', value: 3, interval: 'months'},
  '6m': {label: 'Past 6 Months', value: 6, interval: 'months'},
  '1y': {label: 'Past Year to Date', value: 1, interval: 'year'},
}

export default class CannedRange {
  constructor() {
    this.validRanges = this.validRanges.bind(this)
    this.startTime = this.startTime.bind(this)
    this.endTime = this.endTime.bind(this)
    this.rangeKey = this._validRange(gon.range) ? gon.range : this._defaultRangeKey()

    window.cannedRange = this
  }

  validRanges() {
    const final = {}

    for (const key in RANGES) {
      const range = RANGES[key]
      if (!this._validRange(key)) {
        continue
      }

      final[range.label] = [
        moment(gon.extent_max_ts).subtract(range.value, range.interval),
        moment(gon.extent_max_ts),
      ]
    }

    return final
  }

  startTime() {
    return this.endTime().subtract(TimeRange.milliseconds(this.rangeKey), 'ms')
  }

  endTime() {
    return moment(gon.extent_max_ts || (new Date).getTime())
  }

  update(label) {
    if (!label) {
      return
    }
    if (label === 'Custom Range') {
      return
    }

    const rangeKey = _.detect(Object.keys(RANGES), (key) => RANGES[key].label === label)

    $.ajax({
      url: `/devices/${gon.device_token}/preferences`,
      method: 'POST',
      data: {
        preference: 'range',
        value: rangeKey,
      },
    })
  }

  _defaultRangeKey() {
    if (this._validRange('24h')) {
      return '24h'
    } else {
      return '1h'
    }
  }

  _validRange(range) {
    return window.validRanges.indexOf(range) > -1
  }
}
