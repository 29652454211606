import mixin from '../../helpers/mixin'
import WrapperMixin from '../../mixins/wrapper_mixin'

import Contact from './contact'

class AlarmNotification {
  constructor(attrs) {
    if (attrs == null) {
      attrs = {}
    }

    this._destroy = attrs._destroy || false
    this.contact = new Contact(attrs.contact || {})
    this.contactable = attrs.contactable != null ? attrs.contactable : {}
    this.endpointLabel = attrs.endpointLabel || ''
    this.errors = attrs.errors || {}
    this.id = attrs.id
    this.recipient = attrs.recipient || ''
    this.recipientCountry = attrs.recipientCountry || '1'
    this.endpoints = attrs.endpoints || []
    this.relayConfiguration = attrs.relayConfiguration
    this.relayConfigurationId = attrs.relayConfigurationId || ''
    this.blacklisted = attrs.blacklisted != null ? attrs.blacklisted : false
    this.type = attrs.type || ''
  }

  visible() {
    return this.active() && !this.hardware()
  }

  active() {
    return !this._destroy
  }

  hardware() {
    return this.type === 'HardwareAlarmNotification'
  }

  serialize() {
    return {
      _destroy: this._destroy,
      id: this.id,
      recipient: this.recipient,
      recipient_country: this.recipientCountry,
      relay_configuration_id: this.relayConfigurationId,
      type: this.type,
      contactable_id: this.contactable.id,
      contactable_type: this.contactable.type,
      endpoint_label: this.endpointLabel,
    }
  }
}

mixin.extend(AlarmNotification, WrapperMixin)

export default AlarmNotification
