import Multiselect from 'vue-multiselect'

const LocaleSelect = {
  name: 'locale-select',
  template: '#locale-select-template',
  components: {Multiselect},
  props: {
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
  computed: {
    selection() {
      return this.options.find((option) => option.value === this.modelValue)
    },
  },
  methods: {
    style(option) {
      const style = {
        'background-image': 'url(' + option.img + ')',
      }
      return style
    },
    onSelect(event) {
      this.$emit('update:modelValue', event.value)
    },
  },
}

export default LocaleSelect
