import _ from 'underscore'

export default class LocationTree {
  constructor(locations) {
    this.locations = locations
    this.locationsById = _.indexBy(this.locations, 'id')
  }

  tree(root) {
    const children = this.locations.filter((location) => location.parent_id === root.id)
    return [root].concat(_.flatten(children.map((child) => this.tree(child))))
  }

  find(id) {
    return this.locationsById[id]
  }

  rootLocations() {
    return this.locations.filter((location) => !this.parentLocation(location))
  }

  editableLocations() {
    return this.locations.filter((location) => location.permission === 2)
  }

  parentLocation(child) {
    return this.locationsById[child.parent_id]
  }

  serialize() {
    const permissions = {}
    for (const location of this.locations) {
      permissions[location.id] = this._translatePermission(location)
    }
    return permissions
  }

  _translatePermission(location) {
    switch (location.permission) {
      case 2: return 'manage'
      case 1: return 'read'
      default: return null
    }
  }
}
