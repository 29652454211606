import $ from '~/jquery'
import moment from '~/moment'
import _ from 'underscore'

import Alert from '~/components/mixins/alert'
import DatapointRequester from '~/services/charts/datapoint_requester'
import FlashMessage from '~/services/flash_message'
import SearchDropdown from '~/components/shared/search_dropdown'

import SnoozeForm from './snooze_form'

const SYSTEM_EVENTS = [
  'entered_at',
  'excursion_at',
  'returned_to_normal_at',
]

/**
 * The `alert-modal` component defines a component that can be used to display
 * a detailed modal for viewing and interacting with an alert.
 *
 * @name alert-modal
 * @prop {Object} alert The alert object.
 * @prop {Object} eventLocalizations
 *   An Object containing localization data for various alert events.
 *
 * @template components/alerts/alert_modal
 * @design https://zpl.io/40K5Mvy
 *
 * @example
 *   {
 *     "alert": {
 *       "id": 1,
 *       "condition": "Data Logger not reporting",
 *       "acknowledged_at": "2023-11-20T10:41:53.000Z",
 *       "acknowledger_id": 2,
 *       "escalation_started_at": "2023-08-09T18:02:32.000Z",
 *       "excursion_at": "2023-08-09T18:02:32.000Z",
 *       "returned_to_normal_at": null,
 *       "equipment": [
 *         {
 *           "id": 1,
 *           "name": "Ambient"
 *         }
 *       ],
 *       "device": {
 *         "id": 1,
 *         "name": "Chiller",
 *         "token": "791196350796058499"
 *       }
 *     }
 *   }
 */

const AlertModal = {
  name: 'alert-modal',
  template: '#alert-modal-template',
  components: {SearchDropdown, SnoozeForm},
  mixins: [Alert],
  props: {
    alert: {
      required: false,
    },
    eventLocalizations: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      commentText: '',
      feedDisplay: 'all',
      reasonCodes: gon.reason_codes,
      submittingNote: false,
    }
  },
  computed: {
    requester() {
      return new DatapointRequester(gon.dps_url, [this.alert.channel])
    },
    alertClass() {
      return this.alert.excursion_at ? 'alert-danger' : 'alert-warning'
    },
    events() {
      const events = []

      SYSTEM_EVENTS.forEach((timestamp, index) => {
        if (this.alert[timestamp]) {
          events.push({
            id: `system-${index}`,
            text: this.eventLocalizations[timestamp],
            created_at: this.alert[timestamp],
          })
        }
      })

      if (this.alert.acknowledged_at) {
        events.push({
          id: `acknowledgement`,
          text: this.eventLocalizations['acknowledged_at'],
          created_at: this.alert.acknowledged_at,
          user_id: this.alert.acknowledger_id,
          user_name: this.alert.acknowledger_name
        })
      }

      this.alert.escalations.forEach((escalation) => {
        if (escalation.notified_at && escalation.recipients?.length > 0) {
          events.push({
            id: `escalation-${escalation.id}`,
            text: this.eventLocalizations['escalation'],
            created_at: escalation.notified_at,
            meta: escalation.recipients,
          })
        }
      })

      this.alert.alert_snoozes.forEach((snooze) => {
        events.push({
          id: `snooze-${snooze.id}`,
          text: this.eventLocalizations['snooze_started'],
          created_at: snooze.created_at,
        })

        if (moment().isAfter(snooze.ends_at)) {
          events.push({
            id: `snooze-end-${snooze.id}`,
            text: this.eventLocalizations['snooze_ended'],
            created_at: snooze.ends_at,
          })
        }
      })

      return events
    },
    feed() {
      if (this.feedDisplay == 'all') {
        return _.sortBy(_.union(this.comments, this.events), 'created_at')
      } else if (this.feedDisplay == 'comments') {
        return _.sortBy(this.comments, 'created_at')
      } else {
        return _.sortBy(this.events, 'created_at')
      }
    },
    reasonCode() {
      return this.reasonCodes.find((rc) => rc.id == this.alert.alert_note.reason_code_id)
    },
    snoozeUrl() {
      return `alerts/${this.alert.id}/snooze`
    },
  },
  methods: {
    acknowledge() {
      $.ajax({
        method: 'POST',
        url: `alerts/${this.alert.id}/acknowledge`,
      }).done((data) => {
        this.$emit('update', data)
      })
    },
    stopSnooze() {
      $.ajax({
        method: 'DELETE',
        url: `alerts/${this.alert.id}/snooze`,
      }).done((data) => {
        data.forEach((alert) => this.$emit('update', alert))
      })
    },
    timestamp(isoString) {
      return moment(isoString).format(momentDateTimeFormat)
    },
    addComment() {
      $.ajax({
        method: 'POST',
        url: `alerts/${this.alert.id}/comments`,
        data: {comment: {text: this.commentText}},
      }).done((alert) => {
        this.$emit('update', alert)
        this.commentText = ''
      })
    },
    activeFeed(format) {
      return this.feedDisplay == format ? 'active' : ''
    },
    displayFeed(format) {
      this.feedDisplay = format
    },
    saveNote() {
      this.submittingNote = true
      $.ajax({
        url: `/alerts/${this.alert.id}/note`,
        method: 'PUT',
        contentType: 'application/json',
        data: JSON.stringify({alert_note: this.alert.alert_note}),
        success: (data) => {
          this.submittingNote = false
          this.alert.alert_note = data.alert_note
          new FlashMessage(data.notice, 'notice', {modal: true})
        },
        error: (error) => {
          this.submittingNote = false
          new FlashMessage(error.responseJSON.errors, 'error', {modal: true})
        },
      })
    },
  },
  watch: {
    alert() {
      this.currentReading = '-'
      this.loaded = false
      this.loadReading()
    },
  },
}

export default AlertModal
