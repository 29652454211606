import _ from 'underscore'

export default class FilterSet {
  constructor(defaults) {
    this._defaults = defaults
    this._applied = {}
    this._unapplied = {}
    this._mappings = {}

    this.active = {}
    this.pending = {}

    // We expose `.active` and `.pending` as proxies for our applied and
    // unapplied filter selections, to ensure that we're exposing the setter and
    // getter methods to any components consuming them.
    for (const [proxy, source] of [[this.pending, '_unapplied'], [this.active, '_applied']]) {
      for (const filter of Object.keys(defaults)) {
        Object.defineProperty(proxy, filter, {
          get: (() => {
            const value = this[source][filter]
            return this._mappings[filter] ? this._mappings[filter].get(value) : value
          }),
          set: ((value, v2) => {
            this[source][filter] = this._mappings[filter] ? this._mappings[filter].set(value) : value
          }),
        })
      }
    }
  }

  applied() {
    const filters = _.select(Object.keys(this._defaults), (filter) => JSON.stringify(this._applied[filter]) != JSON.stringify(this._defaults[filter]))

    return _.flatten(_.map(filters, (filter) => {
      if (Array.isArray(this._defaults[filter])) {
        return {filter, count: this._applied[filter].length}
      } else {
        return {filter, value: this._applied[filter]}
      }
    }))
  }

  map(filter, {get, set}) {
    this._mappings[filter] = {get, set}
  }

  applySelections() {
    this.update(this._unapplied)
  }

  update(values) {
    for (const [filter, value] of Object.entries(values)) {
      this._applied[filter] = value ? value : this._defaults[filter]
    }
  }

  clearAll() {
    Object.keys(this._applied).forEach((filter) => this.clear(filter))
  }

  clear(filter) {
    this._applied[filter] = this._defaults[filter]
  }

  resetSelection() {
    this._unapplied = {...this._applied}
  }

  // NOTE: I don't love needing to pass in the params object here, but there's
  // no trivial way to construct a URLSearchParams object that selectively
  // overrides values of another such object -- we _need_ the `set` calls to
  // wipe existing values for search params in a URL with other concerns (like
  // pagination).
  applySearchParams(params, prefix) {
    for (const [filter, value] of Object.entries(this._applied)) {
      if (Array.isArray(value)) {
        params.set(`${prefix}[${filter}][]`, '')
        value.forEach((v) => params.append(`${prefix}[${filter}][]`, v))
      } else if (value) {
        params.set(`${prefix}[${filter}]`, value)
      } else {
        params.set(`${prefix}[${filter}]`, '')
      }
    }
  }
}
