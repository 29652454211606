import $ from 'jquery'
import _ from 'underscore.string'

import ErrorMessage from '../shared/error_message'

export default {
  name: 'channel-row',
  template: '#channel-row-template',
  components: {ErrorMessage},
  props: {
    channel: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initialName: this.channel.name,
      showForm: false,
      errors: {},
    }
  },
  computed: {
    summarizable() {
      return this.channel.class !== 'Channel::Boolean'
    },
    avg() {
      return this.summarizable ? 'avg' : null
    },
    max() {
      return this.summarizable ? 'max' : null
    },
    min() {
      return this.summarizable ? 'min' : null
    },
    mkt() {
      if (this.channel.type === 'Channel::Temperature') {
        return 'mkt'
      }
    },
  },
  methods: {
    saveChannelName() {
      const self = this

      $.ajax({
        type: 'PUT',
        url: `/channels/${this.channel.uuid}`,
        data: this.channel,
        dataType: 'json',
        success() {
          // NOTE: This is a bit of a hack to avoid having to make an additional
          // call to the AnnotationsController to refresh our channel name data.
          $('.annotations-table td')
              .filter(function() {
                return _.strip($(this).text()) == self.initialName
              })
              .text(self.channel.name)
          self.errors = {}
          self.showForm = false
        },
        error(jqXHR) {
          self.errors = jqXHR.responseJSON.errors
        },
      })
    },
    editChannelName() {
      this.initialName = this.channel.name
      this.showForm = true
    },
    cancelEdit() {
      this.errors = {}
      this.channel.name = this.initialName
      this.showForm = false
    },
  },
}
