import moment from '~/moment'
import DatapointRequester from '../../services/charts/datapoint_requester'

const BaseAlert = {
  name: 'base-alert',
  template: '#base-alert-template',
  props: {
    alert: {
      type: Object,
      required: true,
    },
    alertModal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      condition: this.alert.condition,
      path: this.alert.path,
      excursion_at: this.alert.excursion_at,
      isDeviceEvent: this.alert.device_event,
      requester: new DatapointRequester(gon.dps_url, [this.alert.channel]),
    }
  },
  computed: {
    recentReading() {
      return this.lastDatapoint?.format() || '-'
    },
    status() {
      return this.alert.excursion_at ? 'error' : 'warning'
    },
    formattedTimestamp() {
      const timestamp = this.lastDatapoint?.ts || this.alert.timestamp

      return moment(timestamp).format(momentDateTimeFormat)
    },
  },
  asyncComputed: {
    lastDatapoint: {
      lazy: true,
      get() {
        const cutoffTime = moment(this.alert.timestamp).valueOf()
        const currentTime = Date.now()

        return (new Promise((resolve) => {
          this.requester.fetch(cutoffTime, currentTime, 1, (channel) => {
            resolve(channel.lastDatapoint())
          })
        }))
      },
    },
  },
  methods: {
    onClick() {
      this.alertModal.triggerModal(this.path)
    },
  },
}

export default BaseAlert
