import $ from 'jquery'

// Public: Performs a request to /certificates/bulk_download passing the
// certificate numbers
//
// certificates - A list of objects that respond to certificate_number
// onSuccess - A function to be called if the request succeeds
export default function postBulkDownload(certificates, onSuccess) {
  const data = JSON.stringify({
    certificates: certificates.map((certificate) => ({
      certificate_number: certificate.certificate_number,
    })),
  })

  $.ajax({
    url: '/certificates/bulk_download',
    method: 'POST',
    data,
    contentType: 'application/json',
    success: onSuccess,
  })
}
