import $ from 'jquery'
import _ from 'underscore'

import ErrorMessage from '../shared/error_message'
import LocationSelect from './location_select'
import ModalDialog from '../shared/modal_dialog'
import SimpleSelect from '../shared/simple_select'

export default {
  name: 'unassigned-device-form',
  template: '#unassigned-device-form-template',
  components: {ErrorMessage, LocationSelect, ModalDialog, SimpleSelect},
  props: {
    device: {
      type: Object,
      required: true,
    },
    replacementOptions: {
      type: Array,
      required: true,
    },
    templateOptions: {
      type: Array,
      required: true,
    },
    locationOptions: {
      type: Array,
      required: true,
    },
    deviceSerial: {
      type: String,
      default: '',
    },
    disableAddition: {
      type: Boolean,
      default: false,
    },
  },
  data: function data() {
    return {
      replacementToken: null,
      errors: {},
    }
  },
  computed: {
    replacementModalId() {
      return 'replaceDevice-' + this.device.id
    },
    replacementModalAnchor() {
      return '#' + this.replacementModalId
    },
    settingsModalId() {
      return 'deviceSettings-' + this.device.id
    },
    settingsModalAnchor() {
      return '#' + this.settingsModalId
    },
    settingsStatus() {
      if (_.all(_.values(this.device.preferences), function(value) {
        return value === ''
      })) {
        return 'Company Default'
      } else {
        return 'Custom'
      }
    },
  },
  methods: {
    submit() {
      if (this.disableAddition) {
        return
      }

      this.request({
        url: this.device.path,
        method: 'patch',
        data: JSON.stringify(this.device.serialize()),
        contentType: 'application/json',
      })
    },
    replace() {
      if (confirm('Are you sure you wish to replace this device?')) {
        this.request({
          url: `/devices/${this.replacementToken}/replacement`,
          method: 'post',
          data: JSON.stringify({registration_code: this.device.registrationCode}),
          contentType: 'application/json',
          success: function(response) {
            this.device.name = response.name
          }.bind(this),
        })
      }
    },
    request(options) {
      $.ajax(options)
          .done(this.processData.bind(this))
          .fail(function(xhr) {
            this.errors = xhr.responseJSON
          }.bind(this))
    },
    processData(data) {
      $(this.replacementModalAnchor).modal('hide')
      this.$emit('register-device', data)
    },
    addLocation() {
      this.$emit('add-location', this)
    },
    addTemplate(channel) {
      channel.templateGroups.push({})
    },
    removeTemplate(channel, template) {
      const index = channel.templateGroups.indexOf(template)
      channel.templateGroups.splice(index, 1)
    },
  },
}
