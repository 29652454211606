const ICONS = {
  'Channel::AnalogPosition': 'bolt',
  'Channel::Boolean': 'door_open',
  'Channel::DewPoint': 'opacity',
  'Channel::DifferentialPressure': 'speed',
  'Channel::GasConcentration': 'co2',
  'Channel::RelativeHumidity': 'water_drop',
  'Channel::Temperature': 'thermostat',
  'Channel::Light': 'wb_incandescent',
}

const ICON_CLASSES = {
  'Channel::Light': 'icon-flipped',
}

export function channelIcon(channel) {
  return ICONS[channel.type] || 'hexagon'
}

export function channelIconClass(channel) {
  return ICON_CLASSES[channel.type] || ''
}

export default channelIcon
