import _ from 'underscore'

import {initVue} from '~/vue'
import Channel from '../../components/channels/channel'

initVue('#vue--channels-index', {
  components: {Channel},
  data() {
    return {
      channels: gon.channels,
      alerts: gon.alerts,
      searchTerm: '',
      dataRange: (24 * 60 * 60 * 1000),
    }
  },
  computed: {
    orderedChannels() {
      self = this

      return _.chain(this.channels)
          .sortBy(function(channel) {
            return -_.size(self.channelAlerts(channel)[1])
          })
          .sortBy(function(channel) {
            return -_.size(self.channelAlerts(channel)[0])
          })
          .value()
    },
  },
  methods: {
    channelAlerts(channel) {
      const alerts = _.filter(this.alerts, function(alert) {
        return alert.token === channel.token && (alert.channel === channel.id || alert.channel === 0)
      })

      return _.partition(alerts, function(alert) {
        return alert.excursion
      })
    },
  },
})
