import _ from 'underscore'
import underscoreKeys from '../../helpers/underscore_keys'

import TimeSlot from './time_slot'

export default class AlertSchedule {
  // Public: Initialize a new AlertSchedule with the passed attributes.
  //
  // attrs - An Object with the following properties:
  //         - id               - The record ID of the AlertSchedule.
  //         - timeSlots        - An Array<Object> of time slot data.
  //         - buffered         - A Boolean: is the schedule configured to use a
  //                              buffer window for alarm processing?
  //         - notifyAfterHours - A Boolean: should back-to-normal notifications
  //                              be delivered outside of the scheduled window?
  constructor(attrs) {
    this.serialize = this.serialize.bind(this)

    if (!attrs) {
      attrs = {}
    }

    this.id = attrs.id
    this.buffered = attrs.buffered
    this.notifyAfterHours = attrs.notifyAfterHours

    // NOTE: Our alarm form caching will serialize and reconstruct this object
    // from a serialized format, so we need to grab `originalSlots` here if it's
    // present.
    this.originalSlots = attrs.originalSlots || attrs.timeSlots || []
    this.empty = _.isEmpty(this.originalSlots)

    const times = _.groupBy(this.originalSlots, (slot) => slot.startTime + ',' + slot.endTime)
    this.timeSlots = _.map(times, (slots, _hash) => {
      return new TimeSlot({
        startTime: slots[0].startTime,
        endTime: slots[0].endTime,
        days: _.pluck(slots, 'day'),
        error: slots[0].errors,
      })
    })
  }

  // Public: Serialize this AlertSchedule record to a format consumable by the
  // server. Does a bit of work to conserve existing time slot record IDs where
  // possible, but the approach is naïve and mostly just intended to keep the
  // auto-incremented IDs from getting out of control when the alarm form is
  // submitted.
  //
  // Returns an Object.
  serialize() {
    let newSlots = _.flatten(_.map(this.timeSlots, (slot) => slot.serialize()))
    newSlots = _.filter(newSlots, (slot) => !slot._destroy)

    if (this.always || _.isEmpty(newSlots)) {
      return {id: this.id, _destroy: true}
    }

    const slotIds = _.map(this.originalSlots, 'id')
    for (let index = 0; index < newSlots.length; index++) {
      newSlots[index].id = slotIds[index]
    }

    // NOTE: In order to minimize database churn, we attempt to maintain any
    // record IDs that have already been allocated, and only destroy them if
    // the new schedule uses fewer time slots than the old one.
    const usedIds = slotIds.length >= newSlots.length ? slotIds.slice(0, newSlots.length) : slotIds
    const unusedSlots = _.map(
        _.filter(this.originalSlots, (slot) => !_.contains(usedIds, slot.id)),
        (slot) => Object.assign({_destroy: true}, slot),
    )

    return {
      id: this.id,
      buffered: this.buffered,
      notify_after_hours: this.notifyAfterHours,
      time_slots_attributes: unusedSlots.concat(newSlots).map(underscoreKeys),
    }
  }
}
