import mixin from '../../helpers/mixin'
import AlarmNotificationsMixin from '../../mixins/alarm_notifications_mixin'
import WrapperMixin from '../../mixins/wrapper_mixin'

import NotificationGroup from './notification_group'

class EscalationPolicy {
  constructor(attrs) {
    if (!attrs) {
      attrs = {}
    }

    this.custom = attrs.custom || false
    this.errors = attrs.errors || {}
    this.alarms = attrs.alarms || []

    this.id = attrs.id
    this.locationId = attrs.locationId
    this.lockVersion = attrs.lockVersion
    this.name = attrs.name
    this.notificationGroups = NotificationGroup.wrap(attrs.notificationGroups)

    this.addHardwareNotification = (this.existingHardwareNotification() != null)
    this.hardwareDelayInterval = this.existingHardwareDelayInterval()
    this.hardwareExcursion = this.existingHardwareExcursion()

    this.url = attrs.url
  }

  conditionOption() {
    return gon.conditionOption
  }

  serialize() {
    return {
      alarm_notifications_attributes: this.serializedNotifications(),
      location_id: this.locationId,
      lock_version: this.lockVersion,
      name: this.name,
    }
  }
}

mixin.include(EscalationPolicy, AlarmNotificationsMixin)
mixin.extend(EscalationPolicy, WrapperMixin)

export default EscalationPolicy
