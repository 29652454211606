import $ from 'jquery'
import _ from 'underscore'
import moment from '~/moment'

import AlarmForm from './alarm_form'
import AlertScheduleSummary from '../alert_schedules/alert_schedule_summary'
import EscalationPolicyDetails from '../escalation_policies/escalation_policy_details'
import AlarmPauseModal from './alarm_pause_modal'
import Alarm from '../../services/wrappers/alarm'
import FlashMessage from '../../services/flash_message'

import {isPaused,currentAlarmPause} from './alarm_utils'

export default {
  name: 'custom-alarm',
  template: '#custom-alarm-template',
  components: {AlarmForm, AlertScheduleSummary, AlarmPauseModal, EscalationPolicyDetails},
  props: {
    alarm: {
      type: Object,
      required: true,
    },
    colors: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    device: {
      type: Object,
      required: false,
    },
    policies: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    bulkAlarmPauses: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    pauseUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showForm: false,
      isSubmitting: false
    }
  },
  mounted() {
    $('#' + this.alarm.id).on('ajax:success', '.js--togglePaused', function(event, data) {
      Object.assign(this.alarm, new Alarm(data.currentAlarm))
    }.bind(this))

    $('#' + this.alarm.id).on('ajax:error', '.js--togglePaused', function(event, data) {
      new FlashMessage(data.errors.join('.'))
    })
  },
  computed: {
    paused() {
      return isPaused(this.alarm)
    },
    alarmPauseDetails() {
      if (this.paused) {
        return currentAlarmPause(this.alarm)
      }
    },
    isChecked() {
      return _.includes(this.bulkAlarmPauses, this.alarm)
    }
  },
  methods: {
    canActivate(alarm) {
      const notifications = _.chain(alarm.escalationPolicies).map((policy) => {
        return _.map(policy.notificationGroups, (group) => group.notifications)
      }).flatten().value()

      return notifications.length > 0
    },
    highlightFor(alarm) {
      const color = this.colors.colorFor(alarm)
      return `background-color: ${color.background}; border: 1px solid ${color.border};`
    },
    triggerSubmit(alarm, alarmForm) {
      this.$emit('save-alarm', alarm, alarmForm)
    },
    triggerDelete(event) {
      const confirmMessage = event.target.dataset.confirm
      if (window.confirm(confirmMessage)) {
        this.$emit('deleted', this.alarm, this.index)
      }
    },
    handleToggleForm() {
      this.showForm = !this.showForm
    },
    timestamp(isoString) {
      return moment(isoString).format(momentDateTimeFormat)
    },
    unpause() {
      this.isSubmitting = true

      $.ajax({
        url: this.pauseUrl,
        method: 'DELETE',
        data: {alarm_pause: {alarm_ids: [this.alarm.id]}},
        success: ([updatedAlarm]) => {
          this.alarm.paused = updatedAlarm.paused
          this.$emit('unselect-bulk-pauses')
        }
      }).done(() => {
        this.isSubmitting = false
      })
    },
    updateBulkAlarmPause(event) {
      const action = event.target.checked ? "add" : "remove"
      this.$emit("update-bulk-alarm", this.alarm, action)
    }
  },
}
