import _ from 'underscore'
import {initVue} from '~/vue'

import Device from '../../components/devices/device'

initVue('#vue--devices-index', {
  components: {Device},
  data() {
    return {
      devices: gon.devices,
      searchTerm: '',
    }
  },
  computed: {
    filteredDevices() {
      const query = this.searchTerm.toLowerCase()

      return _.chain(this.devices)
          .filter(function(device) {
            return _.isEmpty(query) ? true : device.name.toLowerCase().includes(query)
          })
          .sortBy('warningCount')
          .sortBy('excursionCount')
          .reverse().value()
    },
  },
})
