import $ from 'jquery'
import _ from 'underscore'

import initElement from '../../../helpers/init_element'

const INSTANT_REPORTS = ['calibration', 'coverage']

class FinishingDetailsForm {
  constructor($form) {
    this.setUpOneOff = this.setUpOneOff.bind(this)
    this.setUpRecurring = this.setUpRecurring.bind(this)
    this.setLabel = this.setLabel.bind(this)
    this._adjustTimeZone = this._adjustTimeZone.bind(this)
    this._configureDatepicker = this._configureDatepicker.bind(this)
    this._oneOffOptions = this._oneOffOptions.bind(this)
    this._recurringOptions = this._recurringOptions.bind(this)
    this._updateRange = this._updateRange.bind(this)
    this._formSetup = this._formSetup.bind(this)

    this.$form = $form
    this.$oneOffFields = $('[data-report-type=one_off]')
    this.$frequency = $('#dashboard_report_frequency')
    this.$timeZone = $('#dashboard_report_time_zone')
    this.$dateRange = $('#date_range')
    this.$oneOffDate = $('#one_off_date')

    this.$startDate = this.$dateRange.find('input[data-range=start]')
    this.$endDate = this.$dateRange.find('input[data-range=end]')

    this.$startDate.data('original-value', this.$startDate.val())
    this.$endDate.data('original-value', this.$endDate.val())

    this.$frequency
        .on('change', this._updateRange)
        .on('change', this._formSetup)

    this.$timeZone.on('change', this._adjustTimeZone)

    this._adjustTimeZone()
    this._formSetup()
  }

  setUpOneOff() {
    this.$oneOffFields.show()
    this._configureDatepicker(this._oneOffOptions())

    if (_.contains(INSTANT_REPORTS, this.$frequency.data('type'))) {
      this.$dateRange.parent().hide()
      this.$startDate.val(moment().format())
      this.$endDate.val(moment().format())
      this.$oneOffDate.find('.js--daterange__value').text(moment().format(momentDateFormat))
      this.$oneOffDate.show()
    } else {
      this.$dateRange.show()
      this.$oneOffDate.hide()
    }

    this.setLabel('one-off')
  }

  setUpRecurring() {
    this._configureDatepicker(this._recurringOptions())

    this.$dateRange.parent().show()
    this.$oneOffFields.hide()
    this.$oneOffDate.hide()

    this.setLabel('recurring')
  }

  setLabel(textType) {
    this.$form.find(`[data-${textType}-text]`).each(() => {
      $(this).text($(this).data(`${textType}-text`))
    })
  }

  _adjustTimeZone() {
    const zone = window.zoneMappings[this.$timeZone.val()]
    moment.tz.setDefault(zone)
    if (!this.picker) {
      return
    }

    this.picker.timeZone = zone

    const startsAt = this.picker.startDate
    const endsAt = this.picker.endDate

    this.picker.setStartDate(moment(startsAt.format(momentDateTimeFormat), momentDateTimeFormat))
    if ((this.$frequency.val() === 'one_off') && endsAt) {
      this.picker.setEndDate(moment(endsAt.format(momentDateTimeFormat), momentDateTimeFormat))
    }

    this._updateRange()
  }

  _configureDatepicker(options) {
    this.$dateRange.data('daterangepicker')?.remove()
    this.$picker?.off()

    this.$picker = this.$dateRange.daterangepicker(options)
    this.picker = this.$picker.data('daterangepicker')

    this.$picker.on('apply.daterangepicker', this._updateRange)
  }

  _oneOffOptions() {
    let startDate = this.$startDate.data('original-value')
    let endDate = this.$endDate.data('original-value')

    // Daterangepicker library have a bug that display NaN in the calendar when
    // we manually select a previous year for endDate than the selected one
    // Defaulting the start date and date with a gap larger than one month permit to preload the 2 calendars
  
    startDate = startDate ? moment(startDate) : moment().subtract(2, 'month')
    endDate = endDate ? moment(endDate) : moment()

    return {
      linkedCalendars: false,
      showDropdowns: true,
      showCustomRangeLabel: false,
      timePicker: true,
      timePicker24Hour: !momentDateTimeFormat.includes('a'),
      timePickerSeconds: true,
      startDate,
      endDate,
      minDate: moment(this.$dateRange.data('minimum-date')),
      maxDate: moment(),
      locale: {
        format: momentDateTimeFormat,
        separator: ' &mdash; ',
      },
    }
  }

  _recurringOptions() {
    let startDate = this.$startDate.data('original-value')
    startDate = startDate !== '' ? moment(startDate) : moment().add(1, 'day')

    return {
      autoApply: false,
      autoUpdateInput: false,
      startDate,
      minDate: moment().add(1, 'day'),
      maxDate: false,
      singleDatePicker: true,
      showDropdowns: true,
      timePicker: false,
      locale: {
        format: momentDateTimeFormat,
      },
    }
  }

  _updateRange(_event = null, picker) {
    if (picker == null) {
      picker = this.picker
    }

    const startsAt = picker.startDate
    if (!startsAt._isValid) {
      return true
    }

    this.$startDate.val(startsAt.format())

    if (this.$frequency.val() === 'one_off') {
      const endsAt = picker.endDate
      this.$endDate.val(endsAt.format())
      this.$dateRange
        .find('.js--daterange__value')
        .html(`${startsAt.format(momentDateTimeFormat)} &mdash; ${endsAt.format(momentDateTimeFormat)}`)
    } else {
      this.$endDate.val('')
      this.$dateRange
        .find('.js--daterange__value')
        .html(startsAt.format(momentDateFormat))
    }
  }

  _formSetup() {
    this.$frequency.val() === 'one_off' ? this.setUpOneOff() : this.setUpRecurring()
    this._updateRange()
  }
}

initElement('#js--dashboard_reports-build-add_finishing_details', ($el) => {
  new FinishingDetailsForm($($el))

  const $decimalSeparator = $el.querySelector('#dashboard_report_options_decimal_separator')
  if ($decimalSeparator.value == '') {
    $decimalSeparator.value = window.decimalSeparator
  }
})
