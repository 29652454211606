import initElement from '../../helpers/init_element'

initElement('#js--accounts-billing', () => {
  $('.payment_row').on('click', '.js-payment_details_toggle', function(e) {
    const $icon = $(this).find('i')
    $icon.text($icon.text() == 'expand_more' ? 'expand_less' : 'expand_more')

    $(e.delegateTarget)
        .next('.payment_row__details')
        .toggleClass('hide')
  })
})
