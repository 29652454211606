import _ from 'underscore'
import pluralize from '../../helpers/pluralize'

import TimeDelay from './time_delay'
import NotificationInputs from '../notifications/notification_inputs'

import AlarmNotification from '../../services/wrappers/alarm_notification'

export default {
  name: 'notification-group-form',
  template: '#notification-group-form-template',
  components: {NotificationInputs, TimeDelay},
  props: {
    group: {
      type: Object,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
    device: {
      type: Object,
      required: false,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    disableWarning() {
      return this.group.delayInterval > this.minimumExcursionInterval
    },
    number() {
      const index = this.parent.visibleNotificationGroups().indexOf(this.group)
      return (index + 1).toString().padStart(2, '0')
    },
    hasVisibleNotification() {
      return _.any(this.group.notifications, function(notification) {
        return notification.visible()
      })
    },
    condition() {
      return this.parent.conditionOption()
    },
    excursionGroups() {
      return _.filter(this.parent.notificationGroups, function(ng) {
        return ng.excursion
      })
    },
    minimumExcursionInterval() {
      const minGroupInterval = _.min(_.pluck(this.excursionGroups, 'delayInterval'))

      if (this.parent.addHardwareNotification && this.parent.hardwareExcursion) {
        return _.min([this.parent.hardwareDelayInterval, minGroupInterval])
      } else {
        return minGroupInterval
      }
    },
  },
  methods: {
    addNotification() {
      this.group.notifications.push(new AlarmNotification())
    },
    deleteNotification(index) {
      const notification = Object.assign(this.group.notifications[index], {_destroy: true})
      this.group.notifications[index] = notification
    },
    idFor(prefix) {
      return prefix + '-' + (this.parent.id || 'new') + '-' + this.index
    },
    maximumWarningInterval() {
      const warningGroups = this.parent.notificationGroups.filter((g) => !g.excursion)
      return Math.max(...warningGroups.map((g) => g.delayInterval), 0)
    },
    updateExcursionToggles() {
      const minimumInterval = this.minimumExcursionInterval
      const groups = _.filter(this.parent.notificationGroups, (ng) => ng.delayInterval > minimumInterval)
      _.each(groups, (ng) => ng.excursion = true)

      this.$emit('excursion')
    },
    pluralize,
  },
  watch: {
    'group.excursion'(newValue, _oldValue) {
      const maximumWarningInterval = this.maximumWarningInterval()
      if (newValue && this.group.delayInterval < maximumWarningInterval) {
        this.group.delayInterval = maximumWarningInterval + 1
      }
      this.updateExcursionToggles()
    },
    'group.delayInterval'(newValue, oldValue) {
      if (newValue < 0) {
        this.group.delayInterval = 0
      }
      this.updateExcursionToggles()
    },
    'parent.addHardwareNotification'() {
      this.updateExcursionToggles()
    },
    'parent.hardwareExcursion'() {
      this.updateExcursionToggles()
    },
    'parent.hardwareDelayInterval'() {
      this.updateExcursionToggles()
    },
  },
}
