import $ from 'jquery'
import _ from 'underscore'
import 'core-js/features/array/flat-map'
import 'core-js/features/array/unique-by'

import {initVue} from '~/vue'

import Multiselect from 'vue-multiselect'

import GridFilter from '../../components/mixins/grid_filter'
import EquipmentCard from '../../components/equipment/equipment_card'
import ChannelSection from '../../components/equipment/channel_section'
import PillButton from '../../components/shared/pill_button'
import FlashAlert from '../../components/alerts/flash_alert'

initVue('#vue--equipment-index', {
  mixins: [GridFilter],
  components: {ChannelSection, EquipmentCard, FlashAlert, Multiselect, PillButton},
  data() {
    return {
      filteredEquipment: gon.data,
      searchTerm: '',
      sort: gon.sort_options[0],
      sortingOptions: gon.sort_options,
      appliedFilters: {
        alertStatus: null,
        channelType: [],
        dataLogger: [],
        equipmentType: [],
        location: [],
      },
      selectedFilters: {
        alertStatus: null,
        channelType: [],
        dataLogger: [],
        equipmentType: [],
        location: [],
      },
      filterOptions: {
        alertStatus: [
          {value: 'excursion', label: gon.alert_statuses.excursion},
          {value: 'warning', label: gon.alert_statuses.warning},
          {value: 'excursionAndWarning', label: gon.alert_statuses.excursion_and_warning},
          {value: 'safe', label: gon.alert_statuses.safe},
        ],
        channelType: gon.data.flatMap((eq) => _.pluck(eq.channels, 'localized_type')).uniqueBy().sort(),
        dataLogger: gon.data.flatMap((eq) => eq.channels.map((ch) => ch.device.name)).uniqueBy().sort(),
        equipmentType: _.pluck(gon.data, 'localized_type').uniqueBy().sort(),
        location: gon.data.map((eq) => eq.location.name).uniqueBy().sort(),
      },
      customFilterPills() {
        return {
          alertStatus: {
            label: (_) => `${gon.filters.alert_status}: ${this.appliedFilters.alertStatus.label}`,
            onClose: this.clearAlertStatus,
          },
          channelType: {
            label: ({value}) => `${gon.filters.channel_type}: ${value}`,
          },
          dataLogger: {
            label: ({value}) => `${gon.filters.data_logger}: ${value}`,
          },
          equipmentType: {
            label: ({value}) => `${gon.filters.equipment_type}: ${value}`,
          },
          location: {
            label: ({value}) => `${gon.filters.location}: ${value}`,
          },
        }
      },
      panelFilters: ['alertStatus', 'channelType', 'dataLogger', 'equipmentType', 'location'],
    }
  },
  computed: {
    filteredAndSortedEquipment() {
      const sorted = _.chain(this.filteredEquipment)
          .filter((eq) => eq.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
          .sortBy(this[this.sort.method])
          .value()
      return this.sort.descending ? sorted.reverse() : sorted
    },
  },
  methods: {
    applyFiltersAndClose() {
      this.applyPanelFilters()
      $('#js--equipment-filter-panel').modal('hide')
    },
    clearAllFilters() {
      this.clearPanelFilters()
      this.applyPanelFilters()
    },
    clearAlertStatus() {
      this.selectedFilters.alertStatus = null
      this.appliedFilters.alertStatus = null
      this.updateFilters()
    },
    clearFiltersAndClose() {
      this.clearAllFilters()
      $('#js--equipment-filter-panel').modal('hide')
    },
    doesAlertStatusPass(eq) {
      if (_.isEmpty(this.appliedFilters.alertStatus)) {
        return true
      }

      switch (this.appliedFilters.alertStatus.value) {
        case 'excursion':
          return eq.alert_status.includes('excursion')
        case 'warning':
          return eq.alert_status.includes('warning')
        case 'excursionAndWarning':
          return eq.alert_status.includes('warning') || eq.alert_status.includes('excursion')
        case 'safe':
          return eq.alert_status.includes('safe')
      }
    },
    doesChannelTypePass(eq) {
      if (_.isEmpty(this.appliedFilters.channelType)) {
        return true
      }

      const equipmentChannelTypes = _.pluck(eq.channels, 'localized_type')
      return _.intersection(equipmentChannelTypes, this.appliedFilters.channelType).length
    },
    doesDataLoggerPass(eq) {
      if (_.isEmpty(this.appliedFilters.dataLogger)) {
        return true
      }

      const equipmentDataLoggers = eq.channels.map((ch) => ch.device.name)
      return _.intersection(equipmentDataLoggers, this.appliedFilters.dataLogger).length
    },
    doesEquipmentTypePass(eq) {
      if (_.isEmpty(this.appliedFilters.equipmentType)) {
        return true
      }

      return this.appliedFilters.equipmentType.includes(eq.localized_type)
    },
    doesLocationPass(eq) {
      if (_.isEmpty(this.appliedFilters.location)) {
        return true
      }

      return this.appliedFilters.location.includes(eq.location.name)
    },
    sortByAlertStatus(equipment) {
      return [-equipment.total_excursions, -equipment.total_warnings, equipment.name]
    },
    sortByName({name}) {
      return name.toLowerCase()
    },
    sortByNumberOfChannels({channels}) {
      return channels.length
    },
    updateFilters() {
      const allEquipment = gon.data
      this.filteredEquipment = allEquipment.filter((eq) => {
        return this.doesAlertStatusPass(eq) &&
          this.doesChannelTypePass(eq) &&
          this.doesDataLoggerPass(eq) &&
          this.doesEquipmentTypePass(eq) &&
          this.doesLocationPass(eq)
      })
    },
  },
})
