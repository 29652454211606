import _ from 'underscore'
import moment from '~/moment'
import {DatePicker} from 'v-calendar'

const DaterangeFilter = {
  name: 'daterange-filter',
  components: {DatePicker},
  data() {
    return {
      inputId: _.uniqueId('daterange-input_'),
      popoverVisible: false,
      rangeConfig: [
        {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        },
        {
          hours: 23,
          minutes: 59,
          seconds: 59,
          milliseconds: 999,
        },
      ],
    }
  },
  props: {
    label: String,
    placeholder: String,
    modelValue: Object,
  },
  computed: {
    dateRangeToString() {
      if (_.isEmpty(this.modelValue)) {
        return ''
      }
      return [this.modelValue.start, this.modelValue.end].map((date) => moment(date).format(momentDateFormat)).join(' - ')
    },
  },
  methods: {
    setPopoverVisible(visible) {
      this.popoverVisible = visible
    },
    onUpdate(event) {
      this.$emit('update:modelValue', event)
      this.$emit('input')
    },
  },
  template: `
  <div class="filter-form-group datepicker-form-group">
    <label class="form-label" :for="inputId" v-if="label">{{label}}</label>
    <date-picker @popoverDidShow="setPopoverVisible(true)" :rules="rangeConfig" @popoverDidHide="setPopoverVisible(false)" :modelValue="modelValue" :model-modifiers="{range: true}" @update:modelValue="onUpdate" class="datepicker">
      <template v-slot="{ togglePopover }">
        <input :id="inputId" :value="dateRangeToString" @focus="togglePopover" :placeholder="placeholder" readonly="readonly" class="form-control">
      </template>
    </date-picker>
  </div>`,
}

export default DaterangeFilter
