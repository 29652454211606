import _ from 'underscore'

import {initVue} from '~/vue'

import EquipmentHeader from '../../components/equipment/equipment_header'
import ChannelRow from '../../components/charts/channel_row'
import ChartCoordinator from '../../services/charts/chart_coordinator'
import ModalDialog from '../../components/shared/modal_dialog'
import ReferenceLines from '../../components/reference_lines/reference_lines'

initVue('#vue--equipment-show', {
  components: {ChannelRow, EquipmentHeader, ModalDialog, ReferenceLines},
  data() {
    return {
      equipment: gon.equipment,
      channels: _.map(_.values(gon.channels), _.clone),
      chartCoordinator: null,
      editable: gon.device_editable,
    }
  },
  mounted() {
    if (this.channels.length) {
      this.$nextTick(() => this.chartCoordinator = new ChartCoordinator('chart--shared', 'chart--secondary'))
    }
  },
  methods: {
    onNewReferenceLine() {
      this.$refs.referenceLines.onNewReferenceLine()
    },
  },
})
