import $ from 'jquery'

const initTabs = (defaultTab) => {
  const anchor = window.location.hash || defaultTab
  $('a[href="' + anchor + '"]').tab('show')

  if (!window.location.hash) {
    window.history.replaceState({anchor}, null, window.location.pathname + anchor)
  }

  window.addEventListener('popstate', function(e) {
    const anchor = e.state?.anchor || window.location.hash
    if (anchor) {
      $('a[href="' + anchor + '"]').tab('show')
    }
  })

  $(window).on('show.bs.tab', function(e) {
    const anchor = $(e.target).attr('href')

    if (window.location.hash && anchor !== window.location.hash) {
      window.history.pushState({anchor}, null, window.location.pathname + anchor)
    } else {
      window.history.replaceState({anchor}, null, window.location.pathname + anchor)
    }
  })
}

export default initTabs
