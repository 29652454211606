import $ from 'jquery'
import _ from 'underscore'
import AlertMessage from './alert_message'

const TEMPLATE = `
  <p class='fade show in <%= level %>'>
    <% if (icon) { %><i class='material-icons' aria-hidden><%= icon %></i><% } %>
    <span><%= message %></span>
    <% if (close) { %><a class="close" href="#"><i class="material-icons">close</i></a><% } %>
  </p>
`

export default class FlashMessage {
  constructor(message, level = 'error', opts = {}) {
    const persistent = level.match(/persistent/)
    const icon = opts.icon
    const containerSelector = opts.modal ? '.modal-content .flashes' : '.content .flashes'

    const $container = $(containerSelector).first()
    const $flash = $(_.template(TEMPLATE)({level, icon, message, close: !persistent}))

    $flash.appendTo($container)

    if (!persistent) {
      new AlertMessage($flash, opts.keepMessage)
    }

    $('html, body').animate({scrollTop: $flash.offset().top}, 500)
  }
}
