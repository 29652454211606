import $ from 'jquery'
import _ from 'underscore'

import Multiselect from 'vue-multiselect'

export default {
  name: 'contact-select',
  template: '#contact-select-template',
  components: {Multiselect},
  props: {
    notification: {
      type: Object,
      required: true,
    },
    endpointType: {
      type: String,
      required: true,
    },
  },
  created() {
    this.fetchContacts()
  },
  data() {
    return {
      contactOptions: [],
      isLoading: false,
    }
  },
  computed: {
    contactSelected() {
      return !_.isEmpty(this.notification.contactable) && !this.customSelected
    },
    customSelected() {
      return this.notification.contactable.label === 'Custom'
    },
  },
  methods: {
    fetchContacts: _.debounce(function() {
      this.isLoading = true
      $.get(gon.contactsUrl, {endpoint_type: this.endpointType})
          .then(function(data) {
            data.contacts.push({label: 'Custom'})
            this.contactOptions = [
              {
                label: 'Roles',
                values: data.roles,
              },
              {
                label: 'Contacts',
                values: data.contacts,
              },
            ]
            this.isLoading = false
          }.bind(this),
          )
    }, 300),
    clearCustomRecipient() {
      this.notification.recipient = ''
      this.notification.recipientCountry = '1'
    },
  },
}
