import _ from 'underscore'
import {initVue} from '~/vue'

import ActiveAlerts from '~/components/dashboards/widgets/active_alerts'
import AlertStatus from '~/components/dashboards/widgets/alert_status'
import ChannelReading from '~/components/dashboards/widgets/channel_reading'
import FloorplanArea from '~/components/floorplans/floorplan_area'
import MonitoringPointSearch from '~/components/shared/monitoring_point_search'
import MonitoringPointSummary from '~/components/shared/monitoring_point_summary'
import SearchDropdown from '~/components/shared/search_dropdown'
import SelectCard from '~/components/shared/select_card'
import WidgetCard from '~/components/dashboards/widgets/widget_card'

initVue('#vue--dashboards-widgets-new', {
  components: {
    ActiveAlerts,
    AlertStatus,
    ChannelReading,
    FloorplanArea,
    MonitoringPointSearch,
    MonitoringPointSummary,
    SearchDropdown,
    SelectCard,
    WidgetCard,
  },
  data() {
    return {
      types: gon.localized_types,
      styles: [
        {
          'monitoring_point': [
            {'Alert Status': 'alert_status'},
            {'Channel Readings': 'channel_readings'},
          ],
          'alerts': [
            {'Excursions': 'excursions'},
            {'Warnings': 'warnings'},
          ],
        },
      ],
      selectedFloorplan: gon.monitoring_points.floorplans[0],
      selectedType: '',
      selectedStyle: '',
      monitoringPoints: gon.monitoring_points,
      selectedMonitoringPoints: [],
      preview: {
        monitoringPoint: {
          type: 'data_logger',
          name: 'Logger or Equipment Name',
          description: 'Serial/Type',
          total_excursions: 1,
          total_warnings: 0,
          timestamp: new Date().getTime(),
        },
        location: {
          type: 'data_logger',
          name: 'Location Name',
          description: 'Location (# Channels)',
          total_excursions: 1,
          total_warnings: 0,
          timestamp: new Date().getTime(),
        },
        channel: {
          'type': 'channel',
          'name': 'Channel Name',
          'description': 'Location (# Channels)',
          'total_excursions': 1,
          'total_warnings': 0,
          'timestamp': new Date().getTime(),
        },
      },
    }
  },
  computed: {
    complete() {
      switch (this.selectedType) {
        case '':
          return false
        case 'alerts':
          return this.selectedStyle !== ''
        case 'monitoring_point':
          return this.selectedStyle !== '' && this.selectedMonitoringPointsTotal > 0
        case 'floorplan':
          return true
        default:
          return this.selectedMonitoringPointsTotal > 0
      }
    },
    selectedMonitoringPointsTotal() {
      return this.selectedMonitoringPoints.length
    },
    floorplans() {
      return this.monitoringPoints.floorplans
    },
    searchableMonitoringPoints() {
      switch (this.selectedType) {
        case 'monitoring_point':
          return {
            equipment: this.monitoringPoints.equipment,
            devices: this.monitoringPoints.devices,
          }
        case 'channel':
          return {channels: this.monitoringPoints.channels}
        case 'location':
          return {locations: this.monitoringPoints.locations}
        case 'floorplan':
          return {floorplans: this.monitoringPoints.floorplans}
      }
      return {}
    },
    finalMonitoringPoints() {
      switch (this.selectType) {
        case 'floorplan':
          return [this.selectedFloorplan]
        default:
          return this.selectedMonitoringPoints
      }
    },
    showSearch() {
      if (this.selectedType === '' || this.selectedType === 'alerts' || this.selectedType === 'floorplan') {
        return false
      } else if (this.selectedType === 'monitoring_point') {
        return this.selectedStyle !== ''
      }
      return true
    },
  },
  methods: {
    clearSelection() {
      this.selectedMonitoringPoints = []
      this.selectedFloorplan = this.monitoringPoints.floorplans[0]
    },
    select(monitoringPoint) {
      const applied = [monitoringPoint, ...this.selectedMonitoringPoints]
      this.selectedMonitoringPoints = applied
    },
    unselect(monitoringPoint) {
      const applied = _.without(this.selectedMonitoringPoints, monitoringPoint)
      this.selectedMonitoringPoints = applied
    },
    getTypeIcon(type) {
      switch (type) {
        case 'monitoring_point': return 'tablet_android'
        case 'channel': return 'timeline'
        case 'location': return 'pin_drop'
        case 'alerts': return 'error'
        case 'floorplan': return 'space_dashboard'
      }
      return 'hexagon'
    },
    selectType(type) {
      this.selectedType = type
      this.selectedStyle = ''
      this.clearSelection()
    },
    selectFloorplan(id) {
      const floorplan = this.floorplans.find((f) => f.id === id)
      this.selectedFloorplan = floorplan
    },
  },
})
