import $ from 'jquery'
import {s3} from 'fine-uploader/lib/s3'

class WidgetUploaderField {
  constructor(container) {
    const $container = $(container)
    this.$input = $container.find(':input')
    this.$label = $container.find('label')
  }

  toggleError(condition) {
    if (condition === undefined) {
      condition = this.$input.val()
    }
    if (condition) {
      this.$label.removeClass('field_with_errors')
    } else {
      this.$label.addClass('field_with_errors')
    }
    return condition
  }

  isFileField() {
    return this.$input.attr('id') === 'widget_file'
  }
}

class WidgetUploaderSubmit {
  constructor($submit) {
    this.$submit = $submit
    this.originalText = this.$submit.text()
  }

  click(fn) {
    this.$submit.on('click', fn)
  }

  disable() {
    return this.$submit
        .prop('disabled', true)
        .text('Uploading...')
  }

  enable() {
    return this.$submit
        .prop('disabled', false)
        .text(this.originalText)
  }
}

export default class WidgetUploader {
  constructor($form) {
    this.formatKey = this.formatKey.bind(this)
    this.onComplete = this.onComplete.bind(this)
    this.validateField = this.validateField.bind(this)

    this.$form = $form
    this.storeDir = this.$form.data('s3-store-dir')
    this.submit = new WidgetUploaderSubmit(this.$form.find('.widget_upload__button'))
    this.fields = this.$form.children('.required').toArray().map((el) => new WidgetUploaderField(el))
    this.uploader = new s3.FineUploader(this.uploaderOptions())

    this.bindEvents()
  }

  bindEvents() {
    this.submit.click(() => {
      if (this.validateForm()) {
        this.submit.disable()
        this.uploader.uploadStoredFiles()
      } else {
        this.submit.enable()
      }
    })
  }

  uploaderOptions() {
    const token = $('meta[name="csrf-token"]').attr('content')

    return {
      element: this.$form.find('.upload_container')[0],
      autoUpload: false,
      multiple: false,
      debug: true,
      objectProperties: {
        key: this.formatKey,
        acl: 'public-read',
      },
      cors: {
        expected: true,
      },
      request: {
        endpoint: gon.s3Endpoint,
        accessKey: gon.s3AccessKey,
      },
      signature: {
        endpoint: gon.authorizeUploadPath,
        customHeaders: {'X-CSRF-Token': token},
      },
      resume: {
        enabled: true,
      },
      retry: {
        enableAuto: false,
        showButton: true,
      },
      callbacks: {
        onComplete: this.onComplete,
      },
    }
  }

  formatKey(id) {
    return `${this.storeDir}/${this.uploader.getName(id)}`
  }

  onComplete(id, name, response, xhr) {
    if (response.success) {
      const fileName = this.uploader.getKey(id)
      const fileSize = this.uploader.getFile(id).size

      this.$form.find('#widget_file').val(fileName)
      this.$form.find('#widget_file_size').val(fileSize)
      this.$form.submit()
    } else {
      // TODO: Error handling
      console.error('Upload failed')
    }
  }

  validateForm() {
    return this.fields.every(this.validateField)
  }

  validateField(field) {
    if (field.isFileField()) {
      return field.toggleError(this.uploader.getUploads()[0])
    } else {
      return field.toggleError()
    }
  }
}
