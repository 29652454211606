import _ from 'underscore'

import {initVue} from '~/vue'

import ChannelRow from '../../components/charts/channel_row'
import ChartCoordinator from '../../services/charts/chart_coordinator'
import ModalDialog from '../../components/shared/modal_dialog'
import ReferenceLines from '../../components/reference_lines/reference_lines'

initVue('#vue--devices-show', {
  components: {ChannelRow, ModalDialog, ReferenceLines},
  data() {
    // NOTE: We have to clone our channel objects here, to avoid EventsMixin
    // picking up click events through the actual Objects provided via
    // `gon.channels`.
    return {
      channels: _.map(_.values(gon.channels), _.clone),
      editable: gon.device_editable,
      chartCoordinator: null,
    }
  },
  mounted() {
    if (this.channels.length) {
      this.$nextTick(() => this.chartCoordinator = new ChartCoordinator('chart--shared', 'chart--secondary'))
    }
  },
  methods: {
    onNewReferenceLine() {
      this.$refs.referenceLines.onNewReferenceLine()
    },
  },
})
