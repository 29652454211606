import {createGrid} from 'ag-grid-community'
import _ from 'underscore'

$(() => {
  const gridEl = document.querySelector('#grid--calibrations-build-summary')

  if (!gridEl) {
    return
  }

  createGrid(gridEl, {
    pagination: true,
    paginationPageSize: gon.page_size,
    paginationPageSizeSelector: false,
    rowStyle: {background: 'white'},
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    suppressColumnVirtualisation: true,
    domLayout: 'autoHeight',
    defaultColDef: {
      resizable: true,
      cellStyle: {
        'white-space': 'normal',
        'padding': '10px',
      },
      autoHeight: true,
      sortable: true,
      filter: false,
      maxWidth: 200,
    },
    columnDefs: [
      {
        headerName: gon.labels['channel_name'],
        field: 'channel_name',
        cellRenderer: 'ChannelNameRenderer',
      },
      {
        headerName: gon.labels['serial'],
        field: 'serial',
      },
      {
        headerName: gon.labels['sku'],
        field: 'sku',
      },
      {
        headerName: gon.labels['standard_readings'],
        field: 'standard_readings',
        cellRenderer: 'ValueRenderer',
      },
      {
        headerName: gon.labels['test_readings'],
        field: 'test_readings',
        cellRenderer: 'ValueRenderer',
      },
      {
        headerName: gon.labels['as_left_readings'],
        field: 'as_left_readings',
        cellRenderer: 'ValueRenderer',
      },
      {
        headerName: gon.labels['gain'],
        field: 'gain',
      },
      {
        headerName: gon.labels['offset'],
        field: 'offset',
      },
      {
        headerName: gon.labels['status'],
        field: 'status',
        cellRenderer: 'StatusRenderer',
      },
      {
        headerName: gon.labels['adjustment_type'],
        field: 'adjustment_type'
      },
    ],
    rowData: gon.data,
    onGridReady(event) {
      event.api.autoSizeAllColumns()
      event.api.setGridAriaProperty('label', gon.labels['adjustments_table'])
    },
    onGridSizeChanged(event) {
      event.api.sizeColumnsToFit()
    },
    components: {
      ChannelNameRenderer(params) {
        if (params.data.status) {
          return `<span class="channel-cell-adjusted">${params.value}</span>`
        } else {
          return params.value
        }
      },
      ValueRenderer(params) {
        if (!params.value?.length) {
          return '-'
        }
        return _.pluck(params.value, 'value').join(', ')
      },
      StatusRenderer(params) {
        return gon.labels[params.value]
      },
    },
  })
})
