import $ from 'jquery'
import _ from 'underscore'
import moment from '~/moment'
import Highcharts from '~/highcharts'

import BooleanChart from './boolean_chart'
import SharedChart from './shared_chart'

import AnnotationSummarizer from './annotation_summarizer'
import CannedRange from './canned_range'
import DatapointRequester from './datapoint_requester'
import ReferenceLines from './reference_lines'
import RangeSelect from './range_select'
import TimeRange from './time_range'

const SYNC_EVENTS = ['mousemove', 'touchmove', 'touchstart']

export default class ChartCoordinator {
  // Public: Initialize a new ChartCoordinator object.
  //
  // containerId - A String ID for the container that should house the rendered
  //               chart.
  constructor(containerId) {
    this._resetLoader = this._resetLoader.bind(this)
    this._handleUpdate = this._handleUpdate.bind(this)
    this._updateSeries = this._updateSeries.bind(this)
    this._syncCharts = this._syncCharts.bind(this)
    this.granularity = null
    this.chartMap = {}

    const [primaries, booleans] = _.partition(_.pairs(gon.channels), (args) => {
      const channel = args[1]
      return channel.type !== 'Channel::Boolean'
    })

    const sharedChart = primaries.length > 0 ?
      new SharedChart({
        title: gon.device_name,
        containerId,
        channels: _.object(primaries),
        sync: this._sync,
      }) : undefined

    const booleanChart = booleans.length > 0 ?
      new BooleanChart({
        title: ((!sharedChart ? gon.device_name : undefined)),
        containerId: 'chart--secondary',
        channels: _.object(booleans),
        sync: this._sync,
      }) : undefined

    for (const [channelNum, channel] of Object.entries(gon.channels)) {
      this.chartMap[channelNum] = channel.type !== 'Channel::Boolean' ? sharedChart : booleanChart
    }

    this.charts = _.uniq(_.values(this.chartMap))

    this.requester = new DatapointRequester(gon.dps_url, gon.channels)
    this.annotationSummarizer = new AnnotationSummarizer(this.charts)

    const $exportModal = $('.modal.js--export')
    this.$exportStart = $exportModal.find('input[name=\'export[starts_at]\']')
    this.$exportEnd = $exportModal.find('input[name=\'export[ends_at]\']')

    this.cannedRange = new CannedRange()
    this.rangePicker = new RangeSelect(
        $('.js--datetime-chart-range'),
        {
          minDate: moment(gon.extent_min_ts),
          maxDate: moment(),
          startDate: cannedRange.startTime(),
          endDate: cannedRange.endTime(),
          ranges: cannedRange.validRanges(),
        },
    )

    this.referenceLines = new ReferenceLines($('.js--reference-lines'))

    this._resetLoader()
    this._initUI()
    this._handleUpdate(null, this.rangePicker.picker)
    this._syncCharts()
  }

  // Internal: Initialize the UI elements responsible for interacting with the
  // underlying chart and request mechanisms.
  _initUI() {
    this.rangePicker.on('updated', this._handleUpdate)
    this.rangePicker.on('cannedRange', (_e, label) => this.cannedRange.update(label))

    $('.js--channel-toggle')
        .find(':checkbox')
        .change((event) => {
          const channelNum = $(event.target).data('channel')
          this.chartMap[channelNum].toggleChannel(channelNum)
        })
        .each(function() {
          const $el = $(this)
          $el.siblings('.toggle-group').find('.toggle-on').css({background: $el.data('color')})
        })

    return this.referenceLines
        .on('addLine', (_event, referenceLine) => {
          const channelNum = parseInt(referenceLine.channel, 10)
          this.chartMap[channelNum].addOrUpdatePlotLine(referenceLine)
        })
        .on('removeLine', (_event, referenceLine) => {
          const channelNum = parseInt(referenceLine.channel, 10)
          this.chartMap[channelNum].removePlotLine(referenceLine)
        })
  }

  // Internal: Initialize _loader with an empty promise so we can always successfully
  // chain requests off of the currently-set loader object.
  _resetLoader() {
    this._loader = new Promise((resolve, reject) => resolve())
  }

  // Internal: Handle an update event which specifies a new time range for the
  // chart. If a set of requests is already pending, waits for them to resolve
  // before initiating the new update.
  //
  // event  - The Event that triggered the update.
  // picker - A Daterangepicker object containing the selected date range.
  //
  // Returns nothing.
  _handleUpdate(_event, picker) {
    const minTime = picker.startDate
    const maxTime = picker.endDate

    this.$exportStart.val(minTime.format()).next().val(minTime.format(momentDateTimeFormat))
    this.$exportEnd.val(maxTime.format()).next().val(maxTime.format(momentDateTimeFormat))

    return this._loader.then(() => this._updateSeries(minTime.valueOf(), maxTime.valueOf()))
  }

  // Internal: Update the data in the chart to reflect the newly-specified time
  // range.
  //
  // minTime - An Integer representing the earliest time for which to fetch data.
  // maxTime - An Integer representing the latest time for which to fetch data.
  //
  // Returns a Promise.
  _updateSeries(minTime, maxTime) {
    this.granularity = TimeRange.granularity(minTime, maxTime)
    _.each(this.charts, (chart) => chart.setGranularity(this.granularity))

    $('.js--chart-summary-row td[data-attr]').empty()

    const requests = this.requester.fetch(minTime, maxTime, this.granularity, (channel) => {
      this.chartMap[channel.number].setData(channel)
      this._updateSummary(channel)
    })

    return this._loader = Promise.all(_.map(this.charts, (chart) => chart.load(requests)))
        .then(() => {
          this.referenceLines.init()
          this.annotationSummarizer.update(minTime, maxTime)
        })
        .catch(this._resetLoader)
  }

  // Internal: Update the summary display for a channel specified by the passed
  // summary data.
  //
  // summary - An Object containing a channel and various values.
  //
  // Returns nothing.
  _updateSummary(channel) {
    const row = $(`.js--chart-summary-row[data-channel=${channel.number}]`)

    const object = channel.metadata()
    for (const attr in object) {
      const datapoint = object[attr]
      if (datapoint !== null) {
        row.find(`[data-attr = ${attr}]`).text(datapoint.format())
      }
    }
  }

  // Internal: Add event listeners to synchronize user interactions across _all_
  // graphs rendered on the page.
  //
  // Returns nothing.
  _syncCharts() {
    for (const eventType in SYNC_EVENTS) {
      $('#charts').on(eventType, (e) => {
        const toSynchronize = []
        let current = null

        for (const chart in Highcharts.charts) {
          const coords = chart.plotBackground.element.getBoundingClientRect()
          const onPlot = (e.clientX > coords.left) && (e.clientX < coords.right) && (e.clientY > coords.top) && (e.clientY < coords.bottom)
          if (onPlot) {
            current = chart
          } else {
            toSynchronize.push(chart)
          }
        }

        if (!current) {
          current = Highcharts.charts[0]
        }

        const event = current.pointer.normalize(e.originalEvent)
        if (!current.series[0]) {
          return
        }

        const point = current.series[0].searchPoint(event, true)
        const x = point?.x

        for (chart of toSynchronize) {
          for (const channelSeries of chart.series) {
            if (!channelSeries.visible) {
              continue
            }

            const seriesPoint = _.detect(channelSeries.points, (p) => p.x === x)
            seriesPoint?.onMouseOver?.()
            seriesPoint?.series?.chart?.xAxis?.[0]?.drawCrosshair(event, seriesPoint)
          }
        }
      })
    }
  }

  // Internal: In response to any update of the min/max range for a Highcharts
  // chart, update all other charts to the same range.
  //
  // Returns nothing.
  _sync(e) {
    if (e.trigger === 'synchronizing') {
      return
    }

    const triggeredBy = e.target.chart
    for (const chart of Highcharts.charts) {
      if (chart === triggeredBy) {
        continue
      }

      if (triggeredBy.resetZoomButton != null) {
        chart.resetZoomButton = chart.resetZoomButton != null ? chart.resetZoomButton.destroy() : undefined
      } else {
        chart.showResetZoom()
      }

      chart.xAxis[0].setExtremes?.(e.min, e.max, undefined, false, {trigger: 'synchronizing'})
    }
  }
}
