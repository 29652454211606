export default {
  name: 'contact-search',
  template: '#contact-search-template',
  props: {
    contacts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      query: '',
    }
  },
  watch: {
    query(newQuery) {
      this.$emit('search', newQuery)
    },
  },
}
