import $ from 'jquery'

import mixin from '../../helpers/mixin'
import EventsMixin from '../../mixins/events_mixin'

class ReferenceLines {
  constructor($container) {
    this._addReferenceLine = this._addReferenceLine.bind(this)
    this._hideInputFields = this._hideInputFields.bind(this)
    this._showNewForm = this._showNewForm.bind(this)
    this._showEditForm = this._showEditForm.bind(this)
    this._submitForm = this._submitForm.bind(this)
    this._onKeypress = this._onKeypress.bind(this)
    this._onSuccessfulLoad = this._onSuccessfulLoad.bind(this)
    this._removeReferenceLine = this._removeReferenceLine.bind(this)
    this._getRow = this._getRow.bind(this)
    this._toggleNoneMessage = this._toggleNoneMessage.bind(this)

    this.$container = $container
    this.initialized = false

    this._referenceLineUrl = `${gon.base_url}/reference_lines`
    this._alarmReferenceLineUrl = `${gon.base_url}/alarm_reference_lines`
  }

  init() {
    if (this.initialized) {
      return
    }

    this.$container
        .on('click', '#new_reference_line_link', this._showNewForm)
        .on('click', '.edit_reference_line_link', this._showEditForm)
        .on('click', '.submit', this._submitForm)
        .on('ajax:success', 'a[data-method=delete]', this._removeReferenceLine)
        .on('click', '[data-method=cancel]', this._hideInputFields)
        .on('keypress', 'input', this._onKeypress)

    this._getReferenceLines(this._referenceLineUrl)
    this._getReferenceLines(this._alarmReferenceLineUrl)
    this.initialized = true
  }

  _getReferenceLines(url) {
    return $.ajax({
      url,
      success: (data) => data.reference_lines.map((line) => this._addReferenceLine(line)),
    })
  }

  _addReferenceLine(line) {
    if (line.id) {
      this.triggerHandler('addLine', line)
    }

    this._toggleNoneMessage()
  }

  _hideInputFields() {
    if (this._newLineForm) {
      this._newLineForm.remove()
    }
    this.$container.find('.reference_line_show').show()
    this.$container.find('.reference_line_inputs').hide()

    return false
  }

  _showNewForm() {
    this._hideInputFields()
    this._newLineForm = this._addReferenceLine({url: this._referenceLineUrl})
    return false
  }

  _showEditForm(event) {
    this._hideInputFields()
    const $row = $(event.target).closest('tbody')
    $row.find('.reference_line_show').hide()
    $row.find('.reference_line_inputs').show()
    return false
  }

  _submitForm(event) {
    event.stopPropagation()
    event.preventDefault()

    const $button = $(event.target).closest('.reference_line_inputs').find('button.submit')
    const $inputs = $button.closest('tbody').find('input:visible, select:visible, input[type=hidden]')
    $.ajax({
      url: $button.data('action'),
      type: 'POST',
      data: $inputs.serialize(),
      dataType: 'json',
      success: this._onSuccessfulLoad,
    })
    return false
  }

  // Submit if user types the enter key while in an input field
  _onKeypress(event) {
    if (event.which === 13) {
      this._submitForm(event)
    }
  }

  // After loading an ajax request, replace the old element with the ajax response
  _onSuccessfulLoad(data) {
    this._hideInputFields()
    this._addReferenceLine(data.reference_line)
    return false
  }

  // After deleting a row, delay then fade it out
  _removeReferenceLine(event, data) {
    this.triggerHandler('removeLine', data.reference_line)
  }

  _getRow(referenceLine) {
    return this.$container.find(`[data-id=${referenceLine.id}]`)
  }

  _toggleNoneMessage() {
    if ($('#reference_lines').find('.reference_line_show').length) {
      $('#no_reference_lines_message').hide()
    } else {
      $('#no_reference_lines_message').show()
    }
  }
}

mixin.include(ReferenceLines, EventsMixin)

export default ReferenceLines
