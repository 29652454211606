import _ from 'underscore'

import mixin from '../../helpers/mixin'
import WrapperMixin from '../../mixins/wrapper_mixin'

import ContactEndpoint from './contact_endpoint'
import User from './user'

class Contact {
  constructor(attrs) {
    this.serialize = this.serialize.bind(this)

    if (attrs == null) {
      attrs = {}
    }

    this.editable = attrs.editable != null ? attrs.editable : true
    this.endpoints = ContactEndpoint.wrap(_.map(attrs.endpoints, (endpoint) => {
      endpoint.editable = this.editable
      return endpoint
    }))

    this.errors = attrs.errors || {}
    this.id = attrs.id
    this.firstName = attrs.firstName || ''
    this.lastName = attrs.lastName || ''

    if (attrs.user) {
      this.user = new User(attrs.user)
    }

    this.hasUser = (this.user != null)
    this.userRoleId = attrs.userRoleId
    this.invitationUrl = attrs.invitationUrl
    this.url = attrs.url || `/contacts/${this.id}`
    this.label = attrs.label
    this.locations = _.sortBy(attrs.locations || [], 'name')
    this.editable = attrs.editable
  }

  serialize() {
    return {
      id: this.id,
      first_name: this.firstName,
      last_name: this.lastName,
      user_role_id: this.userRoleId,
      location_ids: [''].concat(_.pluck(this.locations, 'id')),
      user_attributes: (this.user != null ? this.user.serialize() : undefined),
      endpoints_attributes: this.endpoints.map((endpoint) => endpoint.serialize()),
    }
  }

  get fullName() {
    return [this.firstName, this.lastName].join(' ')
  }
}

mixin.extend(Contact, WrapperMixin)

export default Contact
