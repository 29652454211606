import pluralize from '../../helpers/pluralize'

export default {
  name: 'device',
  template: '#device-template',
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  computed: {
    exportId() {
      return 'export-' + this.device.id
    },
    exportAnchor() {
      return '#' + this.exportId
    },
    exportEndId() {
      return this.exportId + '-starts_at'
    },
    exportStartId() {
      return this.exportId + '-ends_at'
    },
    path() {
      return this.device.path
    },
    alarmPath() {
      return this.device.path + '/alarms'
    },
    settingsPath() {
      return this.device.path + '/settings'
    },
    excursionPath() {
      return this.alertPath('excursion')
    },
    warningPath() {
      return this.alertPath('warning')
    },
    statusClass() {
      if (this.device.excursionCount > 0) {
        return 'device--excursion'
      } else if (this.device.warningCount > 0) {
        return 'device--warning'
      } else {
        return 'device--success'
      }
    },
    statusOk() {
      return this.device.warningCount < 1 && this.device.excursionCount < 1
    },
  },
  methods: {
    alertPath(severity) {
      return '/alerts?alert_search_form[severity]=' + severity + '&alert_search_form[tokens][]=' + this.device.token
    },
    pluralize,
  },
}
