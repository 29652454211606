import _ from 'underscore'

import mixin from '../../helpers/mixin'
import WrapperMixin from '../../mixins/wrapper_mixin'

import AlarmNotification from './alarm_notification'

class NotificationGroup {
  constructor(attrs) {
    if (attrs == null) {
      attrs = {}
    }

    this.notifications = AlarmNotification.wrapWithDefault(attrs.notifications)
    this.delayInterval = attrs.delayInterval || 0
    this.excursion = attrs.excursion != null ? attrs.excursion : true
    this.id = _.uniqueId()
  }

  visibleNotifications() {
    return this.notifications.filter((notification) => notification.visible())
  }

  hasContactNotifications() {
    return this.visibleNotifications().length > 0
  }

  hasHardwareNotification() {
    return this.notifications.some((notification) => notification.hardware())
  }

  notificationLevel() {
    return this.excursion ? 'Excursion' : 'Warning'
  }

  serialize() {
    return _.compact(this.notifications.map((notification) => {
      if (notification.hardware()) {
        return null
      } else {
        return Object.assign(
            notification.serialize(),
            {
              delay_interval: this.delayInterval,
              excursion: this.excursion,
            },
        )
      }
    }))
  }
}

mixin.extend(NotificationGroup, WrapperMixin)

export default NotificationGroup
