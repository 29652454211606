/**
The `search-dropdown` component is custom component that provides a list of items to be searchable.
Items also have variations of data they can display including: primary and secondary values, alerts, and visual selected feedback.

@name search-dropdown
@prop {string} id
  The ID for the dropdown component.
@prop {string} buttonText
  The text to display in the dropdown button.
@prop {array} options
  An array of items to list and search through.
@prop {object} format
  Instructions for how to format the values inside the button. Provide keys for
  primary value (required) and secondary value (optional).
@prop {boolean} [useAdditionalSearch=`false`]
  If additonal search terms (secondary value) should be use when running search.
@prop {boolean} [showAlerts=`false`]
  If alert badges should be shown with list items.
@prop {string} [placeholder='Search']
  Input placeholder for the search box.
@prop {object} selectedItem
  Information on the selected item in the list, requires ID as key in object.
  Default `id=-1` as it will rarely be assigned. Provides visual feedback for
  customer on what item is selected.

@template components/shared/search_dropdown
@design https://zpl.io/1yrq6eQ

@example
  {
    "id": "search-dropdown-example",
    "button-text": "Add Item",
    "options": [
      {"id": 1, "name": "Item 1"},
      {"id": 2, "name": "Item 2"},
      {"id": 3, "name": "Item 2.b", "belongs_to": "Item 2"}
    ],
    "format": {"primary": "name", "secondary": "belongs_to"}
  }
@example
  {
    "id": "search-dropdown-example-2",
    "button-text": "Data Logger: Data Logger 1",
    "options": [
      {"id": 1, "name": "Data Logger 1"},
      {"id": 2, "name": "Data Logger 2", "total_warnings": 2, "total_excursions": 1},
      {"id": 3, "name": "Data Logger 3"}
    ],
    "format": {"primary": "name"},
    "show-alerts": true,
    "selected-item": { "id": 1 }
  }
*/

const searchDropdown = {
  template: '#search-dropdown-template',
  props: {
    id: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    format: {
      type: Object, // { primary, secondary }
      required: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    useAdditionalSearch: {
      type: Boolean,
      default: false,
    },
    showAlerts: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
    selectedItem: {
      type: Object,
      default() {
        return {id: -1} // Work around, as we might not always want to show the selected check mark
      },
    },
  },
  data() {
    return {
      searchTerm: '',
    }
  },
  computed: {
    canClear() {
      return this.clearable && this.selectedItem.id !== -1
    },
    searchResults() {
      if (!this.searchTerm) {
        return this.options
      }

      const query = this.searchTerm.toLowerCase()
      const primaryKey = this.format.primary

      if (this.useAdditionalSearch) {
        const secondaryKey = this.format.secondary
        return this.options.filter(( item ) => item[primaryKey].toLowerCase().includes(query) || item[secondaryKey].toLowerCase().includes(query))
      } else {
        return this.options.filter(( item ) => item[primaryKey].toLowerCase().includes(query))
      }
    },
  },
  methods: {
    handleFocusOut(event) {
      const target = event.relatedTarget
      const parent = this.$refs[`${this.id}`]
      if (!parent.contains(target)) {
        this.searchTerm = ''
        document.getElementById(`${this.id}-dropdown`).classList.remove('open')
      }
    },
  },
}

export default searchDropdown
