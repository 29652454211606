import $ from 'jquery'
import _ from 'underscore'
import PreferenceToggle from './preference_toggle'

export default class PreferenceToggleController {
  // Public: Initialize a new PreferenceToggleController.
  //
  // $el - The toggle container element.
  constructor($el) {
    this.refresh = this.refresh.bind(this)
    this._updatePreference = this._updatePreference.bind(this)
    this._onError = this._onError.bind(this)
    this.$el = $el
    this._options = this.$el.data()
    this._toggles = this.$el.find('.preferenceToggle__link').map((_, link) => {
      return new PreferenceToggle($(link))
    })
    this._getInitialPreferences()
    this._registerListeners()
  }

  // Public: Refresh the view to reflect all current toggle states.
  //
  // Returns nothing.
  refresh() {
    _.each(this._toggles, (toggle) => toggle.refresh())
  }

  // Internal: Get user preferences from the server and update toggles.
  //
  // Returns nothing.
  _getInitialPreferences() {
    $.ajax({
      url: this._options.url,
      method: 'GET',
      success: (data) => {
        _.each(this._toggles, (toggle) => toggle.update(data))
      },
    })
  }

  // Internal: Bind click handlers to toggles.
  //
  // Returns nothing.
  _registerListeners() {
    this._toggles.bind('click', this._updatePreference)
  }

  // Internal: Post the user's updated preference to the server to ensure that
  // subsequent views of the page preserve their selection.
  //
  // Returns nothing.
  _updatePreference({target: toggle}) {
    $.ajax({
      url: this._options.url,
      method: 'POST',
      data: toggle.postData(),
      success: toggle.update,
      error: this._onError,
    })
  }

  // Internal: Handle an error response from the server.
  //
  // Returns nothing.
  _onError() {
    alert(this._options.errorMessage)
  }
}
