import {createApp} from 'vue'

export function initVue(selector, options) {
  if (!document.querySelector(selector)) {
    return
  }

  const plugins = options.plugins || []
  const app = createApp(options)

  plugins.forEach((plugin) => app.use(plugin))
  app.directive('style-error', function(el, binding) {
    if (binding.value) {
      $(el).addClass('is-invalid')
    } else {
      $(el).removeClass('is-invalid')
    }
  })
  return app.mount(selector)
}
