import {channelIcon, channelIconClass} from '../../helpers/channel_icon'
import equipmentIcon from '../../helpers/equipment_icon'

/**
The `monitoring-point-icon` is a utility component for display of icons specific
to particular types of record. This can be used to display icons for particular
records wherever they're useful.

@name monitoring-point-icon
@prop {object} item
  A monitoring point (channel, equipment, data logger or location) Object.
@prop {string} type
  Type of monitoring point.

@template components/monitoring_points/icon
@design https://zpl.io/1yrq6eQ

@example
  {
    "type": "Channel",
    "item": {
      "id": 1,
      "name": "Temperature CH:1",
      "uuid": "5dede3b0-f2a1-4069-b83f-3f35c7a32324",
      "type": "Channel::Temperature",
      "device": {"name": "Chiller", "path": "/devices/645329209841963433", "serial": "420010"},
      "equipment": [{"id": 2, "name": "My Custom Equipment", "path": "/equipment/2"}],
      "location": {"id": 4, "name": "Inventing Room", "path": "/locations/4"},
      "localized_type": "Temperature",
      "sensor": null
    }
  }

@example
  {
    "type": "Channel",
    "item": {
      "id": 2,
      "name": "Relative Humidity CH:2",
      "uuid": "f60156a7-9d71-46cf-a378-b1c30cd86600",
      "type": "Channel::RelativeHumidity",
      "device": {"name": "Chiller", "path": "/devices/645329209841963433", "serial": "420010"},
      "equipment": [
        {"id": 3, "name": "My Warehouse", "path": "/equipment/3"},
        {"id": 1, "name": "My Freezer Humidity", "path": "/equipment/1"},
        {"id": 2, "name": "My Custom Equipment", "path": "/equipment/2"}
      ],
      "location": {"id": 4, "name": "Inventing Room", "path": "/locations/4"},
      "localized_type": "Relative Humidity",
      "sensor": "Sensor: dab9aac3f2c04723"
    }
  }

@example
  {
    "type": "Equipment",
    "item": {}
  }
*/

const monitoringPointIcon = {
  name: 'monitoring-point-icon',
  template: '#monitoring-point-icon-template',
  props: {
    type: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    itemType() {
      return this.type.toLowerCase()
    },
    wrapperClass() {
      return `${this.itemType}-icon`
    },
    icon() {
      switch (this.itemType) {
        case 'channel':
          return channelIcon(this.item)
        case 'location':
          return 'pin_drop'
        case 'equipment':
          return equipmentIcon(this.item)
        case 'device':
          return 'tablet_android'
      }
    },
    iconClass() {
      if (this.itemType === 'channel') {
        return channelIconClass(this.item)
      } else {
        return ''
      }
    },
  },
}

export default monitoringPointIcon
